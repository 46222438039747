import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface PushEnvironmentArgs {
  environmentName: string
  destinationEnvironmentName: string
}

const usePushAppEnvironment = (appId: string) => {
  return useMutation(
    async ({
      environmentName,
      destinationEnvironmentName,
    }: PushEnvironmentArgs) => {
      return await axios
        .post(`/account/apps/${appId}/environments/${environmentName}/push`, {
          destinationEnvironmentName: destinationEnvironmentName,
        })
        .then((res) => res.data)
    },
    {
      onSuccess: (data, variables) => {
        cogoToast.success(
          `Environment pushed successfully to ${variables.destinationEnvironmentName}`,
          {
            position: 'bottom-right',
          }
        )
      },
      onError: (error: Error & { status: number }, values) => {
        console.log('ERROR: ', error?.status)
      },
    }
  )
}

export default usePushAppEnvironment
