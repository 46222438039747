import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactCodeInput from 'react-code-input'
import classNames from 'classnames'
import { FiCheckCircle } from 'react-icons/fi'
import qs from 'qs'
import * as gtag from '../../utils/gtag'

import useVerifyEmail from '../../hooks/api/mutations/useVerifyEmail'
import useResendEmail from '../../hooks/api/mutations/useResendEmail'
import imgLogo from '../../images/reactbricks-logo-v.svg'
import Loading from '../../components/Loading'

const VerifyEmail: React.FC = () => {
  const qsEmail =
    qs
      .parse(window.location.search, { ignoreQueryPrefix: true })
      .e?.toString() || ''
  const email = qsEmail || localStorage.getItem('email') || ''
  const [code, setCode] = useState('')

  const navigate = useRef(useNavigate())

  const verifyEmail = useRef(useVerifyEmail())
  const resendEmail = useResendEmail(email)

  const { token } = useParams()

  useEffect(() => {
    if (token && token.length > 10) {
      verifyEmail.current
        .mutateAsync({ token })
        .then((res) => {
          if (res.message === 'KO') {
            navigate.current('/')
          } else if (res.message === 'ALREADY_VERIFIED') {
            navigate.current('/')
          } else if (res.message === 'OK') {
            if (res && res.authToken) {
              // Set LocalStorage
              localStorage.setItem('token', res.authToken)
              localStorage.setItem('email', res.user.email)
              localStorage.setItem('firstName', res.user.firstName)
              localStorage.setItem('lastName', res.user.lastName)

              // Analytics Event
              gtag.trackEvent({
                category: 'sign_up',
                action: 'email_verified',
                label: 'Email verified',
              })

              // Redirect
              navigate.current('/apps')
            }
          }
        })
        .catch(() => {})
    }
  }, [token, verifyEmail])

  const handleVerifyEmail = async (event: React.FormEvent) => {
    event.preventDefault()
    await verifyEmail.current.mutateAsync({ code }).then((res) => {
      if (res && res.authToken) {
        localStorage.setItem('token', res.authToken)
        localStorage.setItem('email', res.user.email)
        localStorage.setItem('firstName', res.user.firstName)
        localStorage.setItem('lastName', res.user.lastName)
        navigate.current('/apps')
      }
    })
  }

  const handleChangeCode = (code: string) => {
    setCode(code)
  }

  const handleResendCode = async (event: React.FormEvent) => {
    await resendEmail.mutate()
    setCode('')
  }

  return (
    <div className="min-h-screen">
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-12 flex justify-center">
        <img src={imgLogo} alt="logo" className="w-32" />
      </header>

      {!!token ? (
        <div className="flex">
          <Loading />
        </div>
      ) : (
        <div className="shadow-lg py-8 px-10 max-w-xs rounded mx-auto bg-white">
          <style>{`
          input[type=number]::-webkit-outer-spin-button,
          input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type=number]:focus { outline: none; border: 2px solid #f65a8e!important }
        `}</style>

          <div className="flex flex-col items-center">
            <FiCheckCircle className="mb-6 text-4xl text-green-500" />

            <h2 className="text-center text-gray-900 text-lg font-bold mb-6">
              Check your e-mail
            </h2>

            <p className="text-sm text-gray-600">
              Please, enter the 4 digits sent to {email} or click on the link
              you find in the e-mail message.
            </p>

            <div className="flex justify-center items-center py-6">
              <ReactCodeInput
                type="number"
                value={code}
                fields={4}
                onChange={handleChangeCode}
              />
            </div>

            <button
              type="submit"
              disabled={code.length < 4}
              className={classNames(
                'block w-full focus:shadow-outline focus:outline-none text-white text-sm font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150',
                {
                  'bg-pink-500 hover:bg-pink-600 cursor-not-allowed':
                    code.length < 4,
                },
                { 'bg-green-500 hover:bg-green-600': code.length >= 4 }
              )}
              onClick={handleVerifyEmail}
            >
              Verify e-mail
            </button>

            <div className="mt-6 flex justify-center">
              <button
                className="text-blue-600 py-1 px-2 hover:text-blue-700"
                onClick={handleResendCode}
              >
                Resend code
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VerifyEmail
