import React, { useEffect, useRef } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

import useProfile from '../../hooks/api/queries/useProfile'
import Header from '../../components/Header'

import Apps from './Apps'
import App from './Apps/App'
import User from './User'

const LoggedApp: React.FC = ({ children }) => {
  //==============================
  // RECUPERIAMO UTENTE
  // =============================
  const navigate = useRef(useNavigate())

  const location = useLocation()
  const qsToken = (
    qs.parse(location.search, { ignoreQueryPrefix: true }).t || ''
  ).toString()

  const lsToken = localStorage.getItem('token')
  const keepLoggedIn = localStorage.getItem('keepLoggedIn') === 'true'

  const token = qsToken || lsToken

  const profileQuery = useProfile(token, keepLoggedIn)

  useEffect(() => {
    if (!token) {
      navigate.current('/')
    }
  }, [token])

  // useEffect(() => {
  //   if (profileQuery.data?.role === 'NEW_USER') {
  //     navigate.current('/verify-email')
  //   }
  // }, [profileQuery.data])

  if (!profileQuery.data) {
    //Non dovresti essere qui...
    return null
  }

  return (
    <div className="logged-app">
      <Header />
      <div className="logged">
        <Routes>
          <Route path="apps" element={<Apps />} />
          <Route path="apps/:appId" element={<App />} />
          <Route path="apps/:appId/:page" element={<App />} />
          <Route
            path="apps/:appId/environments/:environment/*"
            element={<App />}
          />
          <Route path="user/*" element={<User user={profileQuery.data} />} />
        </Routes>
      </div>
    </div>
  )
}

export default LoggedApp
