import { useQuery } from 'react-query'
import axios from 'axios'

const useCheckInvitation = (token?: string) => {
  return useQuery(
    'checkInvitation',
    async () => {
      const { data } = await axios.get(`/account/invitation/${token}`)
      return data
    },
    {
      enabled: !!token,
    }
  )
}

export default useCheckInvitation
