import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useCreateAppCustomRole from '../../hooks/api/mutations/useCreateAppCustomRole'

import Button from '../../components/Button'
import FormField from '../../components/FormField'

interface CreateRoleProps {
  appId: string
  appEnv: string
  onCloseCreateModal: any
}

const initalData = {
  id: '',
  name: '',
}

const normalizeCustomRoleId = (value: string) => {
  const normalized = value
    .replace(/(\s|-)/g, '_')
    .normalize('NFKD')
    .replace(/\W/g, '')
    .toLowerCase()

  return normalized.substring(0, 30)
}

const normalizeCustomRoleName = (value: string) => {
  return value.substring(0, 50)
}

const CreateRole: React.FC<CreateRoleProps> = ({
  appId,
  appEnv,
  onCloseCreateModal,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm<any>({
    defaultValues: initalData,
  })

  useEffect(() => {
    reset(initalData)
  }, [reset])

  const createCustomRole = useCreateAppCustomRole(appId, appEnv)

  return (
    <div className="px-12 py-8 max-w-xl">
      <h2 className="text-xl font-semibold text-center mb-3">Create role</h2>

      <div className="mb-5 text-sm text-gray-600">Create a new custom role</div>

      <form
        onSubmit={handleSubmit((data) => {
          const newRole = {
            id: data.id,
            name: data.name,
            users: 0,
          }
          createCustomRole.mutate(newRole)
          onCloseCreateModal()
        })}
      >
        <div className="mb-4">
          <FormField
            fieldName="id"
            type="normalized-text"
            control={control}
            normalize={normalizeCustomRoleId}
            label="Id"
            validation={{
              required: 'An identifier is required',
            }}
            error={errors.id}
          />
        </div>
        <div className="mb-6">
          <FormField
            fieldName="name"
            type="normalized-text"
            control={control}
            normalize={normalizeCustomRoleName}
            label="Name"
            validation={{
              required: 'A display name is required',
            }}
            error={errors.name}
            register={register}
          />
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <Button color="gray" onClick={onCloseCreateModal} className="flex-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            loading={createCustomRole.isLoading}
            className="flex-1"
          >
            Create role
          </Button>
        </div>

        {createCustomRole.isError && (
          <span className="block mt-2 text-red-500 md:inline-block md:mt-0">
            An error occurred while creating this custom role
          </span>
        )}
      </form>
    </div>
  )
}

export default CreateRole
