import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'
import type { User } from '../queries/useProfile'

interface PatchAppUser {
  role: string
  customRoleId: string
  canDeploy: boolean
  canDeployStaging: boolean
  canDeployDev: boolean
  canCreatePage: boolean
  canDeletePage: boolean
  canApprove: boolean
  canEditPageAttributes: boolean
  canEditSeo: boolean
}

const usePatchAppUser = (appId: string, appEnv: string, userId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: PatchAppUser) => {
      const userToSave = {
        role: values.role,
        customRoleId: values.customRoleId,
        canDeploy: values.canDeploy,
        canDeployStaging: values.canDeployStaging,
        canDeployDev: values.canDeployDev,
        canCreatePage: values.canCreatePage,
        canDeletePage: values.canDeletePage,
        canApprove: values.canApprove,
        canEditPageAttributes: values.canEditPageAttributes,
        canEditSeo: values.canEditSeo,
      }
      return await axios
        .patch<User>(
          `/account/apps/${appId}/environments/${appEnv}/users/${userId}`,
          userToSave
        )
        .then((res) => res.data)
    },
    {
      onSuccess: (data, values) => {
        queryClient.setQueryData(
          ['appUsers', appId, appEnv],
          (oldAppUsers: any) => {
            return oldAppUsers.map((oldUser: any) => {
              if (oldUser.id === userId) {
                return {
                  ...oldUser,
                  role: values.role,
                  customRoleId: values.customRoleId,
                  canDeploy: values.canDeploy,
                  canDeployStaging: values.canDeployStaging,
                  canDeployDev: values.canDeployDev,
                  canCreatePage: values.canCreatePage,
                  canDeletePage: values.canDeletePage,
                  canApprove: values.canApprove,
                  canEditPageAttributes: values.canEditPageAttributes,
                  canEditSeo: values.canEditSeo,
                }
              }
              return oldUser
            })
          }
        )
        queryClient.invalidateQueries(['appUsers', appId, appEnv])
        queryClient.invalidateQueries(['appCustomRoles'])
        queryClient.invalidateQueries(['app', appId, appEnv])
        cogoToast.success('User settings saved successfully', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchAppUser
