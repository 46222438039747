import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface ChangeParams {
  planId: number
  users: number
  pages: number
  diskSpace: number
}

const useChangeSubscription = (appId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ planId, users, pages, diskSpace }: ChangeParams) =>
      await axios
        .post(`/account/apps/${appId}/change_subscription`, {
          planId,
          users,
          pages,
          diskSpace,
        })
        .then((res) => {
          return res.data
        }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['app', appId], data)
        setTimeout(() => {
          queryClient.invalidateQueries(['app', appId])
          queryClient.invalidateQueries('apps')
          // cogoToast.success(`Plan changed successfully`, {
          //   position: 'bottom-right',
          // })
        }, 3500)
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
        // cogoToast.error(`Error changing plan`, {
        //   position: 'top-center',
        // })
      },
    }
  )
}

export default useChangeSubscription
