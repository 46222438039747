import React from 'react'

import useDeleteInvitation from '../../hooks/api/mutations/useDeleteInvitation'

import Button from '../Button'

import blankAvatar from '../../images/avatar.svg'
import useDeleteAppUser from '../../hooks/api/mutations/useDeleteAppUser'
import type { User } from '../../hooks/api/queries/useProfile'

interface RemoveUserProps {
  appId: string
  appEnv: string
  user: User
  onCloseDeleteModal: any
}

const RemoveUser: React.FC<RemoveUserProps> = ({
  appId,
  appEnv,
  user,
  onCloseDeleteModal,
}) => {
  const deleteInvitation = useDeleteInvitation(appId, appEnv, user.id)
  const deleteUser = useDeleteAppUser(appId, appEnv, user.id)

  const isInvitation = !!user.status

  const handleDeleteUser = () => {
    if (isInvitation) {
      deleteInvitation.mutate()
    } else {
      deleteUser.mutate()
    }
    onCloseDeleteModal()
  }

  return (
    <div className="px-12 py-8 max-w-xl">
      {isInvitation ? (
        <h2 className="text-lg text-red-600 font-semibold mb-4 text-center leading-snug">
          Cancel invitation for
          <br /> <span className="text-gray-900">{user.email}</span>
        </h2>
      ) : (
        <div className="flex flex-col items-center mb-4">
          <img
            className="w-16 rounded-full border mb-1"
            alt="avatar"
            src={user.avatarUrl || blankAvatar}
          />
          <h2 className="text-lg text-red-600 font-semibold">
            Remove user {user.firstName} {user.lastName}
          </h2>
        </div>
      )}

      {isInvitation ? (
        <p className="mb-8 text-sm text-gray-600">
          Cancel the invitation you sent to collaborate on this app.
        </p>
      ) : (
        <p className="mb-8 text-sm text-gray-600">
          This user won't be able to access your app anymore.
        </p>
      )}
      <div className="flex justify-between space-x-4">
        <Button color="gray" className="flex-1" onClick={onCloseDeleteModal}>
          {isInvitation ? 'Close' : 'Cancel'}
        </Button>
        <Button
          color="red"
          className="flex-1"
          loading={deleteInvitation.isLoading || deleteUser.isLoading}
          onClick={handleDeleteUser}
        >
          {isInvitation ? 'Cancel invitation' : 'Remove user'}
        </Button>
      </div>
    </div>
  )
}

export default RemoveUser
