import { useQuery } from 'react-query'
import axios from 'axios'
import type { User } from './useProfile'

const useAppInvitations = (appId: string | null, appEnv: string | null) => {
  return useQuery(
    ['appInvitations', appId, appEnv],
    async () => {
      const { data } = await axios.get<User[]>(
        `/account/apps/${appId}/environments/${appEnv}/invitations`
      )
      return data
    },
    {
      enabled: !!appId && !!appEnv,
      staleTime: 2 * 60 * 1000, // 2 minuti
    }
  )
}

export default useAppInvitations
