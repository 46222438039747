import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

import axios, { AxiosRequestConfig } from 'axios'
import cogoToast from 'cogo-toast'

import { API_URL } from './config'

const configureAxios = (navigate: (path: string) => void, location: any) => {
  // AXIOS GLOBAL CONFIG
  axios.defaults.baseURL = API_URL

  const qsToken = (qs.parse(location.search, { ignoreQueryPrefix: true }).t || '').toString()

  // GET TOKEN FROM QUERYSTRING
  if (qsToken && qsToken.length > 10) {
    // Set as localStorage token
    localStorage.setItem('token', qsToken)
  }

  // GET TOKEN FROM LOCALSTORAGE
  const lsToken = localStorage.getItem('token') || ''

  // REQUEST INTERCEPTOR TO SET AUTH TOKEN
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config || !config.headers) {
        return
      }
      if (!config.headers.Authorization) {
        const token = qsToken || lsToken

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  // RESPONSE INTERCEPTOR TO CATCH 401
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (!error?.response) {
        cogoToast.error(`An error occurred while communicating with the server`, {
          hideAfter: 5,
          position: 'bottom-right',
        })
        return Promise.reject(error?.response)
      }

      if (error?.response.status === 401) {
        if (error?.response.config.url === '/account/session' && error?.response.config.method === 'post') {
          // Login con credenziali errate
          cogoToast.error('Wrong username or password', {
            position: 'bottom-right',
          })
        } else {
          // Token scaduto
          cogoToast.warn('Session expired', { position: 'bottom-right' })
          localStorage.removeItem('token')
          navigate('/')
        }
      } else if (error?.response.status !== 409) {
        cogoToast.error(`An error occurred while communicating with the server`, {
          hideAfter: 5,
          position: 'bottom-right',
        })
      }

      return Promise.reject(error?.response)
    }
  )
}

const AxiosConfig = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    configureAxios(navigate, location)
  }, [navigate, location])

  return null
}

export default AxiosConfig
