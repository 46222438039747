import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import TagsInput, { Tag } from 'react-tag-autocomplete'
import cogoToast from 'cogo-toast'

import usePatchApp from '../../../../hooks/api/mutations/usePatchApp'

import Card from '../../../../components/Card'
import useLanguages from '../../../../hooks/api/queries/useLanguages'
import Select from '../../../../components/Select'
import Button from '../../../../components/Button'
import type { App, Language } from '../../../../hooks/api/queries/useApp'
// import { Plan, getPlanName } from '../../../../components/PlanPill'

interface LanguagesProps {
  app: App
}

interface LanguagesForm {
  languages: Language[]
  defaultLanguage: string
}

const Languages: React.FC<LanguagesProps> = ({ app }) => {
  const mutation = usePatchApp(app?.appId, app?.appEnv)

  const { handleSubmit, watch, control } = useForm<LanguagesForm>({
    defaultValues: {
      languages: app.languages,
      defaultLanguage: app.defaultLanguage,
    },
  })

  const { data: languages } = useLanguages()

  const handleSave = (data: LanguagesForm) => mutation.mutate(data)

  const handleValidateLanguage = (value: Language[]) => (languageTag: Tag) => {
    // Developer => Max 2 languages
    if (value.length >= app.subscription.maxLocales) {
      return false
    }

    // No duplicates
    return value.every((item) => item.code !== languageTag.id)
  }

  const handleAddLanguage =
    (value: Language[], onChange: (event: any) => void) => (language: Tag) => {
      const newLanguages = [
        ...value,
        { code: language.id, name: language.name },
      ]
      onChange(newLanguages)
    }

  const handleDeleteLanguage =
    (value: Language[], onChange: (event: any) => void) =>
    (indexToDelete: number) => {
      const languageToDelete = value[indexToDelete]

      if (languageToDelete.code === watch('defaultLanguage')) {
        cogoToast.error("You can't remove the default language", {
          position: 'bottom-right',
        })
      } else {
        const newLanguages = value.filter(
          (language) => language.code !== languageToDelete.code
        )
        onChange(newLanguages)
      }
    }

  // const isLiveDemo = !!app.liveDemo && app.liveDemo.status === 'ACTIVE'
  // const isBoosted = app.subscription?.description === 'Boosted Developer Plan'

  return (
    <div>
      <Card
        title="Languages"
        helpText="Languages available for content translations."
      >
        <form onSubmit={handleSubmit(handleSave)}>
          <label htmlFor="defaultLanguage" className="block font-semibold mb-1">
            Select the languages (max {app.subscription.maxLocales} locales for
            your plan)
          </label>
          <Controller
            control={control}
            name="languages"
            render={({ field: { onChange, value } }) => (
              <TagsInput
                id="languages"
                tags={value.map((language: Language) => ({
                  id: language.code,
                  name: language.name,
                }))}
                placeholderText="Add language..."
                removeButtonText="Remove language"
                onAddition={handleAddLanguage(value, onChange)}
                onDelete={handleDeleteLanguage(value, onChange)}
                onValidate={handleValidateLanguage(value)}
                suggestions={languages?.map((language) => ({
                  id: language.code,
                  name: language.name,
                }))}
                minQueryLength={2}
              />
            )}
          />
          <section className="mt-8">
            <label
              htmlFor="defaultLanguage"
              className="block font-semibold mb-1"
            >
              Default language
            </label>
            <Controller
              name="defaultLanguage"
              control={control}
              render={({ field }) => (
                <Select id="defaultLanguage" {...field}>
                  {watch('languages').map((language: Language) => (
                    <option key={language.code} value={language.code}>
                      {language.name}
                    </option>
                  ))}
                </Select>
              )}
            />
            <Button
              type="submit"
              color="blue"
              loading={mutation.isLoading}
              className="mt-6"
            >
              Save
            </Button>
          </section>
        </form>
      </Card>
    </div>
  )
}

export default Languages
