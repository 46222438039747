import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export interface Plan {
  id: number
  name: string
  description: string
  price: number
  currency: string
  period: string
  paddlePlanId: number
  maxUsers: number
  maxPages: number
  maxLocales: number
  maxStories: number
  maxApiCalls: number
  maxDiskSpace: number
  maxBandwidth: number
  includedApiCalls: number
  includedUsers: number
  includedPages: number
  includedLocales: number
  includedDiskSpace: number
  includedBandwidth: number
  additionalQtyApiCalls: number
  additionalQtyUsers: number
  additionalQtyPages: number
  additionalQtyLocales: number
  additionalQtyDiskSpace: number
  additionalQtyBandwidth: number
  additionalPriceApiCalls: number
  additionalPriceUsers: number
  additionalPricePages: number
  additionalPriceLocales: number
  additionalPriceDiskSpace: number
  additionalPriceBandwidth: number
  maxFileSize: number
  maxImageSize: number
  maxFilesBatch: number
  maxFilesConcurrency: number
  customCdn: boolean
  deployHookStaging: boolean
  deployHookDev: boolean
  trialDays: number
  changeHistoryRetentionDays: number
  support: string
  collaboration: boolean
  scheduledPublishing: boolean
  embedPages: boolean
  lockBlocks: boolean
  flexibleRoles: boolean
  advancedSeo: boolean
  advancedDam: boolean
  workingCopy: boolean
  approvalWorkflow: boolean
  objectStorageBackup: boolean
  objectStorageBackupDaily: boolean
  template: boolean
  richTextExt: boolean
  externalData: boolean
  sso: boolean
  eventsLog: boolean
  features: any
  isDefault: boolean
}

const usePlans = () => {
  const queryClient = useQueryClient()
  return useQuery(
    ['plans'],
    async () => {
      const { data } = await axios.get<Plan[]>(`/account/plans`)
      return data

      // return [
      //   {
      //     id: 1,
      //     name: 'Free',
      //     description: 'Community support',
      //     price: 0,
      //     currency: 'USD',
      //     period: 'month',
      //     paddlePlanId: 1,
      //     maxUsers: 4,
      //     maxPages: 110,
      //     maxLocales: 1,
      //     maxStories: 0,
      //     maxApiCalls: 50000,
      //     maxDiskSpace: 200,
      //     maxBandwidth: 50000,
      //     includedApiCalls: 50000,
      //     includedUsers: 1,
      //     includedPages: 10,
      //     includedLocales: 1,
      //     includedDiskSpace: 200,
      //     includedBandwidth: 50000,
      //     additionalQtyApiCalls: 0,
      //     additionalQtyUsers: 1,
      //     additionalQtyPages: 50,
      //     additionalQtyLocales: 1,
      //     additionalQtyDiskSpace: 1000,
      //     additionalQtyBandwidth: 0,
      //     additionalPriceApiCalls: 0,
      //     additionalPriceUsers: 29,
      //     additionalPricePages: 15,
      //     additionalPriceLocales: 1,
      //     additionalPriceDiskSpace: 5,
      //     additionalPriceBandwidth: 0,
      //     maxFileSize: 1000,
      //     maxImageSize: 1000,
      //     maxFilesBatch: 2,
      //     maxFilesConcurrency: 2,
      //     customCdn: false,
      //     deployHookStaging: false,
      //     deployHookDev: false,
      //     trialDays: 0,
      //     changeHistoryRetentionDays: 7,
      //     support: '',
      //     collaboration: false,
      //     scheduledPublishing: false,
      //     embedPages: false,
      //     lockBlocks: false,
      //     flexibleRoles: false,
      //     advancedSeo: false,
      //     advancedDam: false,
      //     workingCopy: false,
      //     approvalWorkflow: false,
      //     objectStorageBackup: false,
      //     objectStorageBackupDaily: false,
      //     template: false,
      //     richTextExt: false,
      //     externalData: false,
      //     sso: false,
      //     eventsLog: false,
      //     features: [],
      //     isDefault: false,
      //   },
      //   {
      //     id: 2,
      //     name: 'Pro',
      //     description: 'Email support, localization, collaboration, scheduling',
      //     price: 29,
      //     currency: 'USD',
      //     period: 'month',
      //     paddlePlanId: 2,
      //     maxUsers: 15,
      //     maxPages: 500,
      //     maxLocales: 10,
      //     maxStories: 200,
      //     maxApiCalls: 1000000,
      //     maxDiskSpace: null,
      //     maxBandwidth: 1000000,
      //     includedApiCalls: 200000,
      //     includedUsers: 1,
      //     includedPages: 50,
      //     includedLocales: 1,
      //     includedDiskSpace: 1000,
      //     includedBandwidth: 200000,
      //     additionalQtyApiCalls: 100000,
      //     additionalQtyUsers: 1,
      //     additionalQtyPages: 50,
      //     additionalQtyLocales: 1,
      //     additionalQtyDiskSpace: 1000,
      //     additionalQtyBandwidth: 1000,
      //     additionalPriceApiCalls: 10,
      //     additionalPriceUsers: 29,
      //     additionalPricePages: 15,
      //     additionalPriceLocales: 1,
      //     additionalPriceDiskSpace: 5,
      //     additionalPriceBandwidth: 10,
      //     maxFileSize: 1000,
      //     maxImageSize: 1000,
      //     maxFilesBatch: 2,
      //     maxFilesConcurrency: 2,
      //     customCdn: false,
      //     deployHookStaging: false,
      //     deployHookDev: false,
      //     trialDays: 0,
      //     changeHistoryRetentionDays: 14,
      //     support: '',
      //     collaboration: false,
      //     scheduledPublishing: false,
      //     embedPages: false,
      //     lockBlocks: false,
      //     flexibleRoles: false,
      //     advancedSeo: false,
      //     advancedDam: false,
      //     workingCopy: false,
      //     approvalWorkflow: false,
      //     objectStorageBackup: false,
      //     objectStorageBackupDaily: false,
      //     template: false,
      //     richTextExt: false,
      //     externalData: false,
      //     sso: false,
      //     eventsLog: false,
      //     features: [],
      //     isDefault: false,
      //   },
      // ]
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      //refetchInterval: 15000,
    }
  )
}

export default usePlans
