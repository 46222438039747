import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import type { App } from '../queries/useApp'

const usePatchApp = (appId: string, appEnv: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: any) => {
      return await axios
        .patch<App>(`/account/apps/${appId}/environments/${appEnv}`, values)
        .then((res) => res.data)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['app', appId, appEnv], data)
        queryClient.invalidateQueries(['app', appId, appEnv])
        queryClient.invalidateQueries('apps')
        cogoToast.success('Settings saved successfully', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchApp
