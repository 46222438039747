import React from 'react'
import { useLocation } from 'react-router-dom'
import * as gtag from './utils/gtag'

const Analytics = () => {
  const location = useLocation()
  const url = location.pathname + location.search
  React.useEffect(() => {
    gtag.trackPageview(url)
  }, [url])

  return null
}

export default Analytics
