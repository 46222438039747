import { useMutation } from 'react-query'
import axios from 'axios'

const useForgotPassword = () => {
  return useMutation(
    async (values: any) =>
      await axios.post<{ message: string; error: string }>(`/account/forgot_password`, values).then((res) => res.data),
    {
      onSuccess: (data) => {},
      onError: (error: any) => {},
    }
  )
}

export default useForgotPassword
