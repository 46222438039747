import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

type TestConnectionRequest = {
  endpoint: string
  region: string
  accessKeyId: string
  secretAccessKey: string
  bucket: string
}

type TestConnectionResultOk = {
  result: 'OK'
}

type TestConnectionResultKo = {
  result: 'KO'
  error: string
}

type TestConnectionResult = TestConnectionResultOk | TestConnectionResultKo

const useRestoreApp = (appId: string, appEnv: string) => {
  return useMutation(
    async (values: TestConnectionRequest) =>
      await axios
        .post<TestConnectionResult>(
          `/account/apps/${appId}/environments/${appEnv}/test_object_storage_connection`,
          values
        )
        .then((res) => {
          return res.data
        }),
    {
      onSuccess: (data) => {
        if (data.result === 'OK') {
          cogoToast.success('Connection established successfully', {
            position: 'top-center',
          })
        } else {
          cogoToast.error(
            `Connection error: ${data.error || 'Not specified'}`,
            {
              position: 'top-center',
            }
          )
        }
      },
      onError: (error: any) => {
        console.log(error, 'error')
      },
    }
  )
}

export default useRestoreApp
