import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useSyncAppEnvironment = (appId: string) => {
  return useMutation(
    async (environmentName: string) => {
      return await axios
        .post(`/account/apps/${appId}/environments/${environmentName}/sync`)
        .then((res) => res.data)
    },
    {
      onSuccess: () => {
        cogoToast.success('Environment synced successfully with Main', {
          position: 'bottom-right',
        })
      },
      onError: (error: Error & { status: number }, values) => {
        console.log('ERROR: ', error?.status)
      },
    }
  )
}

export default useSyncAppEnvironment
