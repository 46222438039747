import React from 'react'
import { FiEdit2, FiXCircle, FiInfo } from 'react-icons/fi'
import { FaCrown } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip'
import type { User } from '../../hooks/api/queries/useProfile'

interface EditUserProps {
  user: User
  onOpenEditModal: any
  onOpenDeleteModal: any
  hasFlexibleRoles: boolean
}

const UserPermission = ({
  permission,
  label,
}: {
  permission: boolean
  label: string
}) => {
  return (
    <div className={`py-1 ${permission ? 'text-white' : 'text-white/30'}`}>
      {label}
    </div>
  )
}

const UsersRow: React.FC<EditUserProps> = ({
  user,
  onOpenEditModal,
  onOpenDeleteModal,
  hasFlexibleRoles,
}) => {
  return (
    <div className="flex items-center justify-between border-t border-gray-200">
      <div className="w-2/3 sm:w-1/3 py-4 whitespace-no-wrap">
        {!user.status ? (
          <>
            <p className="text-sm font-bold text-gray-900">
              {user.firstName} {user.lastName}
            </p>
            <p className="text-sm text-gray-600">{user.email}</p>
          </>
        ) : (
          <>
            <p className="text-sm font-semibold text-gray-900">{user.email}</p>
            <div>
              {user.status?.toUpperCase() === 'PENDING' && (
                <span className="mt-2 text-xs font-semibold text-white bg-blue-500 py-px px-2 rounded-sm">
                  INVITATION PENDING
                </span>
              )}
              {user.status?.toUpperCase() === 'REJECTED' && (
                <span className="mt-2 text-xs font-semibold text-white bg-red-600 py-px px-2 rounded-sm">
                  INVITATION REJECTED
                </span>
              )}
              {user.status?.toUpperCase() === 'CANCELLED' && (
                <span className="mt-2 text-xs font-semibold text-white bg-gray-500 py-px px-2 rounded-sm">
                  INVITATION CANCELLED
                </span>
              )}
            </div>
          </>
        )}
      </div>
      <div className="hidden sm:flex sm:w-1/3 px-6 py-4 whitespace-no-wrap space-x-1">
        {user.isOwner && (
          <div className="flex items-center justify-center py-1 px-2 leading-none bg-yellow-200 text-yellow-900 rounded lg:text-xs text-[9px] font-semibold uppercase">
            <FaCrown className="text-yellow-600 mr-1" />
            Owner
          </div>
        )}
        <div className="flex items-center justify-center py-1 px-2 leading-none bg-gray-200 text-gray-800 rounded lg:text-xs text-[9px] font-semibold uppercase">
          {user.role}
        </div>
        {!!hasFlexibleRoles && user.customRole && (
          <div className="flex items-center justify-center text-center py-1 px-2 leading-none bg-sky-100 text-sky-800 rounded lg:text-xs text-[9px] font-semibold uppercase">
            {user.customRole.name}
          </div>
        )}
      </div>
      <div className="w-1/3 flex items-center justify-end">
        <div className="w-8 ml-4 pr-1 py-4 whitespace-no-wrap">
          <button className="p-1 text-lg text-blue-500 hover:text-blue-600">
            <FiInfo data-tooltip-content={user.id} data-tooltip-id={user.id} />
            <Tooltip
              id={user.id}
              place="top"
              key={user.id}
              render={({ content }) => {
                return content === user.id ? (
                  <div className="text-xs text-left">
                    <UserPermission
                      permission={user.canDeploy}
                      label="Deploy in Production"
                    />
                    <UserPermission
                      permission={user.canDeployStaging}
                      label="Deploy in Staging"
                    />
                    <UserPermission
                      permission={user.canDeployDev}
                      label="Deploy in Development"
                    />
                    <UserPermission
                      permission={user.canCreatePage}
                      label="Create pages"
                    />
                    <UserPermission
                      permission={user.canDeletePage}
                      label="Delete pages"
                    />
                    <UserPermission
                      permission={user.canApprove}
                      label="Approve pages"
                    />
                    <UserPermission
                      permission={user.canEditPageAttributes}
                      label="Edit page attributes"
                    />
                    <UserPermission
                      permission={user.canEditSeo}
                      label="Edit SEO"
                    />
                  </div>
                ) : null
              }}
            />
          </button>
        </div>

        <div className="w-8 pr-1 py-4 whitespace-no-wrap text-lg">
          {!user.status ? (
            <button
              className="p-1 text-blue-500 hover:text-blue-600"
              onClick={onOpenEditModal(user)}
            >
              <FiEdit2 />
            </button>
          ) : (
            <div className="p-1">&nbsp;</div>
          )}
        </div>
        <div className="w-8 py-4 whitespace-no-wrap text-lg">
          {!user.isOwner ? (
            <button
              className="p-1 text-red-600 hover:text-red-700"
              onClick={onOpenDeleteModal(user)}
            >
              <FiXCircle />
            </button>
          ) : (
            <div className="p-1">&nbsp;</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UsersRow
