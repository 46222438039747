import { useMutation } from 'react-query'
import axios from 'axios'

export type CheckSsoUaiResult = 'NOT_VALID' | 'TAKEN' | 'VALID'

const useCheckSsoUai = (appId: string, appEnv: string) => {
  return useMutation(
    async (uniqueAppIdentifier: string) => {
      const { data } = await axios.post<{ result: CheckSsoUaiResult }>(
        `/account/apps/${appId}/environments/${appEnv}/check_sso_uai`,
        {
          uniqueAppIdentifier,
        }
      )
      return data
    },
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  )
}

export default useCheckSsoUai
