import classNames from 'classnames'
import * as React from 'react'
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxButton,
  Field,
  Label,
  Transition,
} from '@headlessui/react'
import debounce from 'lodash.debounce'

import { HiChevronUpDown } from 'react-icons/hi2'
import { FiCheck } from 'react-icons/fi'

interface AutocompleteProps {
  label?: string
  value: any
  onChange(value: any): void
  placeholder?: string
  getOptions: (input: string) => Promise<any[]>
  getKey: (option: any) => string | number
  getLabel: (option: any) => string
  renderOption?: (option: any) => React.ReactElement
  debounceTime?: number
  getNoOptionsMessage?: (input?: string) => string
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  value,
  onChange,
  placeholder,
  getOptions,
  getKey,
  getLabel,
  renderOption,
  debounceTime = 350,
  getNoOptionsMessage,
}) => {
  const [options, setOptions] = React.useState<any[]>([])
  const [query, setQuery] = React.useState('')

  React.useEffect(() => {
    loadOptions('')
  }, [])

  const loadOptions = async (input: string) => {
    try {
      const newOptions = await getOptions(input || '')
      setOptions(newOptions)
    } catch (error) {
      console.error(error)
    }
  }

  const loadOptionsDebounced = React.useCallback(
    debounceTime ? debounce(loadOptions, debounceTime) : loadOptions,
    []
  )

  const compareKey = (a?: any, b?: any): boolean => {
    return getKey(a) === getKey(b)
  }

  const noOptionsMessage = getNoOptionsMessage
    ? getNoOptionsMessage(query)
    : 'Nothing found'

  return (
    <Field>
      <Label className="block text-xs font-bold uppercase tracking-wider text-gray-600 mb-1">
        {label}
      </Label>
      <Combobox
        value={value || {}}
        by={compareKey}
        onChange={(value) => {
          onChange(value)
        }}
      >
        <div className="relative mt-1 mb-2">
          <div className={classNames('relative w-full')}>
            <ComboboxInput
              placeholder={placeholder}
              onChange={(event) => {
                setQuery(event.target.value.trim())
                loadOptionsDebounced(event.target.value.trim())
              }}
              displayValue={(option) => getLabel(option)}
              className={classNames(
                'w-full py-1 pl-3 pr-6 truncate cursor-default rounded-md text-sm text-left border border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
              )}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronUpDown
                className="h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <ComboboxOptions className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none">
              {options.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  {noOptionsMessage}
                </div>
              ) : (
                options.map((option) => (
                  <ComboboxOption
                    key={getKey(option)}
                    value={option}
                    className={({ focus }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        focus ? 'bg-blue-500 text-white' : 'text-gray-900'
                      }`
                    }
                  >
                    {({ selected, focus }) => (
                      <>
                        <div
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {renderOption
                            ? renderOption(option)
                            : getLabel(option)}
                        </div>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              focus ? 'text-white' : 'text-blue-600'
                            }`}
                          >
                            <FiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ComboboxOption>
                ))
              )}
            </ComboboxOptions>
          </Transition>
        </div>
      </Combobox>
    </Field>
  )
}

export default Autocomplete
