import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Dropzone, { FileRejection } from 'react-dropzone'
import Loader from 'react-loader-spinner'

import { API_URL } from '../../../../config'
import useBackupApp from '../../../../hooks/api/mutations/useBackupApp'
import useRestoreApp from '../../../../hooks/api/mutations/useRestoreApp'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import FormField from '../../../../components/FormField'
import { FiRefreshCw } from 'react-icons/fi'
import type { App } from '../../../../hooks/api/queries/useApp'
import ScheduledBackup from '../../../../components/Backup/ScheduledBackup'

interface BackupRestoreProps {
  app: App
}

const Backup: React.FC<BackupRestoreProps> = ({ app }) => {
  //=================
  // LOCAL STATE
  //=================
  const [backupFile, setBackupFile] = useState<Blob | null>(null)
  const [fileName, setFileName] = useState('')
  const [dropErrorMessage, setDropErrorMessage] = useState('')

  //=================
  // HOOKS
  //=================
  const backupApp = useBackupApp(app.appId, app.appEnv)
  const restoreApp = useRestoreApp(app.appId, app.appEnv)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ confirmationCheckbox: boolean }>()

  //=================
  // EVENT HANDLERS
  //=================

  const handleBackupRequest = (event: React.FormEvent) => {
    event.preventDefault()
    backupApp.mutate()
  }

  const handleDropAccepted = (files: File[]) => {
    setBackupFile(files[0])
    setFileName(files[0].name)
  }

  const handleDropRejected = (files: FileRejection[]) => {
    const fileName = files[0].file.name
    const errorCode = files[0].errors[0].code

    if (errorCode === 'file-too-large') {
      setDropErrorMessage(`"${fileName}" exceeds maximum file size.`)
    } else if (errorCode === 'file-invalid-type') {
      setDropErrorMessage(
        `"${fileName}" has an invalid extension (allowed ones are .gz and .json).`
      )
    } else {
      setDropErrorMessage(`"${fileName}" is not valid.`)
    }
  }

  const handleRestoreFile = () => {
    if (backupFile) {
      let formData = new FormData()
      //@ts-ignore
      formData.append('file', backupFile, backupFile.name)
      restoreApp.mutate(formData)
    }
  }

  const token = localStorage.getItem('token') || ''

  return (
    <>
      <Card
        title="Backup content"
        helpText="Request a backup of your app content."
      >
        {app.backup.status === 'PENDING' && (
          <div className="py-3 px-6 bg-gray-50 border mb-6 flex items-center space-x-4">
            <Loader type="Watch" color="#0ab6d4" height={20} width={20} />
            <span>Backup queued for processing...</span>
          </div>
        )}
        {app.backup.status === 'READY' && (
          <div className="py-4 px-6 bg-gray-50 border mb-6">
            <p className="mb-3">
              Your backup is ready. It will be available for 24 hours.
            </p>
            <a
              href={`${API_URL}/account/apps/${app.appId}/environments/${app.appEnv}/download_backup?t=${token}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent-600 hover:text-accent-700 underline"
            >
              Download now
            </a>
          </div>
        )}
        <form onSubmit={handleBackupRequest}>
          <Button
            type="submit"
            color="blue"
            loading={backupApp.isLoading}
            disabled={app.backup.status === 'PENDING'}
          >
            Request a {app.backup.status === 'READY' && 'new'} backup
          </Button>
        </form>
      </Card>

      <Card
        title="Restore content"
        helpText="Restore the app content from a backup file."
      >
        <form onSubmit={handleSubmit(() => handleRestoreFile())}>
          <div className="mb-5">
            <p className="pl-4 border-l-4 border-pink-500 mb-6">
              <b>
                Before initiating the restore, we strongly recommend creating a
                backup
              </b>{' '}
              of the current content. The restore operation will replace the
              existing content with the content from the backup file.
            </p>
            {!backupFile && (
              <>
                <Dropzone
                  accept={'.gz, .json'}
                  multiple={false}
                  maxSize={1048576}
                  onDropAccepted={handleDropAccepted}
                  onDropRejected={handleDropRejected}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="p-6 bg-gray-50 border-dashed border-2 cursor-pointer"
                      {...getRootProps()}
                    >
                      <p>Click here or drag and drop a backup file.</p>
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
                {!!dropErrorMessage && (
                  <span className="block mt-6 text-red-600">
                    {dropErrorMessage}
                  </span>
                )}
              </>
            )}

            {backupFile && (
              <>
                <div className="py-3 px-6 bg-gray-50 border mb-6 flex items-center space-x-4">
                  <div>
                    Backup file: <b>{fileName}</b>
                  </div>
                  <div>
                    <button
                      onClick={() => setBackupFile(null)}
                      className="flex items-center space-x-1 text-accent-600 hover:text-accent-700"
                    >
                      <FiRefreshCw />
                      <span>Change file</span>
                    </button>
                  </div>
                </div>
                <FormField
                  fieldName="confirmationCheckbox"
                  type="checkbox"
                  register={register}
                  label={
                    <span className="ml-2">
                      I confirm that I have a backup and I want to restore the
                      content
                    </span>
                  }
                  validation={{
                    required:
                      'Please, confirm that you want to restore the content',
                  }}
                  error={errors.confirmationCheckbox}
                />
              </>
            )}
          </div>

          {backupFile && (
            <Button type="submit" color="blue" loading={restoreApp.isLoading}>
              Restore app content
            </Button>
          )}
        </form>
      </Card>
      <ScheduledBackup app={app} />
    </>
  )
}

export default Backup
