import React from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'

import useDeleteApp from '../../../../hooks/api/mutations/useDeleteApp'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import FormField from '../../../../components/FormField'

interface DeleteProps {
  appId: string
  appEnv: string
}

const Delete: React.FC<DeleteProps> = ({ appId, appEnv }) => {
  const deleteApp = useDeleteApp(appId)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()
  const navigate = useNavigate()

  return (
    <>
      {appEnv === 'main' ? (
        <Card
          title="Delete your app"
          helpText="You will lose all configurations, content and user permissions."
          danger
        >
          <form
            onSubmit={handleSubmit(() =>
              deleteApp.mutateAsync().then(() => navigate(`/apps`))
            )}
          >
            <div className="mb-5">
              <FormField
                fieldName="confirmationCheckbox"
                type="checkbox"
                register={register}
                label={
                  <span className="ml-2">
                    I confirm that I want to delete this app
                  </span>
                }
                validation={{
                  required: 'Please, confirm that you want to delete this app',
                }}
                error={errors.confirmationCheckbox}
              />
            </div>

            <Button type="submit" color="red" loading={deleteApp.isLoading}>
              Delete app
            </Button>
          </form>
        </Card>
      ) : (
        <Card title="Delete your app 🔐" danger>
          <p>
            You cannot delete your app from this page. Visit the{' '}
            <Link
              to={`/apps/${appId}/environments/main/delete-app`}
              className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
            >
              main environment
            </Link>{' '}
            page to change it.
          </p>
        </Card>
      )}
    </>
  )
}

export default Delete
