import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import type { User } from '../queries/useProfile'

interface ILoginCredentials {
  email: string
  password: string
  keepLoggedIn: boolean
}

interface ISsoLoginCredentials {
  ssoToken: string
}

const getToken = async (
  credentials: ILoginCredentials | ISsoLoginCredentials
) => {
  const { data } = await axios.post<{ user: User; authToken: string }>(
    `/account/session`,
    credentials
  )
  return data
}

const useLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (credentials: ILoginCredentials | ISsoLoginCredentials) => {
      return getToken(credentials)
    },
    {
      onSuccess: (data) => {
        // console.log('success', data)
        // update cache
        const {
          user: {
            id,
            accountId,
            accountName,
            avatarUrl,
            firstName,
            lastName,
            email,
            company,
            role,
            isVerified,
          },
        } = data

        queryClient.setQueryData(['profile'], {
          id,
          accountId,
          accountName,
          avatarUrl,
          firstName,
          lastName,
          email,
          company,
          role,
          isVerified,
        })
      },
      onError: () => {},
    }
  )
}

export default useLogin
