'use client'

import React from 'react'
import { Minus, Plus } from 'lucide-react'
import { Button, Group, Input, Label, NumberField } from 'react-aria-components'
import { Tooltip } from 'react-tooltip'

interface NumberInputProps {
  label: string
  minValue?: number
  maxValue?: number
  step?: number
  inUse: number
  subscriptionMax: number
  value: number
  onChange: (value: number) => void
  isCurrentPlan: boolean
  tooltipMin?: string
}

const NumberInput: React.FC<NumberInputProps> = ({
  label,
  //planMinValue
  minValue = 1,
  maxValue,
  step = 1,
  inUse = 1,
  subscriptionMax,
  value,
  onChange,
  isCurrentPlan,
  tooltipMin,
}) => {
  return (
    <NumberField
      minValue={minValue}
      maxValue={maxValue}
      step={step}
      value={value}
      onChange={onChange}
    >
      <Label className="text-sm font-medium text-foreground mb-1 flex gap-2 items-center">
        <span>{label}</span>
        {isCurrentPlan && (
          <div className="flex items-center space-x-2">
            <div className="px-1 rounded bg-blue-100/60 text-blue-800/60 text-[10px] leading-4">
              {inUse} / {subscriptionMax}
            </div>
            {value > subscriptionMax && (
              <div className="px-1 rounded bg-green-50 text-green-600 text-[10px] leading-4">
                +{value - subscriptionMax}
              </div>
            )}
            {value < subscriptionMax && (
              <div className="px-1 rounded bg-red-50 text-red-600 text-[10px] leading-4">
                {value - subscriptionMax}
              </div>
            )}
          </div>
        )}
      </Label>
      <div>
        <Group className="relative flex h-9 shrink-0 items-center overflow-hidden whitespace-nowrap rounded-lg border border-input text-sm shadow-sm shadow-black/[.04] ring-offset-background transition-shadow data-[focus-within]:border-ring data-[disabled]:opacity-50 data-[focus-within]:outline-none data-[focus-within]:ring-2 data-[focus-within]:ring-ring/30 data-[focus-within]:ring-offset-2">
          <Button
            slot="decrement"
            data-tooltip-id="tooltipMin"
            data-tooltip-content={tooltipMin}
            className="relative -ms-px flex aspect-square h-[inherit] items-center justify-center rounded-s-lg border border-input bg-background text-sm text-muted-foreground/80 ring-offset-background transition-shadow hover:bg-accent hover:text-foreground disabled:opacity-50"
          >
            <Minus size={16} strokeWidth={2} aria-hidden="true" />
          </Button>

          <Input className="w-full grow bg-background px-3 py-2 text-center tabular-nums text-foreground focus:outline-none" />
          <Button
            slot="increment"
            className="-me-px flex aspect-square h-[inherit] items-center justify-center rounded-e-lg border border-input bg-background text-sm text-muted-foreground/80 ring-offset-background transition-shadow hover:bg-accent hover:text-foreground disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
          >
            <Plus size={16} strokeWidth={2} aria-hidden="true" />
          </Button>
        </Group>
      </div>
      <Tooltip id="tooltipMin" place="top" />
    </NumberField>
  )
}

export default NumberInput
