import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useBackupApp = (appId: string, appEnv: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async () =>
      (await axios.post)<{ result: string }>(
        `/account/apps/${appId}/environments/${appEnv}/backup`
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['app', appId, appEnv], data)
        queryClient.invalidateQueries(['app', appId, appEnv])
        cogoToast.success(
          `Backup requested successfully. You will receive an e-mail with a link to download your backup file.`,
          {
            position: 'bottom-right',
          }
        )
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useBackupApp
