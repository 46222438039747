import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import * as gtag from '../../utils/gtag'

import useLogin from '../../hooks/api/mutations/useLogin'
import Button from '../../components/Button'
import FormField from '../../components/FormField'
import imgLogo from '../../images/reactbricks-logo-v.svg'

type LoginData = {
  email: string
  password: string
  keepLoggedIn: boolean
}

const Login: React.FC = () => {
  // FORM
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginData>()

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const login = useLogin()

  const handleLogin = handleSubmit(({ email, password, keepLoggedIn }) => {
    setLoading(true)
    login
      .mutateAsync({ email, password, keepLoggedIn })
      .then((res) => {
        if (res && res.authToken) {
          // Set LocalStorage
          localStorage.setItem('token', res.authToken)
          localStorage.setItem('email', res.user.email)
          localStorage.setItem('firstName', res.user.firstName)
          localStorage.setItem('lastName', res.user.lastName)
          localStorage.setItem('keepLoggedIn', `${keepLoggedIn}`)

          // Analytics Event
          gtag.trackEvent({
            category: 'login',
            action: 'login',
            label: 'Login completed',
          })

          // Redirect
          navigate('/apps')
          // if (res.user.role === 'NEW_USER') {
          //   navigate('/verify-email')
          // } else {
          //   navigate('/apps')
          // }
        }
      })
      .finally(() => setLoading(false))
  })

  return (
    <div>
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-12 flex justify-center">
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <img src={imgLogo} alt="logo" className="w-32" />
        </a>
      </header>

      <div className="shadow-lg py-8 px-10 max-w-sm rounded mx-auto bg-white">
        <h1 className="text-center text-gray-600 mb-6">
          Welcome to React Bricks
        </h1>
        <form onSubmit={handleLogin}>
          <div className="mb-4 relative">
            <div className="absolute top-0.5 right-0 text-xs">
              <Link
                to="sso-login"
                className="text-blue-600 hover:text-blue-700"
                tabIndex={5}
              >
                Login with SSO
              </Link>
            </div>
            <FormField
              tabIndex={1}
              fieldName="email"
              label="Email"
              labelClassName="block text-sm leading-5 font-medium text-gray-700"
              type="email"
              register={register}
              validation={{
                required: 'Enter your email address',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Invalid email address',
                },
              }}
              error={errors.email}
            />
          </div>
          <div className="mb-4 relative">
            <div className="absolute top-0.5 right-0 text-xs">
              <Link
                tabIndex={6}
                to="forgot-password"
                className="text-blue-600 hover:text-blue-700"
              >
                Forgot password?
              </Link>
            </div>
            <FormField
              tabIndex={2}
              fieldName="password"
              label="Password"
              labelClassName="block text-sm leading-5 font-medium text-gray-700"
              type="password"
              register={register}
              validation={{ required: 'Enter your password' }}
              error={errors.password}
            />
          </div>
          <div className="mb-6">
            <FormField
              tabIndex={3}
              fieldName="keepLoggedIn"
              label="Keep me logged in"
              labelClassName="block text-sm leading-5 font-medium text-gray-700"
              type="checkbox"
              register={register}
            />
          </div>
          <Button
            tabIndex={4}
            full
            color="blue"
            type="submit"
            loading={loading}
          >
            Log in
          </Button>
        </form>

        <div className="mt-6 flex justify-center text-sm gap-x-1">
          {/* <Link
            to="forgot-password"
            className="text-blue-600 hover:text-blue-700"
          >
            Forgot password?
          </Link> */}
          <span>Don't have an account?</span>
          <a
            href="https://reactbricks.com/sign-up"
            className="text-blue-600 hover:text-blue-700"
          >
            Sign up for free
          </a>
        </div>
      </div>
      <div className="mt-4 mb-16 text-gray-700 text-center text-sm">
        © {new Date().getFullYear()}{' '}
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-700"
        >
          ReactBricks.com
        </a>
      </div>
    </div>
  )
}

export default Login
