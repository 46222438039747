import { useQuery } from 'react-query'
import axios from 'axios'

export interface Role {
  id: string
  name: string
  users: number
}

const useCustomRoles = (
  appId: string | null,
  appEnv: string | null,
  flexibleRoles: boolean
) => {
  return useQuery(
    ['appCustomRoles', appId, appEnv],
    async () => {
      const { data } = await axios.get<Role[]>(
        `/account/apps/${appId}/environments/${appEnv}/custom_roles`
      )
      return data
    },
    {
      enabled: !!appId && !!appEnv && !!flexibleRoles,
      staleTime: 5 * 60 * 1000, // 5 minuti
    }
  )
}

export default useCustomRoles
