import { useMutation } from 'react-query'
import axios from 'axios'

const useAcceptInvitation = (token?: string) => {
  return useMutation(
    async (accept: any) => {
      return await axios
        .post<{ message: string }>(`/account/invitation/${token}/accept`, { accept })
        .then((res) => res.data)
    },
    {
      onSuccess: (data: any) => {},
      onError: (error: any) => {},
    }
  )
}

export default useAcceptInvitation
