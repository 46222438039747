import React, { useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import { useForm } from 'react-hook-form'
import { FiInfo } from 'react-icons/fi'

import usePatchAppUser from '../../hooks/api/mutations/usePatchAppUser'

import FormField from '../FormField'
import Button from '../Button'

import blankAvatar from '../../images/avatar.svg'
import { Role } from '../../hooks/api/queries/useCustomRoles'
import type { App } from '../../hooks/api/queries/useApp'
import type { User } from '../../hooks/api/queries/useProfile'

interface EditUserProps {
  app: App
  user: User
  onCloseEditModal: any
  customRoles: Role[]
}

const userRoleOptions = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Editor', value: 'EDITOR' },
]

const EditUser: React.FC<EditUserProps> = ({
  app,
  user,
  onCloseEditModal,
  customRoles,
}) => {
  const patchAppUser = usePatchAppUser(app.appId, app.appEnv, user.id)

  const { handleSubmit, register, watch, reset } = useForm<any>({
    defaultValues: { ...user, customRoleId: user.customRole?.id || '' },
  })

  const onSubmit = (data: any) => {
    patchAppUser.mutateAsync(data).then(onCloseEditModal)
  }

  useEffect(() => {
    reset({ ...user, customRoleId: user.customRole?.id || '' })
  }, [user, reset])

  const watchRole = watch('role')

  const userCustomRoleOptions = [
    {
      value: '',
      label: '-- Select a custom role --',
    },
  ].concat(
    customRoles.map((customRole: Role) => {
      return {
        value: customRole.id,
        label: customRole.name,
      }
    })
  )

  return (
    <div className="px-12 py-8 max-w-2xl">
      <div className="flex flex-col items-center mb-5">
        <img
          className="w-16 rounded-full border mb-1"
          alt="avatar"
          src={user.avatarUrl || blankAvatar}
        />
        <h2 className="text-xl font-semibold">
          <div>
            {user.firstName} {user.lastName}
          </div>
        </h2>
      </div>

      <div className="mb-5 text-sm text-gray-600 min-w-[513px]">
        Here you can set the{' '}
        <span
          className="text-pink-500"
          data-tooltip-id="role"
        >
          Role
        </span>{' '}
        and Permissions for the user.
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <FormField
            fieldName="role"
            type="select"
            register={register}
            options={userRoleOptions}
          />
        </div>
        {!!customRoles && customRoles.length > 0 && (
          <div className="mb-6">
            <FormField
              fieldName="customRoleId"
              type="select"
              register={register}
              options={userCustomRoleOptions}
            />
          </div>
        )}

        {watchRole !== 'ADMIN' && (
          <div className="block sm:flex">
            <div className="md:w-1/2 md:flex-1">
              <div className="mb-4">
                <FormField
                  fieldName="canCreatePage"
                  type="checkbox"
                  register={register}
                  label={<span className="ml-2">Can create pages</span>}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="canDeletePage"
                  type="checkbox"
                  register={register}
                  label={<span className="ml-2">Can delete pages</span>}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="canEditSeo"
                  type="checkbox"
                  register={register}
                  label={<span className="ml-2">Can edit SEO</span>}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="canEditPageAttributes"
                  type="checkbox"
                  register={register}
                  label={
                    <span className="ml-2">Can edit pages attributes</span>
                  }
                />
              </div>
            </div>
            <div className="md:w-1/2 md:flex-1">
              <div className="mb-4">
                <FormField
                  fieldName="canDeploy"
                  type="checkbox"
                  register={register}
                  label={
                    <div className="flex items-center">
                      <span className="ml-2">Can deploy in Production</span>
                      <span
                        id="prod"
                        className="ml-2 text-pink-500"
                        data-tooltip-content={
                          'Checking this flag you allow the Editor user to trigger the Production build hook through the "Deploy" button in React Bricks interface.'
                        }
                        data-tooltip-id="helpDeployEdit"
                      >
                        <FiInfo />
                      </span>
                    </div>
                  }
                />
              </div>
              <div className="mb-4">
                <div
                  data-tooltip-content={'Not available'}
                  data-tooltip-id={
                    !app.subscription.deployHookStaging
                      ? 'canDeployStaging'
                      : ''
                  }
                >
                  <FormField
                    fieldName="canDeployStaging"
                    type="checkbox"
                    register={register}
                    disabled={!app.subscription.deployHookStaging}
                    checkboxLabelClass={
                      !app.subscription.deployHookStaging
                        ? 'text-slate-400'
                        : ''
                    }
                    label={
                      <div className="flex items-center">
                        <span className="ml-2">Can deploy in Staging</span>
                        <span
                          id="staging"
                          className="ml-2 text-pink-500"
                          data-tooltip-content={
                            'Checking this flag you allow the Editor user to trigger the Staging build hook through the "Deploy" button in React Bricks interface.'
                          }
                          data-tooltip-id="helpDeployEdit"
                        >
                          <FiInfo />
                        </span>
                        {/* {!app.subscription.deployHookStaging && (
                          <div className="text-xs font-bold uppercase tracking-wide bg-gray-200 text-gray-500 py-px px-2 rounded-sm">
                            Not available
                          </div>
                        )} */}
                      </div>
                    }
                  />
                  {!app.subscription.deployHookStaging && (
                    <Tooltip id="canDeployStaging" place="bottom" />
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div
                  data-tooltip-content={'Not available'}
                  data-tooltip-id={
                    !app.subscription.deployHookDev ? 'canDeployDev' : ''
                  }
                >
                  <FormField
                    fieldName="canDeployDev"
                    type="checkbox"
                    register={register}
                    disabled={!app.subscription.deployHookDev}
                    checkboxLabelClass={
                      !app.subscription.deployHookDev ? 'text-slate-400' : ''
                    }
                    label={
                      <div className="flex items-center">
                        <span className="ml-2">Can deploy in Development</span>
                        <span
                          id="dev"
                          className="ml-2 text-pink-500"
                          data-tooltip-content={
                            'Checking this flag you allow the Editor user to trigger the Development build hook through the "Deploy" button in React Bricks interface.'
                          }
                          data-tooltip-id="helpDeployEdit"
                        >
                          <FiInfo />
                        </span>
                        {/* {!app.subscription.deployHookDev && (
                          <div className="text-xs font-bold uppercase tracking-wide bg-gray-200 text-gray-500 py-px px-2 rounded-sm">
                            Not available
                          </div>
                        )} */}
                      </div>
                    }
                  />
                  {!app.subscription.deployHookDev && (
                    <Tooltip id="canDeployDev" place="bottom" />
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div
                  data-tooltip-content={'Not available'}
                  data-tooltip-id="canApprovePage"
                >
                  <FormField
                    fieldName="canApprove"
                    type="checkbox"
                    register={register}
                    disabled={
                      !(
                        app.subscription.workingCopy &&
                        app.subscription.approvalWorkflow &&
                        app.useWorkingCopy &&
                        app.useApprovalWorkflow
                      )
                    }
                    checkboxLabelClass={
                      !(
                        app.subscription.workingCopy &&
                        app.subscription.approvalWorkflow &&
                        app.useWorkingCopy &&
                        app.useApprovalWorkflow
                      )
                        ? 'text-slate-400'
                        : ''
                    }
                    label={
                      <div className="flex items-center">
                        <span className="ml-2">Can approve pages</span>
                      </div>
                    }
                  />
                  {!(
                    app.subscription.workingCopy &&
                    app.subscription.approvalWorkflow &&
                    app.useWorkingCopy &&
                    app.useApprovalWorkflow
                  ) && <Tooltip id="canApprovePage" place="bottom" />}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center space-x-4 mt-6">
          <Button color="gray" onClick={onCloseEditModal} className="flex-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            loading={patchAppUser.isLoading}
            className="flex-1"
          >
            Save
          </Button>
        </div>

        {patchAppUser.isError && (
          <span className="block mt-2 text-red-500 md:inline-block md:mt-0">
            An error occurred while editing this user
          </span>
        )}
      </form>

      <Tooltip id="role" place="top" className="w-1/2">
        <div className="text-sm leading-tight text-gray-400">
          <p className="mb-2">
            <b className="text-pink-400">Admin users</b> can edit app settings,
            invite other users and edit content.
          </p>
          <p>
            <b className="text-pink-400">Editor users</b> can just edit content
            using React Bricks interface.
          </p>
        </div>
      </Tooltip>
    </div>
  )
}

export default EditUser
