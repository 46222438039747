import axios from 'axios'
// import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'

interface PatchAppCustomRole {
  name: string
}

const usePatchAppCustomRole = (
  appId: string,
  appEnv: string,
  roleId: string
) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: PatchAppCustomRole) => {
      const roleToSave = {
        name: values.name,
      }
      return await axios
        .patch(
          `/account/apps/${appId}/environments/${appEnv}/custom_roles/${roleId}`,
          roleToSave
        )
        .then((res) => res.data)
    },
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['appCustomRoles', appId, appEnv])
        queryClient.invalidateQueries(['appUsers', appId, appEnv])
        //queryClient.invalidateQueries(['app', appId])
        //cogoToast.success('User settings saved successfully', { position: 'bottom-right' })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchAppCustomRole
