import React, { ReactElement } from 'react'

import AsideMenu from '../../../components/AsideMenu'

import Avatar from './Avatar'
import UserDetails from './UserDetails'
import ChangeEmail from './ChangeEmail'
import ChangePassword from './ChangePassword'
import AccountName from './AccountName'
import DeleteAccount from './DeleteAccount'
import type { User } from '../../../hooks/api/queries/useProfile'

interface UserRoute {
  path: string
  label: string
  element: ReactElement
}

interface UserPageProps {
  user: User
}

const UserPage: React.FC<UserPageProps> = ({ user }) => {
  const USER_ROUTES: UserRoute[] = [
    {
      path: 'avatar',
      label: 'Your Avatar',
      element: <Avatar user={user} />,
    },
    {
      path: 'user-details',
      label: 'User details',
      element: <UserDetails user={user} />,
    },
    {
      path: 'change-email',
      label: 'Change E-mail',
      element: <ChangeEmail user={user} />,
    },
    {
      path: 'change-password',
      label: 'Change Password',
      element: <ChangePassword />,
    },
    {
      path: 'account',
      label: 'Account name',
      element: <AccountName user={user} />,
    },
    {
      path: 'delete-account',
      label: 'Delete account',
      element: <DeleteAccount />,
    },
  ]

  return <AsideMenu menuTitle="User settings" routes={USER_ROUTES} indexRoute="/user" />
}

export default UserPage
