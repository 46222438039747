import axios from 'axios'
import { useQuery } from 'react-query'

export interface User {
  id: string
  avatarUrl: string
  firstName: string
  lastName: string
  email: string
  company?: string
  role: string
  isOwner: boolean
  canDeploy: boolean
  canDeployStaging: boolean
  canDeployDev: boolean
  canCreatePage: boolean
  canDeletePage: boolean
  canApprove: boolean
  canEditPageAttributes: boolean
  canEditSeo: boolean
  customRole: {
    id: string
    name: string
  }
  status?: 'PENDING' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED'
  // createdAt: string
  // expiresAt: string
  // answeredAt?: string
  accountId: string
  accountName: string
  newEmail: string
  isVerified: boolean
}

const useProfile = (token?: string | null, refreshToken?: boolean) => {
  return useQuery(
    'profile',
    async () => {
      const queryToken = refreshToken ? `?refreshToken=${refreshToken}` : ''
      const { data } = await axios.get<{ user: User; authToken: string }>(
        `/account/session${queryToken}`
      )

      if (data.authToken) {
        //console.log('New token', data.authToken.slice(-5))
        localStorage.setItem('token', data.authToken)
      }
      return data.user
    },
    {
      // for checkLogged enable only if I have a token
      enabled: !refreshToken || !!token,
      staleTime: 30 * 60 * 1000, // 30 minuti
    }
  )
}

export default useProfile
