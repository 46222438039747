import React from 'react'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

const Loading = () => {
  return (
    <div className="text-center mx-auto py-6">
      <Loader type="Grid" color="#f65a8e" height={40} width={40} />
    </div>
  )
}

export default Loading
