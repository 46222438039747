import React from 'react'

import Button from '../../components/Button'
import useDeleteAppEnvironment from '../../hooks/api/mutations/useDeleteAppEnvironment'
import { Environment } from '../../hooks/api/queries/useApp'

interface RemoveEnvironmentProps {
  appId: string
  environment: Environment
  onCloseDeleteModal: any
}

const RemoveEnvironment: React.FC<RemoveEnvironmentProps> = ({
  appId,
  environment,
  onCloseDeleteModal,
}) => {
  const deleteEnvironment = useDeleteAppEnvironment(appId, environment.name)

  const handleDeleteEnvironment = () => {
    deleteEnvironment.mutate()
    onCloseDeleteModal()
  }

  return (
    <div className="px-12 py-8 max-w-md">
      <div className="flex flex-col items-center mb-4">
        <h2 className="text-lg text-red-600 font-semibold mb-5">
          Remove "{environment.name}" environment
        </h2>
        <p className="mb-5 text-sm text-gray-600">
          The environment, its content and its configurations (like users or
          build hooks) will be deleted.
        </p>
      </div>
      <div className="flex justify-between space-x-4">
        <Button color="gray" className="flex-1" onClick={onCloseDeleteModal}>
          Cancel
        </Button>
        <Button
          color="red"
          className="flex-1"
          loading={deleteEnvironment.isLoading}
          onClick={handleDeleteEnvironment}
        >
          Remove
        </Button>
      </div>
    </div>
  )
}

export default RemoveEnvironment
