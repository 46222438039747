import { useMutation } from 'react-query'
import axios from 'axios'

interface ISsoLoginCredentials {
  uniqueAppIdentifier: string
}

const getToken = async ({ uniqueAppIdentifier }: ISsoLoginCredentials) => {
  const { data } = await axios.post<{
    result: 'OK' | 'KO'
    redirectUrl: string
  }>(`/account/sso_login`, { uniqueAppIdentifier })
  return data
}

const useSsoLogin = () => {
  return useMutation(
    ({ uniqueAppIdentifier }: ISsoLoginCredentials) => {
      return getToken({ uniqueAppIdentifier })
    },
    {
      onSuccess: (data) => {
        const { result, redirectUrl } = data
      },
      onError: () => {},
    }
  )
}

export default useSsoLogin
