import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useDeleteInvitation = (
  appId: string,
  appEnv: string,
  invitationId: string
) => {
  const queryClient = useQueryClient()
  return useMutation(
    async () =>
      await axios
        .delete(
          `/account/apps/${appId}/environments/${appEnv}/invitations/${invitationId}`
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appInvitations', appId, appEnv])
        queryClient.invalidateQueries(['app', appId, appEnv])
        cogoToast.success('Invitation deleted successfully', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useDeleteInvitation
