import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useChangeAvatar = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (values: FormData) =>
      await axios.post<{ avatarUrl: string }>(`/account/profile/change_avatar`, values).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('profile', data)
        queryClient.invalidateQueries('profile')
        cogoToast.success('Avatar updated successfully', { position: 'bottom-right' })
      },
      onError: (error: any) => {
        console.log(error, 'error')
      },
    }
  )
}

export default useChangeAvatar
