import React, { useState } from 'react'

import type { App, MonthUsage } from '../../../../hooks/api/queries/useApp'
import Card from '../../../../components/Card'

import BillingCycleStats from '../../../../components/usage/BillingCycleStats'
import { BarChart } from '../../../../components/usage/BarChart'

interface UsageProps {
  app: App
}

const fixMonthlyData = (billingCycle?: MonthUsage[]) => {
  if (!billingCycle) {
    return []
  }

  return billingCycle.map((bc) => ({
    ...bc,
    'Usage within limits':
      bc.usage > bc.subscriptionMax ? bc.subscriptionMax : bc.usage,
    Free: bc.subscriptionMax > bc.usage ? bc.subscriptionMax - bc.usage : 0,
    Overage: bc.usage > bc.subscriptionMax ? bc.usage - bc.subscriptionMax : 0,
  }))
}

const Usage: React.FC<UsageProps> = ({ app }) => {
  // Trick (Stats will come from API)
  const appWithStats: App = {
    ...app,
    // usage: {
    //   currentBillingCycle: {
    //     diskSpace: {
    //       usage: 82,
    //       subscriptionMax: 100,
    //       overagePrice: 0,
    //       dailyUsage: [
    //         {
    //           day: 'Sep 12',
    //           usage: 20,
    //         },
    //         {
    //           day: 'Sep 13',
    //           usage: 20,
    //         },
    //         {
    //           day: 'Sep 14',
    //           usage: 25,
    //         },
    //         {
    //           day: 'Sep 15',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 16',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 17',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 18',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 19',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 20',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 21',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 22',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 23',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 24',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 25',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 26',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 27',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 28',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 29',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Sep 30',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Oct 1',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Oct 2',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Oct 3',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Oct 4',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Oct 5',
    //           usage: 30,
    //         },
    //         {
    //           day: 'Oct 6',
    //           usage: 40,
    //         },
    //         {
    //           day: 'Oct 7',
    //           usage: 45,
    //         },
    //         {
    //           day: 'Oct 8',
    //           usage: 50,
    //         },
    //         {
    //           day: 'Oct 9',
    //           usage: 50,
    //         },
    //         {
    //           day: 'Oct 10',
    //           usage: 50,
    //         },
    //         {
    //           day: 'Oct 11',
    //           usage: 50,
    //         },
    //       ],
    //     },
    //     bandwidth: {
    //       usage: 12,
    //       subscriptionMax: 10,
    //       overagePrice: 5,
    //       dailyUsage: [
    //         {
    //           day: 'Sep 12',
    //           usage: 0.3,
    //         },
    //         {
    //           day: 'Sep 13',
    //           usage: 0.5,
    //         },
    //         {
    //           day: 'Sep 14',
    //           usage: 1,
    //         },
    //         {
    //           day: 'Sep 15',
    //           usage: 0.3,
    //         },
    //         {
    //           day: 'Sep 16',
    //           usage: 0.35,
    //         },
    //         {
    //           day: 'Sep 17',
    //           usage: 0.3,
    //         },
    //         {
    //           day: 'Sep 18',
    //           usage: 0.2,
    //         },
    //         {
    //           day: 'Sep 19',
    //           usage: 0.1,
    //         },
    //         {
    //           day: 'Sep 20',
    //           usage: 0.3,
    //         },
    //         {
    //           day: 'Sep 21',
    //           usage: 0.5,
    //         },
    //         {
    //           day: 'Sep 22',
    //           usage: 0.45,
    //         },
    //         {
    //           day: 'Sep 23',
    //           usage: 0.37,
    //         },
    //         {
    //           day: 'Sep 24',
    //           usage: 0.39,
    //         },
    //         {
    //           day: 'Sep 25',
    //           usage: 0.6,
    //         },
    //         {
    //           day: 'Sep 26',
    //           usage: 1.6,
    //         },
    //         {
    //           day: 'Sep 27',
    //           usage: 1.2,
    //         },
    //         {
    //           day: 'Sep 28',
    //           usage: 0.7,
    //         },
    //         {
    //           day: 'Sep 29',
    //           usage: 0.5,
    //         },
    //         {
    //           day: 'Sep 30',
    //           usage: 0.3,
    //         },
    //         {
    //           day: 'Oct 1',
    //           usage: 0.2,
    //         },
    //         {
    //           day: 'Oct 2',
    //           usage: 0.15,
    //         },
    //         {
    //           day: 'Oct 3',
    //           usage: 0.24,
    //         },
    //         {
    //           day: 'Oct 4',
    //           usage: 0.27,
    //         },
    //         {
    //           day: 'Oct 5',
    //           usage: 0.36,
    //         },
    //         {
    //           day: 'Oct 6',
    //           usage: 0.3,
    //         },
    //         {
    //           day: 'Oct 7',
    //           usage: 0.45,
    //         },
    //         {
    //           day: 'Oct 8',
    //           usage: 0.5,
    //         },
    //         {
    //           day: 'Oct 9',
    //           usage: 0.5,
    //         },
    //         {
    //           day: 'Oct 10',
    //           usage: 0.46,
    //         },
    //         {
    //           day: 'Oct 11',
    //           usage: 0.56,
    //         },
    //       ],
    //     },
    //     apiCalls: {
    //       usage: 27564,
    //       subscriptionMax: 50000,
    //       overagePrice: 0,
    //       dailyUsage: [
    //         {
    //           day: 'Sep 12',
    //           usage: 0.32 * 2950,
    //         },
    //         {
    //           day: 'Sep 13',
    //           usage: 0.45 * 2950,
    //         },
    //         {
    //           day: 'Sep 14',
    //           usage: 0.93 * 2950,
    //         },
    //         {
    //           day: 'Sep 15',
    //           usage: 0.32 * 2950,
    //         },
    //         {
    //           day: 'Sep 16',
    //           usage: 0.36 * 2950,
    //         },
    //         {
    //           day: 'Sep 17',
    //           usage: 0.24 * 2950,
    //         },
    //         {
    //           day: 'Sep 18',
    //           usage: 0.22 * 2950,
    //         },
    //         {
    //           day: 'Sep 19',
    //           usage: 0.14 * 2950,
    //         },
    //         {
    //           day: 'Sep 20',
    //           usage: 0.32 * 2950,
    //         },
    //         {
    //           day: 'Sep 21',
    //           usage: 0.55 * 2950,
    //         },
    //         {
    //           day: 'Sep 22',
    //           usage: 0.41 * 2950,
    //         },
    //         {
    //           day: 'Sep 23',
    //           usage: 0.43 * 2950,
    //         },
    //         {
    //           day: 'Sep 24',
    //           usage: 0.46 * 2950,
    //         },
    //         {
    //           day: 'Sep 25',
    //           usage: 0.5 * 2950,
    //         },
    //         {
    //           day: 'Sep 26',
    //           usage: 1.4 * 2950,
    //         },
    //         {
    //           day: 'Sep 27',
    //           usage: 1.3 * 2950,
    //         },
    //         {
    //           day: 'Sep 28',
    //           usage: 0.62 * 2950,
    //         },
    //         {
    //           day: 'Sep 29',
    //           usage: 0.45 * 2950,
    //         },
    //         {
    //           day: 'Sep 30',
    //           usage: 0.32 * 2950,
    //         },
    //         {
    //           day: 'Oct 1',
    //           usage: 0.24 * 2950,
    //         },
    //         {
    //           day: 'Oct 2',
    //           usage: 0.23 * 2950,
    //         },
    //         {
    //           day: 'Oct 3',
    //           usage: 0.2 * 2950,
    //         },
    //         {
    //           day: 'Oct 4',
    //           usage: 0.31 * 2950,
    //         },
    //         {
    //           day: 'Oct 5',
    //           usage: 0.45 * 2950,
    //         },
    //         {
    //           day: 'Oct 6',
    //           usage: 0.32 * 2950,
    //         },
    //         {
    //           day: 'Oct 7',
    //           usage: 0.53 * 2950,
    //         },
    //         {
    //           day: 'Oct 8',
    //           usage: 0.54 * 2950,
    //         },
    //         {
    //           day: 'Oct 9',
    //           usage: 0.6 * 2950,
    //         },
    //         {
    //           day: 'Oct 10',
    //           usage: 0.47 * 2950,
    //         },
    //         {
    //           day: 'Oct 11',
    //           usage: 0.51 * 2950,
    //         },
    //       ],
    //     },
    //   },
    //   previousBillingCycle: {
    //     diskSpace: {
    //       usage: 45,
    //       subscriptionMax: 100,
    //       overagePrice: 0,
    //       dailyUsage: [
    //         {
    //           day: 'Aug 12',
    //           usage: 11,
    //         },
    //         {
    //           day: 'Aug 13',
    //           usage: 11,
    //         },
    //         {
    //           day: 'Aug 14',
    //           usage: 12,
    //         },
    //         {
    //           day: 'Aug 15',
    //           usage: 12,
    //         },
    //         {
    //           day: 'Aug 16',
    //           usage: 12,
    //         },
    //         {
    //           day: 'Aug 17',
    //           usage: 13,
    //         },
    //         {
    //           day: 'Aug 18',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 19',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 20',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 21',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 22',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 23',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 24',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 25',
    //           usage: 14,
    //         },
    //         {
    //           day: 'Aug 26',
    //           usage: 15,
    //         },
    //         {
    //           day: 'Aug 27',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Aug 28',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Aug 29',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Aug 30',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Sep 1',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Sep 2',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Sep 3',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Sep 4',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Sep 5',
    //           usage: 16,
    //         },
    //         {
    //           day: 'Sep 6',
    //           usage: 17,
    //         },
    //         {
    //           day: 'Sep 7',
    //           usage: 18,
    //         },
    //         {
    //           day: 'Sep 8',
    //           usage: 19,
    //         },
    //         {
    //           day: 'Sep 9',
    //           usage: 20,
    //         },
    //         {
    //           day: 'Sep 10',
    //           usage: 20,
    //         },
    //         {
    //           day: 'Sep 11',
    //           usage: 20,
    //         },
    //       ],
    //     },
    //     bandwidth: {
    //       usage: 8,
    //       subscriptionMax: 10,
    //       overagePrice: 0,
    //       dailyUsage: [
    //         {
    //           day: 'Sep 1',
    //           usage: 0.4,
    //         },
    //         {
    //           day: 'Sep 2',
    //           usage: 0.7,
    //         },
    //         {
    //           day: 'Oct 1',
    //           usage: 0.6,
    //         },
    //       ],
    //     },
    //     apiCalls: {
    //       usage: 45923,
    //       subscriptionMax: 50000,
    //       overagePrice: 0,
    //       dailyUsage: [
    //         {
    //           day: 'Sep 1',
    //           usage: 3400,
    //         },
    //         {
    //           day: 'Sep 2',
    //           usage: 2390,
    //         },
    //         {
    //           day: 'Oct 1',
    //           usage: 2201,
    //         },
    //       ],
    //     },
    //   },
    //   last12months: {
    //     diskSpace: [
    //       {
    //         billingCycle: 'Sep 7',
    //         usage: 35,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Oct 7',
    //         usage: 39,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Nov 7',
    //         usage: 42,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Dec 7',
    //         usage: 45,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Gen 7',
    //         usage: 45,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Feb 7',
    //         usage: 47,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Mar 7',
    //         usage: 49,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Apr 7',
    //         usage: 51,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Mag 7',
    //         usage: 53,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Giu 7',
    //         usage: 56,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Lug 7',
    //         usage: 56,
    //         subscriptionMax: 50,
    //       },
    //       {
    //         billingCycle: 'Ago 7',
    //         usage: 62,
    //         subscriptionMax: 50,
    //       },
    //     ],
    //     bandwidth: [
    //       {
    //         billingCycle: 'Sep 7',
    //         usage: 4,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Oct 7',
    //         usage: 5,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Nov 7',
    //         usage: 7,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Dec 7',
    //         usage: 4,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Gen 7',
    //         usage: 12,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Feb 7',
    //         usage: 8,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Mar 7',
    //         usage: 6,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Apr 7',
    //         usage: 10,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Mag 7',
    //         usage: 13,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Giu 7',
    //         usage: 6,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Lug 7',
    //         usage: 4,
    //         subscriptionMax: 10,
    //       },
    //       {
    //         billingCycle: 'Ago 7',
    //         usage: 3,
    //         subscriptionMax: 10,
    //       },
    //     ],
    //     apiCalls: [
    //       {
    //         billingCycle: 'Sep 7',
    //         usage: 12453,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Oct 7',
    //         usage: 9334,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Nov 7',
    //         usage: 17082,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Dec 7',
    //         usage: 5291,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Gen 7',
    //         usage: 62902,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Feb 7',
    //         usage: 7982,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Mar 7',
    //         usage: 12892,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Apr 7',
    //         usage: 68213,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Mag 7',
    //         usage: 82012,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Giu 7',
    //         usage: 18202,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Lug 7',
    //         usage: 8023,
    //         subscriptionMax: 50000,
    //       },
    //       {
    //         billingCycle: 'Ago 7',
    //         usage: 9212,
    //         subscriptionMax: 50000,
    //       },
    //     ],
    //   },
    // },
  }

  const [billingCycle, setBillingCycle] = useState<'current' | 'previous'>(
    'current'
  )

  const toggleBillingCycle = () => {
    if (billingCycle === 'current') {
      setBillingCycle('previous')
    } else {
      setBillingCycle('current')
    }
  }

  const billingCycleData =
    billingCycle === 'current'
      ? appWithStats.usage?.currentBillingCycle
      : appWithStats.usage?.previousBillingCycle

  return (
    <>
      <Card
        title={`${
          billingCycle === 'current' ? 'Current' : 'Previous'
        } Billing Cycle`}
        actionButton={
          <button
            onClick={() => toggleBillingCycle()}
            className="text-sm text-blue-500 hover:text-blue-600 transition-colors mb-4"
          >
            Show {billingCycle === 'current' ? 'previous' : 'current'} billing
            cycle
          </button>
        }
      >
        {billingCycleData ? (
          <div className="mt-3 grid grid-cols-1 gap-6 lg:grid-cols-3">
            <BillingCycleStats
              stat={billingCycleData.diskSpace}
              label="Storage used"
              unit=""
              valueTransformer={(value) => {
                if (value < 1000) {
                  return `${value.toFixed(0)}MB`
                }
                return `${(value / 1000).toFixed(0)}GB`
              }}
            />
            <BillingCycleStats
              stat={billingCycleData.bandwidth}
              label="Bandwidth (traffic)"
              unit=""
              valueTransformer={(value) => {
                if (value < 1000) {
                  return `${value.toFixed(0)}MB`
                }
                return `${(value / 1000).toFixed(0)}GB`
              }}
            />
            <BillingCycleStats
              stat={billingCycleData.apiCalls}
              label="Number of API Calls"
              unit=""
              valueTransformer={(value) => {
                if (value < 1000000) {
                  return `${(value / 1000).toFixed(0)}K`
                }
                return `${(value / 1000000).toFixed(0)}M`
              }}
            />
          </div>
        ) : (
          <div className="mt-3 relative w-full border p-6 text-center shadow-sm bg-white dark:bg-[#090E1A] border-gray-200 dark:border-gray-900 hidden flex-col justify-between rounded-md sm:flex">
            No data for this billing cycle
          </div>
        )}
      </Card>
      <Card title="Last 12 Months">
        {/* <h2 className="text-base font-bold mb-3">Disk Space</h2> */}
        <div className="mt-8 flex flex-col gap-4">
          <h2 className="mx-auto font-mono text-sm font-medium">Disk Space</h2>
          <BarChart
            type="stacked"
            data={fixMonthlyData(appWithStats.usage?.last12months.diskSpace)}
            index="billingCycle"
            categories={['Usage within limits', 'Free', 'Overage']}
            colors={['blue', 'lightBlue', 'pink']}
          />
        </div>

        <div className="mt-24 flex flex-col gap-4">
          <h2 className="mx-auto font-mono text-sm font-medium">Bandwidth</h2>
          <BarChart
            type="stacked"
            data={fixMonthlyData(appWithStats.usage?.last12months.bandwidth)}
            index="billingCycle"
            categories={['Usage within limits', 'Free', 'Overage']}
            colors={['blue', 'lightBlue', 'pink']}
          />
        </div>

        <div className="mt-24 flex flex-col gap-4">
          <h2 className="mx-auto font-mono text-sm font-medium">API Calls</h2>
          <BarChart
            type="stacked"
            data={fixMonthlyData(appWithStats.usage?.last12months.apiCalls)}
            index="billingCycle"
            categories={['Usage within limits', 'Free', 'Overage']}
            colors={['blue', 'lightBlue', 'pink']}
          />
        </div>
      </Card>
    </>
  )
}

export default Usage
