import React, { useEffect, useState } from 'react'
import QueryString from 'qs'
import * as gtag from '../../utils/gtag'
import { useLocation, useNavigate } from 'react-router-dom'
import useLogin from '../../hooks/api/mutations/useLogin'
import Loading from '../../components/Loading'

const SsoLoginSuccess: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const login = useLogin()
  const location = useLocation()

  useEffect(() => {
    const qsSsoLoginToken = (
      QueryString.parse(location.search, { ignoreQueryPrefix: true }).t || ''
    ).toString()

    setLoading(true)
    login
      .mutateAsync({ ssoToken: qsSsoLoginToken })
      .then((res) => {
        if (res && res.authToken) {
          // Set LocalStorage
          localStorage.setItem('token', res.authToken)
          localStorage.setItem('email', res.user.email)
          localStorage.setItem('firstName', res.user.firstName)
          localStorage.setItem('lastName', res.user.lastName)

          // Analytics Event
          gtag.trackEvent({
            category: 'login',
            action: 'login',
            label: 'SSO login completed',
          })

          // Redirect
          navigate('/apps')
        }
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center justify-center text-center">
        <Loading />
        <h3 className="mt-3 text-lg">Authenticating...</h3>
      </div>
    </div>
  )
}

export default SsoLoginSuccess
