import React, { useState } from 'react'
import Modal from 'react-modal'
import { FiPlusCircle } from 'react-icons/fi'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import RolesRow from '../../../../components/CustomRoles/CustomRolesRow'
import RemoveRole from '../../../../components/CustomRoles/RemoveRole'
import CreateRole from '../../../../components/CustomRoles/CreateRole'
import EditRole from '../../../../components/CustomRoles/EditRole'

import useCustomRoles, {
  Role,
} from '../../../../hooks/api/queries/useCustomRoles'
import type { App } from '../../../../hooks/api/queries/useApp'

import { modalCustomStyles } from '../../../../styles/modalStyles'

import { Tooltip } from 'react-tooltip'
interface CustomRolesProps {
  app: App
}

const CustomRoles: React.FC<CustomRolesProps> = ({ app }) => {
  const [deleteRole, setDeleteRole] = useState<Role | null>(null)
  const [createRole, setCreateRole] = useState<boolean>(false)
  const [editRole, setEditRole] = useState<Role | null>(null)

  const customRolesQuery = useCustomRoles(
    app.appId,
    app.appEnv,
    app.subscription.flexibleRoles
  )

  const openDeleteModal = (role: Role) => () => {
    setDeleteRole(role)
  }
  const closeDeleteModal = () => {
    setDeleteRole(null)
  }

  const openCreateModal = () => {
    setCreateRole(true)
  }
  const closeCreateModal = () => {
    setCreateRole(false)
  }

  const openEditModal = (role: Role) => () => {
    setEditRole(role)
  }
  const closeEditModal = () => {
    setEditRole(null)
  }

  return (
    <div>
      {!customRolesQuery.isLoading && (
        <Card
          title="Custom roles"
          actionButton={
            <Button
              color="green"
              className="flex items-center pl-3 mb-4 relative"
              onClick={openCreateModal}
              disabled={!app.subscription.flexibleRoles}
            >
              <>
                <div
                  className="absolute top-0 bottom-0 right-0 left-0"
                  data-tooltip-id="userCan"
                  data-tooltip-content={'Not available for your plan'}
                />
                {!app.subscription.flexibleRoles && (
                  <Tooltip
                    id="userCan"
                    place="top"
                  />
                )}
              </>
              <FiPlusCircle
                className={`mr-2 ${
                  !app.subscription.flexibleRoles ? '' : 'text-lime-200'
                }`}
              />
              Add custom role
            </Button>
          }
        >
          {customRolesQuery?.data?.map((role) => {
            return (
              <RolesRow
                key={role.id}
                role={role}
                onOpenEditModal={openEditModal}
                onOpenDeleteModal={openDeleteModal}
              />
            )
          })}
        </Card>
      )}

      <Modal
        isOpen={!!deleteRole}
        onRequestClose={closeDeleteModal}
        style={modalCustomStyles}
      >
        {deleteRole && (
          <RemoveRole
            appId={app.appId}
            appEnv={app.appEnv}
            role={deleteRole}
            onCloseDeleteModal={closeDeleteModal}
          />
        )}
      </Modal>

      <Modal
        isOpen={!!createRole}
        onRequestClose={closeCreateModal}
        style={modalCustomStyles}
      >
        {createRole && (
          <CreateRole
            appId={app.appId}
            appEnv={app.appEnv}
            onCloseCreateModal={closeCreateModal}
          />
        )}
      </Modal>

      <Modal
        isOpen={!!editRole}
        onRequestClose={closeEditModal}
        style={modalCustomStyles}
      >
        {!!editRole && (
          <EditRole
            appId={app.appId}
            appEnv={app.appEnv}
            role={editRole}
            onCloseEditModal={closeEditModal}
          />
        )}
      </Modal>
    </div>
  )
}

export default CustomRoles
