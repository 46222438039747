import React from 'react'
import { Tooltip } from 'react-tooltip'
import { useForm } from 'react-hook-form'

import FormField from '../FormField'
import Button from '../Button'

import useTransferOwnership from '../../hooks/api/mutations/useTransferOwnership'

interface User {
  id: string
  avatarUrl?: string
  firstName?: string
  lastName?: string
  email: string
  company?: string
  role: string
  canDeploy: boolean
  canDeployStaging: boolean
  canDeployDev: boolean
  canCreatePage: boolean
  canDeletePage: boolean
  canApprove: boolean
  canEditPageAttributes: boolean
  canEditSeo: boolean
  customRole?: {
    id: string
    name: string
  }
  status?: 'PENDING' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED'
}

interface App {
  appId: string
  appEnv: string
  appName: string
  subscription: {
    deployHookStaging: boolean
    deployHookDev: boolean
    workingCopy: boolean
    approvalWorkflow: boolean
  }
  useWorkingCopy: boolean
  useApprovalWorkflow: boolean
}

interface TransferOwnershipProps {
  app: App
  adminsNotOwners: User[]
  onCloseModal: any
}

const TransferOwnership: React.FC<TransferOwnershipProps> = ({
  app,
  adminsNotOwners,
  onCloseModal,
}) => {
  const transferOwnership = useTransferOwnership(app.appId, app.appEnv)

  const {
    handleSubmit,
    register,
    watch,
    // reset
  } = useForm<{ userId: string }>({
    defaultValues: { userId: '' },
  })

  const onSubmit = ({ userId }: { userId: string }) => {
    transferOwnership.mutateAsync(userId).then(onCloseModal)
  }

  const usersOptions = [
    {
      value: '',
      label: '-- Select an admin user --',
    },
  ].concat(
    adminsNotOwners.map((user: User) => {
      return {
        value: user.id,
        label: `${user.firstName} ${user.lastName} <${user.email}>`,
      }
    })
  )

  const currentSelectedUser = adminsNotOwners.find(
    (u) => u.id === watch('userId')
  )

  return (
    <div className="px-12 py-8 max-w-xl">
      <h2 className="text-xl font-semibold text-center mb-5">
        Transfer ownership
      </h2>

      <div className="mb-5 text-sm text-gray-600">
        Transfer the ownership of this App to another user. This can be useful
        when handing over the project to a responsible person within the
        company, allowing them to manage plans and billing, for example.
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <FormField
            fieldName="userId"
            type="select"
            register={register}
            options={usersOptions}
          />
        </div>

        {currentSelectedUser && (
          <div className="mb-5 text-sm p-3 border-2 border-red-600">
            <b className="text-red-600">WARNING</b>
            <br />
            You will lose the Owner role on this App, which will be assigned to{' '}
            {currentSelectedUser.firstName} {currentSelectedUser.lastName}. This
            action is irreversible, unless {currentSelectedUser.firstName}{' '}
            decides to transfer the ownership back to you.
          </div>
        )}

        <div className="flex items-center space-x-4 mt-6">
          <Button color="gray" onClick={onCloseModal} className="flex-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            disabled={transferOwnership.isLoading || !watch('userId')}
            loading={transferOwnership.isLoading}
            className="flex-1"
          >
            Confirm Transfer
          </Button>
        </div>

        {transferOwnership.isError && (
          <span className="block mt-6 text-red-500">
            An error occurred while transferring the ownership
          </span>
        )}
      </form>

      <Tooltip
        id="role"
        place="top"
        className="w-1/2"
      >
        <div className="text-sm leading-tight text-gray-400">
          <p className="mb-2">
            <b className="text-pink-400">Admin users</b> can edit app settings,
            invite other users and edit content.
          </p>
          <p>
            <b className="text-pink-400">Editor users</b> can just edit content
            using React Bricks interface.
          </p>
        </div>
      </Tooltip>
    </div>
  )
}

export default TransferOwnership
