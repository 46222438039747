import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Analytics from './Analytics'
import AxiosConfig from './Axios'
import AcceptInvitation from './pages/auth/AcceptInvitation'
import ForgotPassword from './pages/auth/ForgotPassword'
import Login from './pages/auth/Login'
import Pause from './pages/auth/Pause'
import ResetPassword from './pages/auth/ResetPassword'
import SignUp from './pages/auth/SignUp'
import VerifyEmail from './pages/auth/VerifyEmail'
import LoggedApp from './pages/LoggedApp'
import SsoLogin from './pages/auth/SsoLogin'
import SsoLoginSuccess from './pages/auth/SsoLoginSuccess'
import SsoLoginFailure from './pages/auth/SsoLoginFailure'

const App = () => {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <div className="app text-gray-900 antialiased">
        <BrowserRouter>
          <AxiosConfig />
          <Analytics />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/sso-login" element={<SsoLogin />} />
            <Route path="/sso-success" element={<SsoLoginSuccess />} />
            <Route path="/sso-failure" element={<SsoLoginFailure />} />
            <Route path="/pause" element={<Pause />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-up/:invitationToken" element={<SignUp />} />
            <Route
              path="/accept-invitation/:invitationToken"
              element={<AcceptInvitation />}
            />
            <Route path="/*" element={<LoggedApp />} />
          </Routes>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  )
}

export default App
