import React from 'react'
import classNames from 'classnames'

export type PlanType =
  | 'Free'
  | 'Developer'
  // | 'Essential'
  | 'Starter'
  | 'Pro'
  | 'Pro - yearly'
  | 'Entry'
  | 'Business'
  | 'Enterprise'
  | 'Enterprise Trial'
  | 'AppSumo Tier 1'
  | 'AppSumo Tier 2'
  | 'AppSumo Tier 3'
  | 'Basic'
  | 'Scale up'
  | 'Custom'
  | 'Custom - Free'

export const getPlanName = (
  plan: PlanType,
  isLiveDemo?: boolean,
  isBoosted?: boolean
) => {
  if (isLiveDemo) {
    return 'Live Demo'
  }
  if (isBoosted) {
    return 'Boosted Developer'
  }
  if (plan === 'Developer') {
    return 'Free'
  }
  if (plan === 'Pro') {
    return 'Pro'
  }
  if (plan === 'Pro - yearly') {
    return 'Pro'
  }
  // if (plan === 'Basic') {
  //   return 'Pro'
  // }
  // if (plan === 'Scale up') {
  //   return 'Basic'
  // }
  return plan
}

interface PlanPillProps {
  plan: PlanType
  isLabel?: boolean
  isLiveDemo?: boolean
  isBoosted?: boolean
}

const PlanPill: React.FC<PlanPillProps> = ({
  plan,
  isLabel = false,
  isLiveDemo = false,
  isBoosted = false,
}) => {
  return (
    <>
      {!!plan && (
        <div
          className={classNames(
            'text-xs font-bold uppercase tracking-wide',
            {
              'bg-purple-50 text-pink-500 border-purple-200':
                plan === 'Developer' || plan === 'Starter' || isBoosted,
              'bg-blue-100 text-blue-800 border-blue-200':
                plan === 'Pro' ||
                plan === 'Pro - yearly' ||
                plan === 'Entry' ||
                plan === 'Business',
              'bg-blue-900 text-white border-blue-900':
                plan === 'Enterprise' || plan === 'Enterprise Trial',
              'bg-appsumoyellow-100 text-appsumoyellow-900 border-appsumoyellow-200':
                plan === 'AppSumo Tier 1' ||
                plan === 'AppSumo Tier 2' ||
                plan === 'AppSumo Tier 3',
            },
            {
              'mt-3 py-1 px-3 rounded-l-md shadow-sm border border-r-0':
                isLabel,
              'py-px px-2 border rounded-sm': !isLabel,
            }
          )}
          //style={{ marginBottom: isLabel ? 0 : 8 }}
        >
          {getPlanName(plan, isLiveDemo, isBoosted)}
        </div>
      )}
    </>
  )
}

export default PlanPill
