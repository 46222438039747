import React from 'react'

import useChangePassword from '../../../hooks/api/mutations/useChangePassword'

import Card from '../../../components/Card'
import Form from '../../../components/Form'

const ChangePassword = () => {
  const mutation = useChangePassword()

  const error403 = mutation.error?.status === 403

  return (
    <Card title="Change password">
      <Form
        formFields={[
          {
            fieldName: 'oldPassword',
            label: 'Current password',
            type: 'password',
            validation: {
              required: 'Your current password is required',
            },
          },
          {
            fieldName: 'password',
            label: 'New password',
            type: 'password',
            validation: {
              required: 'Choose a password',
              pattern: {
                value: /^.*(?=.{8,})((?=.*[?!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                message: 'At least 8 characters, lowercase and uppercase letters, a number and a special character',
              },
            },
          },
        ]}
        mutation={mutation}
        data={{ oldPassword: '', password: '' }}
        submitText="Change password"
        errorText={error403 ? `The current password you entered is wrong` : undefined}
      />
    </Card>
  )
}

export default ChangePassword
