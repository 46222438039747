import React from 'react'
import classNames from 'classnames'
import Loader from 'react-loader-spinner'

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  full?: boolean
  className?: string
  disabled?: boolean
  loading?: boolean
  color?: 'pink' | 'accent' | 'green' | 'blue' | 'red' | 'gray' | 'cyan'
  link?: boolean
  form?: string
  onClick?: (event: React.FormEvent) => void
  children: React.ReactNode
  tabIndex?: number
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  full = false,
  className,
  disabled = false,
  loading = false,
  color,
  link = false,
  children,
  form,
  tabIndex,
  onClick = () => {},
  ...rest
}) => {
  return (
    <button
      type={type}
      tabIndex={tabIndex}
      disabled={disabled || loading}
      form={form}
      className={classNames(
        'block focus:shadow-outline  text-white font-medium leading-5 rounded-lg transition-colors duration-150',
        {
          'text-white py-2 px-5': !link,
        },
        { 'text-accent-600 hover:text-accent-700 bg-transparent': link },
        {
          'bg-pink-500': color === 'pink',
        },
        {
          'hover:bg-pink-600': color === 'pink' && !disabled,
        },
        {
          'bg-accent-600': color === 'accent',
        },
        {
          'hover:bg-accent-700': color === 'accent' && !disabled,
        },
        {
          'bg-blue-500': color === 'blue',
        },
        {
          'hover:bg-blue-600': color === 'blue' && !disabled,
        },
        {
          'bg-lime-500': color === 'green',
        },
        {
          'hover:bg-lime-600': color === 'green' && !disabled,
        },
        {
          'bg-red-500': color === 'red',
        },
        {
          'hover:bg-red-600': color === 'red' && !disabled,
        },
        {
          'bg-gray-500': color === 'gray',
        },
        {
          'hover:bg-gray-600': color === 'gray' && !disabled,
        },
        {
          'bg-cyan-500': color === 'cyan',
        },
        {
          'hover:bg-cyan-600': color === 'cyan' && !disabled,
        },
        {
          'opacity-80': disabled || loading,
        },
        { 'w-full py-3': full },
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {loading ? (
        <div className="flex justify-center">
          <div className="mr-2">
            <Loader
              type="Oval"
              color={link ? '#6c63ff' : '#ffffff'}
              height={20}
              width={20}
            />
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
