import React from 'react'

import usePatchUser from '../../../hooks/api/mutations/usePatchUser'

import Card from '../../../components/Card'
import Form from '../../../components/Form'
import type { User } from '../../../hooks/api/queries/useProfile'

interface accountNameProps {
  user: User
}

const AccountName: React.FC<accountNameProps> = ({ user }) => {
  const mutation = usePatchUser()

  return (
    <Card
      title="Account name"
      helpText="This is the name of your account that other users will see when you invite them to be Admin or Editors for one of your Apps."
    >
      <Form
        formFields={[
          {
            fieldName: 'accountName',
            label: 'Account Name',
            validation: { required: 'Account name is required' },
          },
        ]}
        mutation={mutation}
        data={{ accountName: user.accountName }}
      />
    </Card>
  )
}

export default AccountName
