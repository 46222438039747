import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const resendEmail = async () => {
  const { data } = await axios.post(`/account/resend_email`, {})
  return data
}

const useResendEmail = (email: string) => {
  return useMutation(
    () => {
      return resendEmail()
    },
    {
      onSuccess: (data: any) => {
        if (data.message === 'OK') {
          cogoToast.success(`The code has been sent again to ${email}`, {
            position: 'bottom-right',
          })
        } else if (data.message === 'ALREADY_VERIFIED') {
          cogoToast.warn(`Your email has already been verified.`, {
            position: 'bottom-right',
          })
        } else {
          cogoToast.warn(`An error occurred while resending the code`, {
            position: 'bottom-right',
          })
        }
      },
      onError: () => {},
    }
  )
}

export default useResendEmail
