import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useRestoreApp = (appId: string, appEnv: string) => {
  return useMutation(
    async (values: FormData) =>
      await axios
        .post<{ result: string }>(
          `/account/apps/${appId}/environments/${appEnv}/restore`,
          values
        )
        .then((res) => {
          return res.data
        }),
    {
      onSuccess: (data) => {
        cogoToast.success('Restore requested successfully', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log(error, 'error')
      },
    }
  )
}

export default useRestoreApp
