import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

const useChangeEmail = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ email }: { email: string }) =>
      await axios.post<{ message: string }>(`/account/profile/change_email`, { email }).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('profile', data)
        queryClient.invalidateQueries('profile')
      },
      onError: (error: any) => {},
    }
  )
}

export default useChangeEmail
