import React, { useState } from 'react'
import { Check, Loader2 } from 'lucide-react'

import Button from './Button'
import { App } from '../hooks/api/queries/useApp'
import type { PlanParamsUI } from '../pages/LoggedApp/Apps/App/Plan'
import useChangeSubscription from '../hooks/api/mutations/useChangeSubscription'
import { Tooltip } from 'react-tooltip'

const getButtonText = (
  isCurrentPlan: boolean,
  isParamsUIChanged: boolean,
  isChangingPlan: boolean
): React.ReactElement => {
  if (isChangingPlan) {
    return (
      <span className="flex items-center justify-center space-x-1">
        <Loader2
          size={16}
          strokeWidth={2}
          aria-hidden="true"
          className="block animate-spin"
        />
        <span className="block">Updating your plan...</span>
      </span>
    )
  }
  if (!isCurrentPlan) {
    return <span>Switch to this plan</span>
  }
  if (isParamsUIChanged) {
    return <span>Change plan</span>
  }
  return (
    <span className="flex items-center justify-center space-x-1">
      <Check size={16} strokeWidth={2} aria-hidden="true" className="block" />
      <span className="block">Your current plan</span>
    </span>
  )
}

interface ChangePlanButtonProps {
  app: App
  planParamsUI: PlanParamsUI
  planId: number
  planName: string
  isCurrentPlan: boolean
  openSwitchPlanModal: (title: string, text: string) => void
}

const ChangePlanButton: React.FC<ChangePlanButtonProps> = ({
  app,
  planParamsUI,
  planId,
  planName,
  isCurrentPlan,
  openSwitchPlanModal,
}) => {
  const [isChangingPlan, setIsChangingPlan] = useState<boolean>(false)

  const changeSubscription = useChangeSubscription(app.appId)

  const isParamsUIChanged =
    planParamsUI.numUsers !== app.subscription.maxUsers ||
    planParamsUI.numPages !== app.subscription.maxPages ||
    planParamsUI.numDiskSpaceMegabytes !== app.subscription.maxDiskSpace

  const hasEnoughUsers = planParamsUI.numUsers >= app.subscription.numUsers
  const hasEnoughPages = planParamsUI.numPages >= app.subscription.numPages
  const hasEnoughDiskSpace =
    planParamsUI.numDiskSpaceMegabytes >= app.subscription.diskSpace

  const isPlanCompatible =
    hasEnoughUsers && hasEnoughPages && hasEnoughDiskSpace

  const getTooltipText = () => {
    if (isCurrentPlan) {
      return ''
    }
    if (!hasEnoughUsers) {
      return `You need at least ${app.subscription.numUsers} users`
    }
    if (!hasEnoughPages) {
      return `You need at least ${app.subscription.numPages} pages`
    }
    if (!hasEnoughDiskSpace) {
      return `You need at least ${app.subscription.diskSpace} MB of disk space`
    }
  }

  return (
    <>
      <Button
        color="blue"
        className="w-full"
        disabled={
          (isCurrentPlan && !isParamsUIChanged) ||
          (!isCurrentPlan && !isPlanCompatible) ||
          isChangingPlan
        }
        data-tooltip-id="notEnough"
        data-tooltip-content={getTooltipText()}
        onClick={() => {
          setIsChangingPlan(true)

          changeSubscription
            .mutateAsync({
              planId,
              users: planParamsUI.numUsers,
              pages: planParamsUI.numPages,
              diskSpace: planParamsUI.numDiskSpaceMegabytes,
            })
            .then(() => {
              setTimeout(() => {
                setIsChangingPlan(false)
                openSwitchPlanModal(
                  'Plan changed correctly.',
                  `Enjoy your new ${planName} plan.`
                )
              }, 3500)
            })
        }}
      >
        {getButtonText(isCurrentPlan, isParamsUIChanged, isChangingPlan)}
      </Button>
      <Tooltip id="notEnough" place="top" />
    </>
  )
}

export default ChangePlanButton
