import React from 'react'
import { App, BillingCycleStat } from '../../hooks/api/queries/useApp'
import { ProgressBar } from './ProgressBar'
import { AreaChart } from './AreaChart'
import classNames from 'classnames'

interface BillingCycleStatsProps {
  stat?: BillingCycleStat
  label: string
  unit: string
  valueTransformer?: (value: number) => React.ReactNode
}

const BillingCycleStats: React.FC<BillingCycleStatsProps> = ({
  stat,
  label,
  unit,
  valueTransformer,
}) => {
  if (!stat) {
    return null
  }

  const percentage =
    stat.usage && stat.subscriptionMax
      ? (stat.usage * 100) / stat.subscriptionMax
      : 0

  return (
    <div className="relative w-full border p-6 text-left shadow-sm bg-white dark:bg-[#090E1A] border-gray-200 dark:border-gray-900 hidden flex-col justify-between rounded-md sm:flex">
      <div>
        <p className="text-sm text-gray-500 dark:text-gray-500">{label}</p>
        <p className="text-lg font-semibold text-gray-900 dark:text-gray-50">
          {valueTransformer ? valueTransformer(stat.usage) : stat.usage}
          {unit}
        </p>
      </div>
      <div>
        <p className="mt-2 flex items-center justify-between text-sm text-gray-500 dark:text-gray-500">
          <span>{percentage}%</span>
          <span>
            {valueTransformer ? valueTransformer(stat.usage) : stat.usage} of{' '}
            {valueTransformer
              ? valueTransformer(stat.subscriptionMax)
              : stat.subscriptionMax}
            {unit}
          </span>
        </p>
        <div className="flex w-full items-center mt-2">
          <ProgressBar
            value={percentage}
            variant={
              percentage > 100
                ? 'overage'
                : percentage > 85
                ? 'warning'
                : 'default'
            }
          />
        </div>

        <div
          className={classNames(
            'mt-3 text-sm',
            stat.overagePrice ? 'text-pink-600' : 'text-gray-500'
          )}
        >
          Charge:{' '}
          <span className={stat.overagePrice ? 'font-bold' : 'font-normal'}>
            ${stat.overagePrice}
          </span>
        </div>

        <div className="flex w-full items-center mt-10">
          <AreaChart
            data={stat.dailyUsage || []}
            className="h-36"
            index="day"
            categories={['usage']}
            startEndOnly
            showYAxis={false}
            showLegend={false}
          />
        </div>
      </div>
    </div>
  )
}

export default BillingCycleStats
