import axios from 'axios'
import { useQuery } from 'react-query'

interface ChangeHistoryInfoProps {
  users: string[]
  pages: string[]
  retentionDays: number
}

const useChangeHistoryInfo = (appId: string, appEnv: string) => {
  return useQuery(['changeHistoryInfo'], async () => {
    const { data: res } = await axios.get<ChangeHistoryInfoProps>(
      `/account/apps/${appId}/environments/${appEnv}/change_history_info`
    )
    return res
  })
}

export default useChangeHistoryInfo
