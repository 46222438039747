import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'
import type { User } from '../queries/useProfile'

const useTransferOwnership = (appId: string, appEnv: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (userId: string) => {
      return await axios
        .post<User>(
          `/account/apps/${appId}/environments/${appEnv}/transfer_ownership`,
          { userId }
        )
        .then((res) => res.data)
    },
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['appUsers', appId, appEnv])
        queryClient.invalidateQueries(['app', appId, appEnv])
        cogoToast.success('Ownership transferred successfully', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useTransferOwnership
