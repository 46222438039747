import React from 'react'

import useChangeEmail from '../../../hooks/api/mutations/useChangeEmail'
import usePatchUser from '../../../hooks/api/mutations/usePatchUser'
import useResendEmail from '../../../hooks/api/mutations/useResendEmail'

import Card from '../../../components/Card'
import Form from '../../../components/Form'
import Button from '../../../components/Button'

interface userProps {
  email: string
  newEmail: string
}

interface changeEmailProps {
  user: userProps
}

const ChangeEmail: React.FC<changeEmailProps> = ({ user }) => {
  const mutation = useChangeEmail()

  const error409 = mutation.error?.status === 409

  const patchUser = usePatchUser()
  const handleResetNewEmail = () => patchUser.mutate({ newEmail: '' })

  const resendEmail = useResendEmail(user.newEmail)
  const handleResendEmail = () => resendEmail.mutate()

  return (
    <Card title="Change e-mail" helpText="We'll send you an e-mail to verify the change.">
      <Form
        formFields={[
          {
            fieldName: 'email',
            label: 'Email',
            type: 'email',
            validation: {
              required: 'Email is required',
              validate: (value: string) => value !== user.email || 'This is your current email address 🤔',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Invalid email address',
              },
            },
          },
        ]}
        mutation={mutation}
        data={{ email: user.email }}
        submitText="Request change"
        errorText={error409 ? 'Email already taken by another user' : undefined}
      />

      {user.newEmail && (
        <div className="px-6 py-4 bg-green-100 border-l-4 border-green-400 mt-10 text-sm">
          <p>
            <b>{user.newEmail}</b> pending verification.
          </p>
          <p className="text-green-700 font-semibold">You will receive am email to confirm your new email address.</p>
          <div className="buttons flex items-center mt-3">
            <Button link loading={resendEmail.isLoading} onClick={handleResendEmail}>
              Resend link
            </Button>
            <span className="px-4 text-green-300">|</span>
            <Button link loading={patchUser.isLoading} onClick={handleResetNewEmail}>
              Cancel request
            </Button>
          </div>
        </div>
      )}
    </Card>
  )
}

export default ChangeEmail
