import axios from 'axios'
import { useQuery } from 'react-query'
import { Language } from './useApp'

const useLanguages = () => {
  return useQuery(
    ['languages'],
    async () => {
      const { data } = await axios.get<Language[]>(`/languages?pageSize=200`)
      return data
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minuti
    }
  )
}

export default useLanguages
