import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'

const useChangeHistoryRestore = (appId: string, appEnv: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (changeId: number) =>
      await axios
        .post<{ result: string }>(
          `account/apps/${appId}/environments/${appEnv}/change_history/${changeId}/restore`,
          {}
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('changeHistory')
        cogoToast.success('Page restored to previous state', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {},
    }
  )
}

export default useChangeHistoryRestore
