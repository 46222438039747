import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import type { User } from '../queries/useProfile'

const usePatchUser = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: any) => {
      const data = await axios.patch<{ user: User }>(`/account/profile`, values)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('profile', data)
        queryClient.invalidateQueries('profile')
        queryClient.invalidateQueries('apps')
        queryClient.invalidateQueries(['appUsers'])
        cogoToast.success('Settings saved successfully', { position: 'bottom-right' })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchUser
