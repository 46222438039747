import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useDeleteAccount = () => {
  return useMutation(async () => await axios.delete('/account/profile').then((res) => res.data), {
    onSuccess: () => {
      cogoToast.success('You successfully deleted your account', { position: 'bottom-right' })
    },
    onError: (error: any) => {
      console.log('ERROR: ', error?.message)
    },
  })
}

export default useDeleteAccount
