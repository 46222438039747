import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { Link, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { format, parseISO } from 'date-fns'
import * as gtag from '../../../../utils/gtag'
import { modalCustomStyles } from '../../../../styles/modalStyles'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
// import { PlanType } from '../../../../components/PlanPill'

import type { App } from '../../../../hooks/api/queries/useApp'
// import type { Plan } from '../../../../hooks/api/queries/usePlans'
import usePlans from '../../../../hooks/api/queries/usePlans'
import useProfile from '../../../../hooks/api/queries/useProfile'
import NumberInput from '../../../../components/NumberInput'
import { FaCheckCircle } from 'react-icons/fa'
import { FiCheck } from 'react-icons/fi'
import ChangePlanButton from '../../../../components/ChangePlanButton'

const PLAN_ID_FREE = '899682' //72605

declare global {
  interface Window {
    Paddle: any
  }
}

interface PlansProps {
  app: App
}

export type PlanParamsUI = {
  numUsers: number
  numPages: number
  numDiskSpaceMegabytes: number
}

type PlansParamsUI = Record<string, PlanParamsUI>

const getPlanName = (plan: string): string => {
  switch (plan) {
    case 'Developer':
      return 'Developer'
    case 'Pro':
      return 'Pro'
    case 'Basic':
      return 'Basic'
    case 'Scale Up':
      return 'Scale Up'
    case 'Starter':
      return 'Starter'
    default:
      return plan
  }
}

const defaultPlanParamsUI: PlansParamsUI = {
  Developer: {
    numUsers: 1,
    numPages: 20,
    numDiskSpaceMegabytes: 200,
  },
  Pro: {
    numUsers: 1,
    numPages: 20,
    numDiskSpaceMegabytes: 200,
  },
}

const convertArrayToObject = (array: any[], key: string) => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, initialValue)
}

const isCurrentPlan = (plan: string, appPlan: string): boolean =>
  getPlanName(appPlan).toLowerCase() === plan.toLowerCase()

const PlanPage: React.FC<PlansProps> = ({ app }) => {
  const { appId } = useParams()
  const queryClient = useQueryClient()

  // Queries
  const plansQuery = usePlans()
  const { data: user } = useProfile()

  // Local State
  const [plansParamsUI, setPlansParamsUI] = useState(defaultPlanParamsUI)
  const [waitingForPaddleUpdate, setWaitingForPaddleUpdate] = useState(false)

  // Modal state
  const [switchPlanModalOpen, setSwitchPlanModalOpen] = useState<boolean>(false)
  const [switchPlanModalTitle, setSwitchPlanTitle] = useState<string>('')
  const [switchPlanModalText, setSwitchPlanText] = useState<string>('')

  useEffect(() => {
    if (plansQuery.data && app) {
      const plans = convertArrayToObject(
        plansQuery.data.map((p) => ({
          ...p,
          numUsers: isCurrentPlan(p.name, app.plan)
            ? app.subscription.maxUsers
            : p.includedUsers,
          numPages: isCurrentPlan(p.name, app.plan)
            ? app.subscription.maxPages
            : p.includedPages,
          numDiskSpaceMegabytes: isCurrentPlan(p.name, app.plan)
            ? app.subscription.maxDiskSpace
            : p.includedDiskSpace,
        })),
        'name'
      )

      setPlansParamsUI(plans)
    }
  }, [plansQuery.data, app])

  const openSwitchPlanModal = (title: string, text: string) => {
    setSwitchPlanTitle(title)
    setSwitchPlanText(text)
    setSwitchPlanModalOpen(true)
  }

  const setPlanParamsUI =
    (planName: string) => (fieldName: string) => (value: number) => {
      setPlansParamsUI({
        ...plansParamsUI,
        [planName]: {
          ...plansParamsUI[planName],
          [fieldName]: value,
        },
      })
    }

  const handleAddCreditCard = () => {
    setWaitingForPaddleUpdate(true)
    //window.Paddle.Environment.set('sandbox')
    window.Paddle.Checkout.open({
      product: PLAN_ID_FREE,
      email: user?.email,
      allowQuantity: false,
      quantity: 1,
      passthrough: JSON.stringify({ appId }),
      customData: { appId },
      successCallback: () => {
        openSwitchPlanModal('Credit card set', 'Updating subscription...')
        setTimeout(() => {
          // queryClient.setQueryData(['app', appId], (app: App | undefined) => {
          //   if (app) {
          //     return {
          //       ...app,
          //       subscription: {
          //         ...app.subscription,
          //         hasSubscription: true,
          //       },
          //     }
          //   } else {
          //     throw new Error('App not found')
          //   }
          // })
          setSwitchPlanModalOpen(false)
          setWaitingForPaddleUpdate(false)
          queryClient.invalidateQueries(['app', appId])
        }, 4500)

        // Analytics Event
        gtag.trackEvent({
          category: 'purchase',
          action: 'add-credit-card',
          label: 'Add credit card',
        })
      },
      closeCallback: () => {
        setSwitchPlanModalOpen(false)
        setWaitingForPaddleUpdate(false)
      },
    })
  }

  const getMin = (used: number, planIncluded: number, step: number) => {
    if (used <= planIncluded) {
      return planIncluded
    }
    return planIncluded + Math.ceil((used - planIncluded) / step) * step
  }

  const renderCurrentPlan = () => {
    return (
      <div className="mb-8">
        <p className="mb-2">
          You are on the <b>{getPlanName(app.plan)}</b> plan.
        </p>
        <p className="mb-2">
          Next renewal on{' '}
          <b>{format(parseISO(app.subscription.periodTo), 'MMMM dd, yyyy')}</b>.
        </p>
      </div>
    )
  }

  const renderPlans = () => {
    if (app.appEnv !== 'main') {
      return (
        <p>
          You cannot edit your plan from this page. Visit the{' '}
          <Link
            to={`/apps/${appId}/environments/main/plan`}
            className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
          >
            main environment
          </Link>{' '}
          page to change it.
        </p>
      )
    }

    if (
      app.plan === 'Enterprise' ||
      app.plan === 'Starter' ||
      app.plan.startsWith('AppSumo') ||
      app.plan.startsWith('Custom')
    ) {
      return (
        <div>
          <a
            href="mailto:support@reactbricks.com"
            className="text-accent-600 hover:text-accent-700 hover:underline font-semibold"
          >
            Contact us
          </a>{' '}
          for changes to your plan
        </div>
      )
    }

    if (app.plan === 'Developer' && !app.subscription.hasSubscription) {
      return (
        <div>
          <h2 className="text-xl font-semibold mb-2">Upgrade Plan</h2>
          <p className="mb-5">
            In order to increase the limits of your plan or upgrade to a
            different plan, you need to enter your credit card details: you
            won't be charged now.
          </p>
          <Button
            type="button"
            color="blue"
            loading={waitingForPaddleUpdate}
            onClick={() => handleAddCreditCard()}
          >
            Add credit card
          </Button>
        </div>
      )
    }

    return (
      <>
        <div className="grid lg:grid-cols-2 gap-6">
          {plansQuery.data?.map((plan) => {
            const currentPlan = isCurrentPlan(plan.name, app.plan)

            const minUsers = currentPlan
              ? getMin(
                  app.subscription.numUsers,
                  plan.includedUsers,
                  plan.additionalQtyUsers
                )
              : plan.includedUsers
            const minPages = currentPlan
              ? getMin(
                  app.subscription.numPages,
                  plan.includedPages,
                  plan.additionalQtyPages
                )
              : plan.includedPages
            const minDiskSpace = currentPlan
              ? getMin(
                  app.subscription.diskSpace,
                  plan.includedDiskSpace,
                  plan.additionalQtyDiskSpace
                )
              : plan.includedDiskSpace

            const uiAdditionalPriceUsers = plan.additionalQtyUsers
              ? ((plansParamsUI[plan.name].numUsers - plan.includedUsers) /
                  plan.additionalQtyUsers) *
                plan.additionalPriceUsers
              : 0
            const uiAdditionalPricePages = plan.additionalQtyPages
              ? ((plansParamsUI[plan.name].numPages - plan.includedPages) /
                  plan.additionalQtyPages) *
                plan.additionalPricePages
              : 0
            const uiAdditionalPriceDiskSpace = plan.additionalQtyDiskSpace
              ? ((plansParamsUI[plan.name].numDiskSpaceMegabytes -
                  plan.includedDiskSpace) /
                  plan.additionalQtyDiskSpace) *
                plan.additionalPriceDiskSpace
              : 0

            const uiPrice =
              plan.price +
              uiAdditionalPriceUsers +
              uiAdditionalPricePages +
              uiAdditionalPriceDiskSpace

            return (
              <div
                key={plan.id}
                className="relative flex flex-col w-full p-8 bg-white border rounded-xl shadow-md"
              >
                {currentPlan && (
                  <div className="absolute top-0 right-0 py-1.5 px-4 text-xs font-semibold tracking-wide uppercase text-white bg-pink-500 rounded-bl-xl rounded-tr-xl">
                    Current plan
                  </div>
                )}
                <h1 className="text-xl text-slate-700 font-semibold">
                  {plan.name}
                </h1>
                <div className="mt-2 text-xs h-10 lg:h-6">
                  {plan.description}
                </div>
                <hr className="mt-2 mb-6 opacity-75" />
                <div className="flex items-center justify-center gap-2 mb-6">
                  <div className="text-5xl text-slate-700 font-semibold">
                    $ {uiPrice}
                  </div>
                  <div className="text-slate-500">/ month</div>
                </div>
                <div className="flex flex-col px-3 gap-y-4 mb-4">
                  <NumberInput
                    label="Users"
                    minValue={minUsers}
                    maxValue={plan.maxUsers}
                    step={plan.additionalQtyUsers}
                    inUse={app.subscription.numUsers}
                    subscriptionMax={app.subscription.maxUsers}
                    value={plansParamsUI[plan.name].numUsers}
                    onChange={setPlanParamsUI(plan.name)('numUsers')}
                    isCurrentPlan={currentPlan}
                    tooltipMin={
                      plansParamsUI[plan.name].numUsers === minUsers &&
                      minUsers > plan.includedUsers
                        ? "Can't be less than your current usage"
                        : ''
                    }
                  />
                  <NumberInput
                    label="Pages"
                    minValue={minPages}
                    maxValue={plan.maxPages}
                    step={plan.additionalQtyPages}
                    inUse={app.subscription.numPages}
                    subscriptionMax={app.subscription.maxPages}
                    value={plansParamsUI[plan.name].numPages}
                    onChange={setPlanParamsUI(plan.name)('numPages')}
                    isCurrentPlan={currentPlan}
                    tooltipMin={
                      plansParamsUI[plan.name].numPages === minPages &&
                      minPages > plan.includedPages
                        ? "Can't be less than your current usage"
                        : ''
                    }
                  />
                  {minDiskSpace === plan.maxDiskSpace ? (
                    <div>
                      <div className="text-sm font-medium mb-1">Disk space</div>
                      <div className="text-sm h-9 flex items-center justify-center rounded-lg border shadow-sm shadow-black/[.04]">
                        {minDiskSpace} MB (no overage)
                      </div>
                    </div>
                  ) : (
                    <NumberInput
                      label="Disk space (MB)"
                      minValue={minDiskSpace}
                      maxValue={plan.maxDiskSpace || Infinity}
                      step={plan.additionalQtyDiskSpace}
                      inUse={app.subscription.diskSpace}
                      subscriptionMax={app.subscription.maxDiskSpace}
                      value={plansParamsUI[plan.name].numDiskSpaceMegabytes}
                      onChange={setPlanParamsUI(plan.name)(
                        'numDiskSpaceMegabytes'
                      )}
                      isCurrentPlan={currentPlan}
                      tooltipMin={
                        plansParamsUI[plan.name].numDiskSpaceMegabytes ===
                          minDiskSpace && minDiskSpace > plan.includedDiskSpace
                          ? "Can't be less than your current usage"
                          : ''
                      }
                    />
                  )}
                </div>

                <div className="mt-4">
                  <div className="px-3 mb-4">
                    <div className="text-xs font-semibold mb-1">
                      Monthly Traffic
                    </div>
                    <div className="text-xs">
                      {plan.includedBandwidth / 1000} GB{' '}
                      {plan.additionalQtyBandwidth
                        ? `($ ${plan.additionalPriceBandwidth} for ${
                            plan.additionalQtyBandwidth / 1000
                          } GB)`
                        : `(no overage)`}
                    </div>
                  </div>
                  <div className="px-3 mb-10">
                    <div className="text-xs font-semibold mt-2 mb-1">
                      Monthly API Calls
                    </div>
                    <div className="text-xs">
                      {plan.includedApiCalls / 1000}K{' '}
                      {plan.additionalQtyApiCalls
                        ? `($ ${plan.additionalPriceApiCalls} for ${
                            plan.additionalQtyApiCalls / 1000
                          }K)`
                        : `(no overage)`}
                    </div>
                  </div>

                  <ChangePlanButton
                    app={app}
                    planParamsUI={plansParamsUI[plan.name]}
                    planId={plan.id}
                    planName={plan.name}
                    isCurrentPlan={currentPlan}
                    openSwitchPlanModal={openSwitchPlanModal}
                  />
                </div>
              </div>
            )
          })}
          <div className="flex flex-col p-8 bg-white shadow-md border rounded-xl lg:col-span-2">
            <h1 className="text-xl text-slate-700 font-semibold">Enterprise</h1>
            <hr className="mt-2 mb-6 opacity-75" />
            <div className="flex items-center text-4xl text-slate-700 font-semibold mb-6">
              Custom price
            </div>
            <div className="flex-1 flex flex-col space-between">
              <div className="grid lg:grid-cols-3 lg:gap-6">
                <ul className="flex-1 pl-7 leading-tight text-sm">
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Enterprise support
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Custom limits
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Tech on-boarding
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Professional services
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Advanced SEO
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Custom CDN Domain
                  </li>
                </ul>
                <ul className="flex-1 pl-7 leading-tight text-sm">
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Single Sign-on (SSO)
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Approval Wokflows
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Content Environments
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Scheduled backup on S3
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Custom roles
                  </li>
                  <li className="relative mb-3">
                    <div className="absolute top-[1.5px] -left-7">
                      <div className="rounded-full bg-blue-100/60 w-4 h-4 p-1 flex justify-center items-center">
                        <FiCheck className="text-blue-800/60" />
                      </div>
                    </div>
                    Audit logs
                  </li>
                </ul>
                <div>
                  <a
                    href="https://reactbricks.com/contact-sales"
                    target="_blank"
                    rel="noreferrer"
                    className="py-2 px-5 text-center mt-10 lg:mt-0 lg:w-full block focus:shadow-outline focus:outline-none text-white font-medium leading-5 rounded-lg transition-colors duration-150 bg-blue-500 hover:bg-blue-600"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={!!switchPlanModalOpen}
          shouldCloseOnOverlayClick={!waitingForPaddleUpdate}
          shouldCloseOnEsc={!waitingForPaddleUpdate}
          onRequestClose={() => setSwitchPlanModalOpen(false)}
          style={modalCustomStyles}
        >
          <div className="px-12 py-8 max-w-lg">
            <div className="flex justify-center text-6xl text-green-500 mb-3">
              <FaCheckCircle />
            </div>

            <h2 className="text-xl font-semibold text-center mb-6">
              {switchPlanModalTitle}
            </h2>

            <div className="mb-6 text-lg text-gray-600">
              {switchPlanModalText}
            </div>
            {!waitingForPaddleUpdate && (
              <Button
                type="button"
                color="blue"
                full
                onClick={() => setSwitchPlanModalOpen(false)}
              >
                Back to your App
              </Button>
            )}
          </div>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Card title="Your plan">
        {renderCurrentPlan()}
        {renderPlans()}
      </Card>
    </>
  )
}

export default PlanPage
