import axios from 'axios'
import { useQuery } from 'react-query'

interface ChangeHistoryChangesProps {
  dataBefore: string
  dataAfter: string
}

const useChangeHistoryChanges = (
  appId: string,
  appEnv: string,
  changeId?: number
) => {
  return useQuery(
    ['changeHistoryChanges', changeId],
    async () => {
      const { data: res } = await axios.get<ChangeHistoryChangesProps>(
        `/account/apps/${appId}/environments/${appEnv}/change_history/${changeId}`
      )
      return res
    },
    {
      enabled: !!appId && !!appEnv && !!changeId,
    }
  )
}

export default useChangeHistoryChanges
