import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import useTokenChangePassword from '../../hooks/api/queries/useTokenChangePassword'
import useResetPassword from '../../hooks/api/mutations/useResetPassword'

import FormField from '../../components/FormField'
import Button from '../../components/Button'

import imgLogo from '../../images/reactbricks-logo-v.svg'
import imgWarning from '../../images/warning.svg'
import imgRocket from '../../images/rocket.svg'

const ResetPassword: React.FC = () => {
  const [tokenValid, setTokenValid] = useState(false)
  const [passwordResetOk, setPasswordResetOk] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ password: string }>()

  const navigate = useNavigate()
  const { token } = useParams()
  const { data } = useTokenChangePassword(token)
  const resetPassword = useResetPassword(token || '')

  useEffect(() => {
    if (data && data.message === 'OK') {
      setTokenValid(true)
    }
  }, [data])

  const handleChangePassword = (values: { password: string }) => {
    resetPassword.mutateAsync(values.password).then((res) => {
      if (res && res.authToken) {
        localStorage.setItem('token', res.authToken)
        localStorage.setItem('email', res.user.email)
        localStorage.setItem('firstName', res.user.firstName)
        localStorage.setItem('lastName', res.user.lastName)

        navigate('/apps')
      }
    })

    if (resetPassword.isSuccess) {
      setPasswordResetOk(true)
    } else {
      setPasswordResetOk(false)
    }
  }

  return (
    <div className="min-h-screen">
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-12 flex justify-center">
        <img src={imgLogo} alt="logo" className="w-32" />
      </header>

      <div className="shadow-lg py-8 px-10 max-w-sm rounded mx-auto bg-white">
        {!passwordResetOk ? (
          !tokenValid ? (
            <div className="flex flex-col items-center">
              <h2 className="text-center text-gray-900 text-lg font-bold mb-6">
                Invalid link
              </h2>
              <p className="mb-6 text-center">
                This link you clicked on is not valid or it is expired.
              </p>
              <Link
                to="/forgot-password"
                className="mb-6 bg-pink-500 hover:bg-pink-600 focus:shadow-outline focus:outline-none text-white text-sm font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150"
              >
                Request new password reset
              </Link>
              <img src={imgWarning} className="w-32" alt="Invalid link" />
            </div>
          ) : (
            <>
              <h2 className="text-center text-gray-900 text-lg font-bold mb-6">
                Password reset
              </h2>

              <p className="text-sm text-gray-600 mb-4">
                Choose a new password to access your account
              </p>
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <div className="mb-4">
                  <FormField
                    fieldName="password"
                    type="password"
                    label="Enter a new password"
                    labelClassName="block text-sm leading-5 font-medium text-gray-700 mb-1"
                    validation={{
                      required: 'Choose a password',
                      pattern: {
                        value:
                          /^.*(?=.{8,})((?=.*[?!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        message:
                          'At least 8 characters, lowercase and uppercase letters, a number and a special character',
                      },
                    }}
                    error={errors.password}
                    register={register}
                  />
                </div>

                <Button
                  type="submit"
                  className="block w-full bg-pink-500 hover:bg-pink-600 focus:shadow-outline focus:outline-none text-white text-sm font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150"
                  loading={resetPassword.isLoading}
                >
                  Set new password
                </Button>
              </form>
            </>
          )
        ) : (
          <div className="flex flex-col items-center">
            <h2 className="text-center text-green-600 text-lg font-bold mb-6">
              Password was changed
            </h2>

            <p className="text-gray-600 mb-6">
              You are already logged in your account
            </p>

            <Link
              to="/"
              className="mb-6 bg-pink-500 hover:bg-pink-600 focus:shadow-outline focus:outline-none text-white text-sm font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150"
            >
              Go to dashboard
            </Link>

            <img src={imgRocket} className="w-32" alt="Success" />
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetPassword
