import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface CreateCustomRole {
  id: string
  name: string
}

const useCreateAppCustomRole = (appId: string, appEnv: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (customRole: CreateCustomRole) => {
      const { id, name } = customRole
      return await axios
        .post(`/account/apps/${appId}/environments/${appEnv}/custom_roles`, {
          id,
          name,
        })
        .then((res) => res.data)
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appCustomRoles', appId, appEnv])
        //queryClient.invalidateQueries(['app', appId])
        //cogoToast.success('User invited successfully', { position: 'bottom-right' })
      },
      onError: (error: Error & { status: number }, values) => {
        console.log('ERROR: ', error?.status)
        if (error?.status === 409) {
          cogoToast.warn(`Role ${values.id} is already registered`, {
            position: 'bottom-right',
          })
        }
      },
    }
  )
}

export default useCreateAppCustomRole
