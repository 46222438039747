import React, { useState, useRef } from 'react'
import Modal from 'react-modal'
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'

import useChangeAvatar from '../../../hooks/api/mutations/useChangeAvatar'

import CardAvatar from '../../../components/CardAvatar'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'

import blankAvatar from '../../../images/avatar.svg'

import { modalCustomStyles } from '../../../styles/modalStyles'
import type { User } from '../../../hooks/api/queries/useProfile'

Modal.setAppElement('#root')

interface avatarProps {
  user: User
}

const Avatar: React.FC<avatarProps> = ({ user }) => {
  //=================================
  // LOCAL STATE
  const [modalOpen, setModalOpen] = useState(false)
  const [avatar, setAvatar] = useState(blankAvatar)
  const [scale, setScale] = useState(1)
  const [dropErrorMessage, setDropErrorMessage] = useState('')

  //==================================
  // MUTATION
  const changeAvatar = useChangeAvatar()

  //==================================
  // REF
  const avatarEditorRef = useRef<any>(null)

  //==================================
  // EVENT HANDLERS
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const handleDropAccepted = (files: any) => {
    setDropErrorMessage('')
    setAvatar(files[0])
    openModal()
  }

  const handleDropRejected = (files: any) => {
    const fileName = files[0].file.name

    const fileSize =
      files[0].file.size <= 1048576
        ? `${(files[0].file.size / 1024).toFixed(0)} KB`
        : `${(files[0].file.size / 1048576).toFixed(1)} MB`

    setDropErrorMessage(`"${fileName}" exceeds maximum file size: ${fileSize}`)
  }

  const handleScale = (e: any) => {
    setScale(parseFloat(e.target.value))
  }

  const saveAvatar = () => {
    if (avatarEditorRef.current) {
      const canvas = avatarEditorRef.current
      if (canvas) {
        canvas.getImage().toBlob((blob: Blob) => {
          let formData = new FormData()
          formData.append('file', blob, canvas.props.image.name)
          changeAvatar.mutate(formData)
        })
      }
    }
    closeModal()
  }

  return (
    <CardAvatar
      title="Your Avatar"
      helpText={
        <span>
          This is your avatar: it is not required but highly recommended.
          <br />
          Click on the avatar to upload a new photo.
          {!!dropErrorMessage && (
            <span className="block mt-6 text-red-600">{dropErrorMessage}</span>
          )}
        </span>
      }
    >
      <div className="cursor-pointer relative">
        {changeAvatar.isLoading && (
          <div className="absolute w-full h-full flex items-center justify-center bg-black opacity-50 rounded-full">
            <Loading />
          </div>
        )}
        <Dropzone
          accept={'image/jpeg, image/png'}
          multiple={false}
          maxSize={1048576}
          onDropAccepted={handleDropAccepted}
          onDropRejected={handleDropRejected}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <img
                className="w-32 rounded-full border-2 mt-1 hover:border-pink-500 transition-border duration-150"
                alt="avatar"
                src={user.avatarUrl || blankAvatar}
              />
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      </div>

      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalCustomStyles}
      >
        <div className="px-12 py-8">
          <AvatarEditor
            ref={avatarEditorRef}
            image={avatar}
            width={250}
            height={250}
            borderRadius={1000}
            scale={scale}
          />
          <div className="my-4">
            <input
              className="w-full"
              name="scale"
              type="range"
              value={scale}
              onChange={handleScale}
              min="1"
              max="2"
              step="0.01"
            />
          </div>
          <div className="flex justify-between">
            <Button color="gray" onClick={closeModal}>
              Cancel
            </Button>
            <Button color="blue" onClick={saveAvatar}>
              Set new avatar
            </Button>
          </div>
        </div>
      </Modal>
    </CardAvatar>
  )
}

export default Avatar
