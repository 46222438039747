import React from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import FormField from '../../../components/FormField'
import useDeleteAccount from '../../../hooks/api/mutations/useDeleteAccount'

const DeleteAccount = () => {
  const queryClient = useQueryClient()
  const deleteAccount = useDeleteAccount()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()
  const navigate = useNavigate()

  const handleDelete = async () => {
    await deleteAccount.mutate()
    localStorage.removeItem('token')
    localStorage.removeItem('email')
    localStorage.removeItem('username')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    queryClient.clear()
    navigate('/')
  }

  return (
    <Card
      title="Delete your React Bricks account"
      helpText="You will lose all your user data and your applications (configuration and content)."
      danger
    >
      <form onSubmit={handleSubmit(handleDelete)}>
        <div className="mb-5">
          <FormField
            fieldName="confirmationCheckbox"
            type="checkbox"
            register={register}
            label={
              <span className="ml-2">
                I confirm that I want to completely remove all my data
              </span>
            }
            validation={{
              required: 'Please, confirm that you want to delete your data',
            }}
            error={errors.confirmationCheckbox}
          />
        </div>

        <Button type="submit" color="red" loading={deleteAccount.isLoading}>
          Delete my account
        </Button>
      </form>
    </Card>
  )
}

export default DeleteAccount
