import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useChangePassword = () => {
  return useMutation(
    async (values: any) =>
      await axios
        .post<{ message: string; authToken: string }>(
          `/account/profile/change_password`,
          values
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        //console.log('New token', data.authToken.slice(-5))
        localStorage.setItem('token', data.authToken)
        cogoToast.success('Your password has been changed', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {},
    }
  )
}

export default useChangePassword
