import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { Tooltip } from 'react-tooltip'
import QueryString from 'qs'
import { FiPlusCircle } from 'react-icons/fi'

import useApps from '../../../hooks/api/queries/useApps'
import useCreateApp from '../../../hooks/api/mutations/useCreateApp'
import useProfile from '../../../hooks/api/queries/useProfile'

import Loading from '../../../components/Loading'
import UserApp from '../../../components/UserApp'
import FormField from '../../../components/FormField'
import Button from '../../../components/Button'

import noApps from '../../../images/no_app.svg'

import { modalCustomStyles } from '../../../styles/modalStyles'

const Apps = () => {
  const [createNewApp, setCreateNewApp] = useState<boolean>(false)

  const openCreateAppModal = () => {
    setCreateNewApp(true)
  }
  const closeCreateAppModal = () => {
    setCreateNewApp(false)
  }

  const { data: user } = useProfile()
  const { data: appsData, ...appsQuery } = useApps()
  const createApp = useCreateApp()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()

  const canAddNewApp = (appsData && appsData.length < 1) || user?.isVerified
  const tooltip = canAddNewApp
    ? ''
    : 'To create more than 1 app you need to verify your email'

  const location = useLocation()
  const qsFromAppSumo = (
    QueryString.parse(location.search, { ignoreQueryPrefix: true })
      .fromAppSumo || ''
  ).toString()

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-semibold leading-tight mb-4">
          {qsFromAppSumo
            ? 'Thank you for your purchase on AppSumo 🌮'
            : 'Your Apps'}
          {appsQuery.isFetching ? ' ...' : null}
        </h1>

        {appsQuery.isLoading ? (
          <Loading />
        ) : appsData && appsData.length > 0 ? (
          <div className="md:flex justify-between items-end">
            <div className="max-w-lg">
              <p className="text-gray-600">
                {qsFromAppSumo
                  ? 'Here you find your App with the AppSumo tier you chose. Click on the App to set a custom name and see the details.'
                  : 'Here you can see the apps for which you are the Owner (you created them) or for which you have role of Admin or Editor.'}
              </p>
            </div>
            <Button
              color="green"
              className="flex items-center pl-3 relative"
              onClick={openCreateAppModal}
              disabled={!canAddNewApp}
            >
              {!canAddNewApp && (
                <>
                  <div
                    className="absolute top-0 bottom-0 right-0 left-0"
                    data-tooltip-id="userCan"
                    data-tooltip-content={tooltip}
                  />
                  <Tooltip
                    id="userCan"
                    place="top"
                  />
                </>
              )}
              <FiPlusCircle
                className={`mr-2 ${!canAddNewApp ? '' : 'text-lime-200'}`}
              />
              Add new App
            </Button>
          </div>
        ) : (
          <>
            <div className="flex flex-wrap sm:flex-no-wrap max-w-sm mx-auto sm:max-w-full sm:mx-0 items-center justify-center sm:justify-between py-12">
              <img
                src={noApps}
                alt="noApps"
                className="sm:w-1/3 w-full mb-12 sm:mb-0"
              />
              <div className="sm:w-2/3 pl-20 w-full text-center sm:text-left">
                <div className="text-xl mb-12 text-gray-600">
                  <p className="mb-1">You haven't created any apps yet.</p>
                  <p className="font-bold">But you're just a click away!</p>
                </div>
                <div className="flex justify-center sm:justify-start">
                  <Button
                    color="blue"
                    className="flex items-center py-3 px-6 text-lg"
                    onClick={openCreateAppModal}
                  >
                    <FiPlusCircle className="mr-2 text-blue-200" />
                    Create an app now
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {appsData && appsData.length > 0 && (
        <div
          className="bg-gray-50 border-t border-gray-200"
          style={{ minHeight: 400 }}
        >
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="flex flex-wrap -mx-5">
              {appsData?.map((app: any) => (
                <div
                  key={`${app.appId}_${app.appEnv}`}
                  className="my-3 px-5 w-full md:w-1/2"
                >
                  <Link to={`${app.appId}/environments/${app.appEnv}`}>
                    <UserApp app={app} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={!!createNewApp}
        onRequestClose={closeCreateAppModal}
        style={modalCustomStyles}
      >
        {createNewApp && (
          <div className="px-12 py-8 max-w-xl">
            <h2 className="text-xl font-semibold text-center mb-3">
              Create App
            </h2>

            <div className="mb-5 text-sm text-gray-600">
              Create a new React Bricks app.
            </div>

            <form
              onSubmit={handleSubmit((data) =>
                createApp.mutateAsync(data).then(closeCreateAppModal)
              )}
            >
              <div className="mb-6">
                <FormField
                  fieldName="appName"
                  type="text"
                  label="App name"
                  register={register}
                  validation={{ required: 'A name is required' }}
                  error={errors.appName}
                />
              </div>
              <div className="flex items-center space-x-4">
                <Button
                  color="gray"
                  onClick={closeCreateAppModal}
                  className="flex-1"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="blue"
                  loading={createApp.isLoading}
                  className="flex-1"
                >
                  Create app
                </Button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default Apps
