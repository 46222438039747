import { useQuery } from 'react-query'
import axios from 'axios'
import type { User } from './useProfile'

const useAppUsers = (appId: string | null, appEnv: string | null) => {
  return useQuery(
    ['appUsers', appId, appEnv],
    async () => {
      const { data } = await axios.get<User[]>(
        `/account/apps/${appId}/environments/${appEnv}/users`
      )
      return data
    },
    {
      enabled: !!appId && !!appEnv,
      staleTime: 30 * 1000, // 30 sec
    }
  )
}

export default useAppUsers
