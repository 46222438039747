import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import type { User } from '../queries/useProfile'

interface Credentials {
  token?: string
  code?: string
}

const verifyEmail = async (verifyEmailCredential: Credentials) => {
  const { data } = await axios.post<{ message: string; authToken: string; user: User }>(
    `/account/verify_email`,
    verifyEmailCredential
  )

  return data
}

const useVerifyEmail = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (verifyEmailCredential: Credentials) => {
      return verifyEmail(verifyEmailCredential)
    },
    {
      onSuccess: (data: any) => {
        if (data.message === 'OK') {
          cogoToast.success(`Email verified. You'll be redirected to app`, {
            position: 'bottom-right',
          })
          queryClient.invalidateQueries(['profile'])
        } else if (data.message === 'ALREADY_VERIFIED') {
          cogoToast.warn(`Your email has already been verified.`, {
            position: 'bottom-right',
          })
        } else {
          cogoToast.warn(`The code is invalid or has expired.`, {
            position: 'bottom-right',
          })
        }
      },
      onError: () => {},
    }
  )
}

export default useVerifyEmail
