import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import type { App } from '../queries/useApp'

const useCreateApp = () => {
  const queryClient = useQueryClient()
  return useMutation(async (values: any) => await axios.post<App>(`/account/apps`, values).then((res) => res.data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['apps'])
      cogoToast.success('New app created successfully', { position: 'bottom-right' })
    },
    onError: (error: Error & { status: number }, values) => {
      console.log('ERROR: ', error?.status)
      if (error.status === 409) {
        cogoToast.warn(`An app named ${values.appName} already exists`, {
          position: 'bottom-right',
        })
      }
    },
  })
}

export default useCreateApp
