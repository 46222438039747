import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import cogoToast from "cogo-toast";

const useDeleteAppEnvironment = (appId: string, environmentName: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async () =>
      await axios
        .delete(`/account/apps/${appId}/environments/${environmentName}`)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", appId]);
        cogoToast.success("Environment deleted successfully", {
          position: "bottom-right",
        });
      },
      onError: (error: any) => {
        console.log("ERROR: ", error?.message);
      },
    }
  );
};

export default useDeleteAppEnvironment;
