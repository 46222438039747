import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'

import useForgotPassword from '../../hooks/api/mutations/useForgotPassword'

import FormField from '../../components/FormField'
import Button from '../../components/Button'

import imgLogo from '../../images/reactbricks-logo-v.svg'
import imgEmail from '../../images/email_sent.svg'

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const resetPassword = useForgotPassword()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const handleSendEmail = (values: any) => {
    resetPassword.mutate(values)
    setEmail(values.email)
    setEmailSent(true)
  }

  return (
    <div className="min-h-screen">
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>

      <header className="py-12 flex justify-center">
        <img src={imgLogo} alt="logo" className="w-32" />
      </header>

      <div className="shadow-lg py-8 px-10 max-w-sm rounded mx-auto bg-white">
        {!emailSent ? (
          <>
            <h2 className="text-center text-gray-900 text-lg font-bold mb-6">
              Forgot your password?
            </h2>

            <p className="text-sm text-gray-600 mb-4">
              Don't worry, you will be able to access your account.{' '}
              <span className="text-lg" role="img" aria-labelledby="smile">
                😊
              </span>
            </p>
            <form onSubmit={handleSubmit(handleSendEmail)}>
              <div className="mb-4">
                <FormField
                  fieldName="email"
                  type="email"
                  label="Enter your e-mail"
                  labelClassName="block text-sm leading-5 font-medium text-gray-700 mb-1"
                  validation={{
                    required: 'Enter your email address',
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: 'Invalid email address',
                    },
                  }}
                  error={errors.email}
                  register={register}
                />
              </div>

              <Button
                type="submit"
                className="block w-full bg-pink-500 hover:bg-pink-600 focus:shadow-outline focus:outline-none text-white text-sm font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150"
                loading={resetPassword.isLoading}
              >
                Send me a recovery link
              </Button>
            </form>
          </>
        ) : (
          <>
            <h2 className="text-center text-gray-900 text-lg font-bold mb-6">Check your e-mail</h2>

            <p className="text-gray-600 mb-4">
              We sent you an email at <span className="font-semibold text-gray-700">{email}</span>.
              Click on the link you received to reset your password.
            </p>

            <img src={imgEmail} className="w-32 mx-auto mb-6" alt="Email with reset link sent" />

            <Link to="/" className="block text-sm text-center text-blue-600 hover:text-blue-700">
              Back to login
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
