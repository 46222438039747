import React from "react";
import { FiEdit2, FiXCircle, FiUser } from "react-icons/fi";

import { Role } from "../../hooks/api/queries/useCustomRoles";

interface CustomRolesRowProps {
  role: Role;
  onOpenEditModal: any;
  onOpenDeleteModal: any;
}

const CustomRolesRow: React.FC<CustomRolesRowProps> = ({
  role,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  return (
    <div className="flex items-center justify-between border-t border-gray-200">
      <div className="w-2/3 sm:w-1/3 py-4 whitespace-no-wrap">
        <p className="font-semibold text-gray-900">{role.name}</p>
        <code className="mt-1 inline-block text-xs py-[2px] px-2 bg-gray-200 rounded text-gray-700">
          {role.id}
        </code>
      </div>

      <div className="hidden sm:flex sm:w-1/3 justify-start px-6 py-4 whitespace-no-wrap space-x-1">
        <span className="flex items-center space-x-1 py-1 px-2 leading-none  text-gray-800 rounded text-xs font-semibold uppercase">
          <p className="text-sm text-pink-500">{role.users}</p>
          <FiUser />
        </span>
      </div>

      <div className="w-1/3 flex items-center justify-end">
        <div className="w-8 ml-4 pr-1 py-4 whitespace-no-wrap text-lg">
          <button
            className="p-1 text-accent-600 hover:text-accent-700"
            onClick={onOpenEditModal(role)}
          >
            <FiEdit2 />
          </button>
        </div>
        <div className="w-8 py-4 whitespace-no-wrap text-lg">
          <button
            className="p-1 text-red-600 hover:text-red-700"
            onClick={onOpenDeleteModal(role)}
          >
            <FiXCircle />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomRolesRow;
