import React from 'react'

import Button from '../../components/Button'
import { Role } from '../../hooks/api/queries/useCustomRoles'
import useDeleteAppCustomRole from '../../hooks/api/mutations/useDeleteAppCustomRole'

interface RemoveRoleProps {
  appId: string
  appEnv: string
  role: Role
  onCloseDeleteModal: any
}

const RemoveRole: React.FC<RemoveRoleProps> = ({
  appId,
  appEnv,
  role,
  onCloseDeleteModal,
}) => {
  const deleteRole = useDeleteAppCustomRole(appId, appEnv, role.id)

  const handleDeleteRole = () => {
    deleteRole.mutate()
    onCloseDeleteModal()
  }

  return (
    <div className="px-12 py-8 max-w-sm">
      <div className="flex flex-col items-center mb-4">
        <h2 className="text-lg text-red-600 font-semibold">
          Remove role {role.name}
        </h2>
        <p className="my-4 text-sm text-center text-gray-600">
          The role will be removed from all the users. The role's permissions
          won't be enforced any more.
        </p>
      </div>
      <div className="flex justify-between space-x-4">
        <Button color="gray" className="flex-1" onClick={onCloseDeleteModal}>
          Cancel
        </Button>
        <Button
          color="red"
          className="flex-1"
          loading={deleteRole.isLoading}
          onClick={handleDeleteRole}
        >
          Remove role
        </Button>
      </div>
    </div>
  )
}

export default RemoveRole
