import React from 'react'
import { format, parseISO, differenceInSeconds } from 'date-fns'

import type { BackupLogRow } from '../../hooks/api/queries/useApp'
import classNames from 'classnames'

interface BackupLogRowProps {
  backupLogRow?: BackupLogRow
}

const BackupLogTable: React.FC<BackupLogRowProps> = ({ backupLogRow }) => {
  return (
    <>
      <table className="bg-white border-collapse border border-gray-300 text-xs w-full">
        <thead>
          <tr>
            <th className="border border-gray-300 py-1 px-2 text-center">
              Date started
            </th>
            <th className="border border-gray-300 py-1 px-2 text-center">
              Status
            </th>
            <th className="border border-gray-300 py-1 px-2 text-center">
              # Pages
            </th>
            <th className="border border-gray-300 py-1 px-2 text-center">
              # Images
            </th>
            <th className="border border-gray-300 py-1 px-2 text-center">
              # Files
            </th>
            <th className="border border-gray-300 py-1 px-2 text-center">
              # Total Assets
            </th>
            <th className="border border-gray-300 py-1 px-2 text-center">
              Backup Size
            </th>
            <th className="border border-gray-300 py-1 px-2 text-center">
              Duration
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 py-1 px-2 text-center">
              {backupLogRow?.createdAt
                ? format(parseISO(backupLogRow?.createdAt), 'yyyy-MM-dd HH:mm')
                : ''}
            </td>
            <td
              className={classNames(
                'border border-gray-300 py-1 px-2 text-center font-bold',
                backupLogRow?.status === 'COMPLETED'
                  ? 'text-green-600'
                  : backupLogRow?.status === 'FAILED'
                  ? 'text-red-600'
                  : backupLogRow?.status === 'IN_PROGRESS'
                  ? 'text-yellow-600'
                  : 'text-gray-600'
              )}
            >
              {backupLogRow?.status.replace('_', ' ')}
            </td>
            <td className="border border-gray-300 py-1 px-2 text-center">
              {backupLogRow?.pages}
            </td>
            <td className="border border-gray-300 py-1 px-2 text-center">
              {backupLogRow?.images}
            </td>
            <td className="border border-gray-300 py-1 px-2 text-center">
              {backupLogRow?.files}
            </td>
            <td className="border border-gray-300 py-1 px-2 text-center">
              {backupLogRow?.assetsNumber}
            </td>
            <td className="border border-gray-300 py-1 px-2 text-center">
              {backupLogRow?.assetsSize
                ? `${(
                    (backupLogRow?.jsonSize + backupLogRow?.assetsSize) /
                    (1024 * 1024)
                  ).toFixed(0)} MB`
                : ''}
            </td>
            <td className="border border-gray-300 py-1 px-2 text-center">
              {backupLogRow?.createdAt && backupLogRow?.finishedAt
                ? `${differenceInSeconds(
                    parseISO(backupLogRow?.finishedAt),
                    parseISO(backupLogRow?.createdAt)
                  )} s`
                : ''}
            </td>
          </tr>
        </tbody>
      </table>

      {backupLogRow?.status === 'FAILED' && (
        <div className="mt-4 border border-red-500 bg-red-100 py-2 px-3">
          <h4 className="font-bold mb-2">Backup failed with error:</h4>
          <div className="text-xs">{backupLogRow?.error}</div>
        </div>
      )}
    </>
  )
}

export default BackupLogTable
