import React from 'react'

import Card from '../../../../components/Card'
import FormField from '../../../../components/FormField'
import type { App } from '../../../../hooks/api/queries/useApp'

interface KeyProps {
  app: App
}

const Keys: React.FC<KeyProps> = ({ app }) => {
  return (
    <Card
      title="API Keys"
      helpText="These are the keys that should be passed to the React Bricks configuration. If you scaffold a project with the CLI, they are already set for you in a .env file."
    >
      <div className="mb-4">
        <FormField
          fieldName="appId"
          type="text"
          label="AppId"
          readOnly
          defaultValue={app?.appId}
        />
      </div>
      <div className="mb-4">
        <FormField
          fieldName="apiKey"
          type="password"
          label="ApiKey"
          readOnly
          defaultValue={app?.apiKey}
        />
      </div>
      <div className="mb-4">
        <FormField
          fieldName="appEnv"
          type="text"
          label="Environment"
          readOnly
          defaultValue={app?.appEnv}
        />
      </div>
    </Card>
  )
}

export default Keys
