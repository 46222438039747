import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { PlanType } from '../../../components/PlanPill'
import { ProviderName } from '../../../pages/LoggedApp/Apps/App/SingleSignOn'

export type Language = {
  code: string
  name: string
}

export type Environment = {
  name: string
  isMain: boolean
}

export type BackupLogRow = {
  id: number
  status: 'IN_PROGRESS' | 'COMPLETED' | 'FAILED'
  pages: number
  images: number
  files: number
  jsonSize: number
  assetsSize: number
  assetsNumber: number
  error: string
  createdAt: string
  finishedAt: string
}

type DayUsage = {
  day: string
  usage: number
}

export type BillingCycleStat = {
  usage: number
  subscriptionMax: number
  overagePrice: number
  dailyUsage: DayUsage[]
}

type BillingCycleStats = {
  diskSpace: BillingCycleStat
  bandwidth: BillingCycleStat
  apiCalls: BillingCycleStat
}

export type MonthUsage = {
  billingCycle: string
  usage: number
  subscriptionMax: number
}

export interface App {
  appId: string
  apiKey: string
  appEnv: string
  appName: string
  isOwner: boolean
  role: 'ADMIN' | 'EDITOR'
  backup: {
    status: '' | 'PENDING' | 'READY'
  }
  defaultLanguage: string
  deployHookUrl: string
  deployHookMethod: string
  deployHookTriggerOnScheduledPublishing: string
  deployHookHeaderKey1: string
  deployHookHeaderValue1: string
  deployHookHeaderKey2: string
  deployHookHeaderValue2: string
  deployHookPayload: string
  deployHookStagingUrl: string
  deployHookStagingMethod: string
  deployHookStagingTriggerOnScheduledPublishing: string
  deployHookStagingHeaderKey1: string
  deployHookStagingHeaderValue1: string
  deployHookStagingHeaderKey2: string
  deployHookStagingHeaderValue2: string
  deployHookStagingPayload: string
  deployHookDevUrl: string
  deployHookDevMethod: string
  deployHookDevTriggerOnScheduledPublishing: string
  deployHookDevHeaderKey1: string
  deployHookDevHeaderValue1: string
  deployHookDevHeaderKey2: string
  deployHookDevHeaderValue2: string
  deployHookDevPayload: string
  firstName?: string
  languages: Language[]
  plan: PlanType
  subscription: {
    planMaxUsers: number // valori massimi del piano
    planMaxPages: number
    planMaxLocales: number
    planMaxApiCalls: number
    planMaxDiskSpace: number | null
    planMaxBandwidth: number
    maxStories: number // valori massimi correnti
    maxUsers: number
    maxPages: number
    maxLocales: number
    maxApiCalls: number
    maxDiskSpace: number
    maxBandwidth: number
    includedApiCalls: number
    includedUsers: number
    includedPages: number
    includedLocales: number
    includedDiskSpace: number
    includedBandwidth: number
    additionalQtyApiCalls: number // step qty
    additionalQtyUsers: number
    additionalQtyPages: number
    additionalQtyLocales: number
    additionalQtyDiskSpace: number
    additionalQtyBandwidth: number
    additionalPriceApiCalls: number // price per step
    additionalPriceUsers: number
    additionalPricePages: number
    additionalPriceLocales: number
    additionalPriceDiskSpace: number
    additionalPriceBandwidth: number
    maxFileSize: number
    maxImageSize: number
    maxFilesBatch: number
    maxFilesConcurrency: number
    changeHistoryRetentionDays: number
    collaboration: boolean
    deployHookStaging: boolean
    deployHookDev: boolean
    scheduledPublishing: boolean
    embedPages: boolean
    lockBlocks: boolean
    flexibleRoles: boolean
    advancedSeo: boolean
    advancedDam: boolean
    workingCopy: boolean
    approvalWorkflow: boolean
    objectStorageBackup: boolean
    objectStorageBackupDaily: boolean
    template: boolean
    richTextExt: boolean
    externalData: boolean
    sso: boolean
    environments: boolean
    maxEnvironments: number
    eventsLog: boolean
    support: string
    features: any[]
    numApiCalls: number
    hasSubscription: boolean // se true -> ha inserito la carta di credito
    diskSpace: number
    numEnvironments: number
    numUsers: number
    numPages: number
    numLocales: number
    numStories: number
    planId: number | null
    price: number
    description: string
    periodFrom: string
    periodTo: string
    customCdn: boolean
    trialDays: number
    updateUrl: string
    cancelUrl: string
    coupon: string
    planActivatedAt: string
  }
  eventsHookUrl: string
  eventsHookAuthToken: string
  hostname?: string
  liveDemo?: { expiresAt: string; deleteAppAt: string; status: string }
  environments: Environment[]
  useWorkingCopy: boolean
  useApprovalWorkflow: boolean
  useObjectStorageBackup: boolean
  useSso: boolean
  adminUrl: string
  sso?: {
    provider: ProviderName
    certificate: string
    redirectUrl: string
    uniqueAppIdentifier: string
    forceSso: boolean
    metadataUrl: string
    entityId: string
    acsUrl: string
  }
  objectStorageBackup?: {
    frequency: 'DAILY' | 'WEEKLY'
    weekDay?:
      | 'MONDAY'
      | 'TUESDAY'
      | 'WEDNESDAY'
      | 'THURSDAY'
      | 'FRIDAY'
      | 'SATURDAY'
      | 'SUNDAY'
    endpoint: string
    region: string
    accessKeyId: string
    secretAccessKey: string
    bucket: string
    lastBackupLog?: BackupLogRow
    lastCompletedBackupLog?: BackupLogRow
  }
  usage?: {
    currentBillingCycle: BillingCycleStats
    previousBillingCycle?: BillingCycleStats
    last12months: {
      diskSpace: MonthUsage[]
      bandwidth: MonthUsage[]
      apiCalls: MonthUsage[]
    }
  }
}

const useApp = (appId?: string, appEnv?: string) => {
  const queryClient = useQueryClient()
  return useQuery(
    ['app', appId, appEnv],
    async () => {
      const { data } = await axios.get<App>(
        `/account/apps/${appId}/environments/${appEnv}`
      )
      return data
      // return {
      //   ...data,
      //   subscription: {
      //     ...data.subscription,
      //     numUsers: 2,
      //     numPages: 57,
      //     maxPages: 60,
      //   },
      // }
    },
    {
      enabled: !!appId && !!appEnv,
      initialData: () => {
        const apps = (queryClient.getQueryData(['apps']) || []) as App[]
        return apps.find((app) => app.appId === appId && app.appEnv === appEnv)
      },
      refetchOnWindowFocus: false,
      //staleTime: 30 * 60 * 1000, // 30 minuti
      //refetchInterval: 15000,
    }
  )
}

export default useApp
