import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useDeleteApp = (appId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => await axios.delete(`/account/apps/${appId}`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('apps')
        cogoToast.success(`App deleted successfully`, {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useDeleteApp
