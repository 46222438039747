import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import FormField from './FormField'
import Button from './Button'
import { UseMutationResult } from 'react-query'
import { FaPlus } from 'react-icons/fa'

interface OptionsProps {
  label: string
  value: string
}

interface FormFieldProps {
  fieldName: string
  type?:
    | 'text'
    | 'date'
    | 'number'
    | 'email'
    | 'textarea'
    | 'checkbox'
    | 'password'
    | 'select'
    | 'normalized-text'
  label?: string
  labelClassName?: string
  validation?: any
  error?: any
  defaultValue?: any
  readOnly?: boolean
  disabled?: boolean
  options?: OptionsProps[]
  isAdvanced?: boolean
}

interface FormProps {
  formName?: string
  mutatingFormName?: string
  setMutatingFormName?: React.Dispatch<React.SetStateAction<string>>
  formFields: FormFieldProps[]
  mutation: UseMutationResult<any, any, any, unknown>
  data: any
  submitText?: string
  errorText?: string
  onCancel?: any
  disabled?: boolean
}

const Form: React.FC<FormProps> = ({
  formName,
  mutatingFormName,
  setMutatingFormName,
  formFields,
  mutation,
  data,
  submitText = 'Save',
  errorText = 'An error occurred while saving',
  onCancel,
  disabled,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: data,
  })
  const [showAdvanced, setShowAdvanced] = useState(false)
  const { mutate } = mutation

  const onSubmit = (data: any) => {
    formName && setMutatingFormName && setMutatingFormName(formName)
    mutate(data)
    onCancel && onCancel()
  }

  useEffect(() => {
    reset(data)
  }, [data, reset])

  const hasAdvancedFormFields =
    formFields.filter((field) => field.isAdvanced).length > 0

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formFields
        .filter((field) => !field.isAdvanced || showAdvanced)
        .map((formField) => {
          return (
            <div key={formField.fieldName} className="mb-4">
              <FormField
                fieldName={formField.fieldName}
                type={formField.type}
                label={formField.label}
                labelClassName={formField.labelClassName}
                readOnly={formField.readOnly}
                disabled={formField.disabled}
                register={register}
                validation={formField.validation}
                error={errors[formField.fieldName]}
                options={formField.options}
              />
            </div>
          )
        })}
      {hasAdvancedFormFields && !showAdvanced && (
        <div className="py-2">
          <button
            type="button"
            className="flex items-center space-x-1 text-blue-600 text-sm"
            onClick={() => setShowAdvanced(true)}
          >
            <FaPlus />
            <span>Show advanced</span>
          </button>
        </div>
      )}
      {!!mutation && (
        <>
          {!!onCancel ? (
            <div className="flex items-center space-x-4 mt-2">
              <Button color="gray" onClick={onCancel} className="flex-1">
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                loading={mutation.isLoading}
                className="flex-1"
              >
                {submitText}
              </Button>
            </div>
          ) : (
            <Button
              type="submit"
              color="blue"
              loading={mutation.isLoading && formName === mutatingFormName}
              disabled={disabled}
              className="md:inline-block mt-2 md:mr-4"
            >
              {submitText}
            </Button>
          )}
          {mutation.isError && formName === mutatingFormName && (
            <span className="block mt-2 text-red-500 md:inline-block md:mt-0">
              {errorText}
            </span>
          )}
        </>
      )}
    </form>
  )
}

export default Form
