import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import cogoToast from "cogo-toast";

const useCreateAppEnvironment = (appId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (environmentName: string) => {
      return await axios
        .post(`/account/apps/${appId}/environments`, { environmentName })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", appId]);
        //cogoToast.success('Branch created successfully', { position: 'bottom-right' })
      },
      onError: (error: Error & { status: number }, values) => {
        console.log("ERROR: ", error?.status);
        if (error?.status === 409) {
          cogoToast.warn(`An Environment with name ${values} already exists`, {
            position: "bottom-right",
          });
        }
      },
    }
  );
};

export default useCreateAppEnvironment;
