import axios from 'axios'
import { useQuery } from 'react-query'

interface Change {
  id: number
  appId: string
  parentId: null
  createdAt: string
  pageId: string
  language: string
  name: string
  action:
    | 'CREATE_PAGE'
    | 'DELETE_PAGE'
    | 'DUPLICATE_PAGE'
    | 'CREATE_TRANSLATION'
    | 'UPDATE_TRANSLATION'
    | 'DELETE_TRANSLATION'
    | 'UPDATE_WORKING_COPY'
    | 'COMMIT_WORKING_COPY'
    | 'APPROVE_WORKING_COPY'
    | 'DISCARD_WORKING_COPY'
    | 'REQUEST_MERGE_WORKING_COPY'
    | 'UNDO_REQUEST_MERGE_WORKING_COPY'
  hookResult: string
  children: null
  user: string
}

interface ChangeHistoryArgs {
  appId: string
  appEnv: string
  pageId: string
  language: string
  userId: string
  userName: string
  pagination: {
    page: number
    pageSize: number
  }
}

const useChangeHistory = ({
  appId,
  appEnv,
  pageId,
  language,
  userId,
  userName,
  pagination,
}: ChangeHistoryArgs) => {
  return useQuery(
    ['changeHistory', pageId, language, userId, pagination],
    async () => {
      const pageIdQuery = pageId ? `pageId=${pageId}` : ''
      const languageQuery = language ? `&language=${language}` : ''
      const userIdQuery = userId ? `&userId=${userId}` : ''
      const userNameQuery = userName ? `&user=${userName}` : ''
      const pageSizeQuery = pagination.pageSize
        ? `&pageSize=${pagination.pageSize}`
        : ''
      const pageQuery = pagination.page ? `&page=${pagination.page}` : ''
      const res = await axios.get<Change[]>(
        `/account/apps/${appId}/environments/${appEnv}/change_history?${pageIdQuery}${languageQuery}${userIdQuery}${userNameQuery}${pageSizeQuery}${pageQuery}`
      )
      return res
    }
  )
}

export default useChangeHistory
