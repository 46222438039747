import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useDeleteAppCustomRole = (
  appId: string,
  appEnv: string,
  roleId: string
) => {
  const queryClient = useQueryClient()
  return useMutation(
    async () =>
      await axios
        .delete(
          `/account/apps/${appId}/environments/${appEnv}/custom_roles/${roleId}`
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appCustomRoles', appId, appEnv])
        queryClient.invalidateQueries(['appUsers', appId, appEnv])
        //queryClient.invalidateQueries(['app', appId])
        cogoToast.success('Custom role deleted successfully', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useDeleteAppCustomRole
