import React, { useState } from 'react'

import usePatchApp from '../../../../hooks/api/mutations/usePatchApp'

import Card from '../../../../components/Card'
import Form from '../../../../components/Form'
import type { App } from '../../../../hooks/api/queries/useApp'

interface WebHooksProps {
  app: App
}

const deployHookMethodOptions = [
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'POST',
    value: 'POST',
  },
]

const urlValidation = {
  pattern: {
    value:
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    message: 'Invalid URL',
  },
}

const WebHooks: React.FC<WebHooksProps> = ({ app }) => {
  const mutation = usePatchApp(app?.appId, app?.appEnv)

  const [mutatingFormName, setMutatingFormName] = useState('')

  // const isEnterprise =
  //   app.plan.toLowerCase() === 'enterprise' || app.plan.toLowerCase() === 'enterprise trial'
  // const isPro =
  //   app.plan.toLowerCase() === 'pro' ||
  //   app.plan.toLowerCase() === 'pro - yearly' ||
  //   app.plan.toLowerCase() === 'enterprise' ||
  //   app.plan.toLowerCase() === 'enterprise trial' ||
  //   app.plan.toLowerCase().startsWith('appsumo')

  return (
    <div>
      <Card
        title="Production Build Hook"
        helpText="This is the URL that is called to trigger a rebuild for the Production website."
      >
        <Form
          formName="deployHookUrl"
          formFields={[
            {
              fieldName: 'deployHookUrl',
              label: 'Hook URL',
              validation: urlValidation,
            },
            {
              fieldName: 'deployHookMethod',
              label: 'Method',
              type: 'select',
              options: deployHookMethodOptions,
            },
            {
              fieldName: 'deployHookTriggerOnScheduledPublishing',
              label: 'Trigger hook on scheduled publishing?',
              type: 'checkbox',
            },
            {
              fieldName: 'deployHookHeaderKey1',
              label: 'Custom header 1 - Name',
              isAdvanced: true,
            },
            {
              fieldName: 'deployHookHeaderValue1',
              label: 'Custom header 1 - Value',
              isAdvanced: true,
            },
            {
              fieldName: 'deployHookHeaderKey2',
              label: 'Custom header 2 - Name',
              isAdvanced: true,
            },
            {
              fieldName: 'deployHookHeaderValue2',
              label: 'Custom header 2 - Value',
              isAdvanced: true,
            },
            {
              fieldName: 'deployHookPayload',
              label: 'Hook Payload (only for POST)',
              type: 'textarea',
              isAdvanced: true,
            },
          ]}
          mutation={mutation}
          data={{
            deployHookUrl: app.deployHookUrl,
            deployHookMethod: app.deployHookMethod,
            deployHookTriggerOnScheduledPublishing:
              app.deployHookTriggerOnScheduledPublishing,
            deployHookHeaderKey1: app.deployHookHeaderKey1,
            deployHookHeaderValue1: app.deployHookHeaderValue1,
            deployHookHeaderKey2: app.deployHookHeaderKey2,
            deployHookHeaderValue2: app.deployHookHeaderValue2,
            deployHookPayload: app.deployHookPayload,
          }}
          mutatingFormName={mutatingFormName}
          setMutatingFormName={setMutatingFormName}
        />
      </Card>

      <Card
        title="Staging Build Hook"
        available={!!app.subscription.deployHookStaging}
        helpText="This is the URL that is called to trigger a rebuild for the Staging website."
      >
        <Form
          formName="deployHookStagingUrl"
          formFields={[
            {
              fieldName: 'deployHookStagingUrl',
              label: 'Hook URL',
              disabled: !app.subscription.deployHookStaging,
              validation: urlValidation,
            },
            {
              fieldName: 'deployHookStagingMethod',
              label: 'Method',
              type: 'select',
              options: deployHookMethodOptions,
              disabled: !app.subscription.deployHookStaging,
            },
            {
              fieldName: 'deployHookStagingTriggerOnScheduledPublishing',
              label: 'Trigger hook on scheduled publishing?',
              type: 'checkbox',
              disabled: !app.subscription.deployHookStaging,
            },
            {
              fieldName: 'deployHookStagingHeaderKey1',
              label: 'Custom header 1 - Name',
              isAdvanced: true,
              disabled: !app.subscription.deployHookStaging,
            },
            {
              fieldName: 'deployHookStagingHeaderValue1',
              label: 'Custom header 1 - Value',
              isAdvanced: true,
              disabled: !app.subscription.deployHookStaging,
            },
            {
              fieldName: 'deployHookStagingHeaderKey2',
              label: 'Custom header 2 - Name',
              isAdvanced: true,
              disabled: !app.subscription.deployHookStaging,
            },
            {
              fieldName: 'deployHookStagingHeaderValue2',
              label: 'Custom header 2 - Value',
              isAdvanced: true,
              disabled: !app.subscription.deployHookStaging,
            },
            {
              fieldName: 'deployHookStagingPayload',
              label: 'Hook Payload (only for POST)',
              type: 'textarea',
              isAdvanced: true,
              disabled: !app.subscription.deployHookStaging,
            },
          ]}
          mutation={mutation}
          data={{
            deployHookStagingUrl: app.deployHookStagingUrl,
            deployHookStagingMethod: app.deployHookStagingMethod,
            deployHookStagingTriggerOnScheduledPublishing:
              app.deployHookStagingTriggerOnScheduledPublishing,
            deployHookStagingHeaderKey1: app.deployHookStagingHeaderKey1,
            deployHookStagingHeaderValue1: app.deployHookStagingHeaderValue1,
            deployHookStagingHeaderKey2: app.deployHookStagingHeaderKey2,
            deployHookStagingHeaderValue2: app.deployHookStagingHeaderValue2,
            deployHookStagingPayload: app.deployHookStagingPayload,
          }}
          disabled={!app.subscription.deployHookStaging}
          mutatingFormName={mutatingFormName}
          setMutatingFormName={setMutatingFormName}
        />
      </Card>

      <Card
        title="Development Build Hook"
        available={!!app.subscription.deployHookDev}
        helpText="This is the URL that is called to trigger a rebuild for the Development website."
      >
        <Form
          formName="deployHookDevUrl"
          formFields={[
            {
              fieldName: 'deployHookDevUrl',
              label: 'Hook URL',
              disabled: !app.subscription.deployHookDev,
              validation: urlValidation,
            },
            {
              fieldName: 'deployHookDevMethod',
              label: 'Method',
              type: 'select',
              options: deployHookMethodOptions,
              disabled: !app.subscription.deployHookDev,
            },
            {
              fieldName: 'deployHookDevTriggerOnScheduledPublishing',
              label: 'Trigger hook on scheduled publishing?',
              type: 'checkbox',
              disabled: !app.subscription.deployHookDev,
            },
            {
              fieldName: 'deployHookDevHeaderKey1',
              label: 'Custom header 1 - Name',
              isAdvanced: true,
              disabled: !app.subscription.deployHookDev,
            },
            {
              fieldName: 'deployHookDevHeaderValue1',
              label: 'Custom header 1 - Value',
              isAdvanced: true,
              disabled: !app.subscription.deployHookDev,
            },
            {
              fieldName: 'deployHookDevHeaderKey2',
              label: 'Custom header 2 - Name',
              isAdvanced: true,
              disabled: !app.subscription.deployHookDev,
            },
            {
              fieldName: 'deployHookDevHeaderValue2',
              label: 'Custom header 2 - Value',
              isAdvanced: true,
              disabled: !app.subscription.deployHookDev,
            },
            {
              fieldName: 'deployHookDevPayload',
              label: 'Hook Payload (only for POST)',
              type: 'textarea',
              isAdvanced: true,
              disabled: !app.subscription.deployHookDev,
            },
          ]}
          mutation={mutation}
          data={{
            deployHookDevUrl: app.deployHookDevUrl,
            deployHookDevMethod: app.deployHookDevMethod,
            deployHookDevTriggerOnScheduledPublishing:
              app.deployHookDevTriggerOnScheduledPublishing,
            deployHookDevHeaderKey1: app.deployHookDevHeaderKey1,
            deployHookDevHeaderValue1: app.deployHookDevHeaderValue1,
            deployHookDevHeaderKey2: app.deployHookDevHeaderKey2,
            deployHookDevHeaderValue2: app.deployHookDevHeaderValue2,
            deployHookDevPayload: app.deployHookDevPayload,
          }}
          disabled={!app.subscription.deployHookDev}
          mutatingFormName={mutatingFormName}
          setMutatingFormName={setMutatingFormName}
        />
      </Card>

      <Card
        title="Events Hook"
        available={!!app.subscription.eventsLog}
        helpText="This is a URL that we'll call in POST upon page Create / Save or Delete to notify you. See the Docs for the payload shape."
      >
        <Form
          formName="eventsHook"
          formFields={[
            {
              fieldName: 'eventsHookUrl',
              label: 'Hook URL',
              disabled: !app.subscription.eventsLog,
              validation: urlValidation,
            },
            {
              fieldName: 'eventsHookAuthToken',
              label: 'Hook Auth Token',
              disabled: !app.subscription.eventsLog,
            },
          ]}
          mutation={mutation}
          data={{
            eventsHookUrl: app.eventsHookUrl,
            eventsHookAuthToken: app.eventsHookAuthToken,
          }}
          disabled={!app.subscription.eventsLog}
          mutatingFormName={mutatingFormName}
          setMutatingFormName={setMutatingFormName}
        />
      </Card>
    </div>
  )
}

export default WebHooks
