import React, { useEffect } from 'react'
import * as gtag from '../../utils/gtag'
import Loading from '../../components/Loading'

const SsoLoginSuccess: React.FC = () => {
  useEffect(() => {
    // Analytics Event
    gtag.trackEvent({
      category: 'login',
      action: 'login_failed',
      label: 'SSO login failed',
    })
  }, [])

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center justify-center text-center">
        <h3 className="text-lg font-bold">SSO Login Failed</h3>
        <p className="mt-3">
          Please, contact your website administrator or write to{' '}
          <a
            className="text-pink-500 underline"
            href="mailto:support@reactbricks.com"
          >
            React Bricks support
          </a>
        </p>
      </div>
    </div>
  )
}

export default SsoLoginSuccess
