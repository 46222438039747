import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useCreateAppEnvironment from '../../hooks/api/mutations/useCreateAppEnvironment'

import Button from '../../components/Button'
import FormField from '../../components/FormField'

interface CreateEnvironmentProps {
  appId: string
  onCloseCreateModal: any
}

const initalData = {
  name: '',
}

const normalizeEnvironmentName = (value: string) => {
  const normalized = value
    .replace(/(\s|-)/g, '_')
    .normalize('NFKD')
    .replace(/\W/g, '')
    .toLowerCase()

  return normalized.substring(0, 30)
}

const CreateEnvironment: React.FC<CreateEnvironmentProps> = ({
  appId,
  onCloseCreateModal,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm<any>({
    defaultValues: initalData,
  })

  useEffect(() => {
    reset(initalData)
  }, [reset])

  const createEnvironment = useCreateAppEnvironment(appId)

  return (
    <div className="px-12 py-8 max-w-md">
      <h2 className="text-lg font-semibold text-center mb-5">
        Create a new environment
      </h2>

      <div className="mb-5 text-sm text-gray-600">
        The Environment name cannot be changed later, so think it over well :)
      </div>

      <form
        onSubmit={handleSubmit((data) => {
          createEnvironment.mutate(data.name)
          onCloseCreateModal()
        })}
      >
        <div className="mb-6">
          <FormField
            fieldName="name"
            type="normalized-text"
            control={control}
            normalize={normalizeEnvironmentName}
            label="Name"
            validation={{
              required: 'The environment name is required',
            }}
            error={errors.name}
            register={register}
          />
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <Button color="gray" onClick={onCloseCreateModal} className="flex-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            loading={createEnvironment.isLoading}
            className="flex-1"
          >
            Create
          </Button>
        </div>

        {createEnvironment.isError && (
          <span className="block mt-2 text-red-500 md:inline-block md:mt-0">
            An error occurred while creating this environment
          </span>
        )}
      </form>
    </div>
  )
}

export default CreateEnvironment
