import React from 'react'
import { useForm } from 'react-hook-form'
import debounce from 'lodash.debounce'
import {
  // FiActivity,
  FiDownload,
} from 'react-icons/fi'
import Card from '../../../../components/Card'
import usePatchApp from '../../../../hooks/api/mutations/usePatchApp'
import useCheckSsoUai from '../../../../hooks/api/mutations/useCheckSsoUai'
import type { App } from '../../../../hooks/api/queries/useApp'
import FormField from '../../../../components/FormField'
import Button from '../../../../components/Button'

import imgSSO from '../../../../images/form_sso.png'

interface AppNameProps {
  app: App
}

export type ProviderName =
  | 'AUTH0'
  | 'GSUITE'
  | 'MICROSOFT ENTRA ID'
  | 'OKTA'
  | 'ONE LOGIN'
  | 'PING IDENTITY'
  | 'OTHER'

type SSOProvider = {
  name: ProviderName
  label: string
}

const SSOProviders: SSOProvider[] = [
  { name: 'AUTH0', label: 'Auth0' },
  { name: 'GSUITE', label: 'Google Suite' },
  { name: 'MICROSOFT ENTRA ID', label: 'Microsoft Entra ID' },
  { name: 'OKTA', label: 'Okta' },
  { name: 'ONE LOGIN', label: 'OneLogin' },
  { name: 'PING IDENTITY', label: 'Ping Identity' },
  { name: 'OTHER', label: 'Other' },
]

const providersOptions = [
  {
    value: '',
    label: '-- Select a provider --',
  },
].concat(
  SSOProviders.map((provider) => {
    return {
      value: provider.name,
      label: provider.label,
    }
  })
)

type SSOConfig = {
  useSso: boolean
  provider: ProviderName
  certificate: string
  redirectUrl: string
  uniqueAppIdentifier: string
  forceSso: boolean
}

const normalizeUniqueAppIdentifier = (uai: string) => {
  if (!uai) {
    return ''
  }
  const normalized = uai
    .replace(/(\s|-)/g, '_')
    .normalize('NFKD')
    .replace(/\W/g, '')
    .toLowerCase()

  return normalized.substring(0, 20)
}

const SingleSignOn: React.FC<AppNameProps> = ({ app }) => {
  const patchApp = usePatchApp(app?.appId, app?.appEnv)
  const checkSsoUai = useCheckSsoUai(app?.appId, app?.appEnv)

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, submitCount },
  } = useForm<SSOConfig>({
    defaultValues: {
      useSso: app.useSso,
      provider: app.sso?.provider,
      certificate: app.sso?.certificate,
      redirectUrl: app.sso?.redirectUrl,
      uniqueAppIdentifier: app.sso?.uniqueAppIdentifier,
      forceSso: app.sso?.forceSso || false,
    },
    mode: 'onChange',
  })

  const ssoProviderDataOk =
    watch('useSso') &&
    watch('provider') &&
    watch('certificate') &&
    watch('redirectUrl')

  const uniqueValidation = async (value: string) => {
    console.log('Validate ' + value)
    if (value.length < 6) {
      return 'At least 6 characters.'
    }

    return checkSsoUai
      .mutateAsync(value)
      .then((data) => {
        if (!data.result) {
          return 'An error happened while verifying the unique identifier.'
        }
        if (data.result === 'TAKEN') {
          return 'Sorry, this name is already taken.'
        }
        if (data.result === 'NOT_VALID') {
          return 'Sorry, this name is not valid.'
        }
        return true
      })
      .catch(() => {
        return 'An error happened while verifying the unique identifier.'
      })
  }

  const uniqueValidationDebounced = React.useRef(
    debounce(uniqueValidation, 400, { leading: true })
  ).current

  return (
    <div>
      <Card
        title="Single Sign-On"
        helpText="Configure SAML2-based SSO for your App."
        available={!!app.subscription.sso}
      >
        <form
          onSubmit={handleSubmit((data) => {
            const { useSso, ...rest } = data
            patchApp.mutateAsync({
              useSso,
              sso: rest,
            })
          })}
        >
          <div className="mb-4">
            <FormField
              fieldName="useSso"
              type="checkbox"
              label="Enable Single Sign-On"
              register={register}
              disabled={!app.subscription.sso}
            />
          </div>

          {watch('useSso') && (
            <>
              <div className="p-6 border rounded-lg shadow-lg mb-6">
                <h2 className="text-sm uppercase tracking-wider font-bold text-gray-400 mb-6">
                  React Bricks Service Provider
                </h2>

                <div className="sm:flex sm:items-center sm:space-x-8 mb-6">
                  <a
                    href={app.sso?.metadataUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 py-3 px-5 bg-blue-50 hover:bg-white transition-colors border border-blue-200 rounded text-accent-600 hover:text-accent-700"
                  >
                    <FiDownload />
                    <div>Download Metadata</div>
                  </a>
                  <a
                    href="/reactbricks_icon.png"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 py-3 px-5 bg-blue-50 hover:bg-white transition-colors border border-blue-200 rounded text-accent-600 hover:text-accent-700"
                  >
                    <FiDownload />
                    <div>Download App Logo</div>
                    <div>
                      <img
                        src="/reactbricks_icon.png"
                        alt="React Bricks Icon"
                        className="w-5 h-5 border border-slate-200 rounded"
                      />
                    </div>
                  </a>
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="entityId"
                    className="block text-sm font-semibold mb-1"
                  >
                    Entity ID
                  </label>

                  <input
                    id="entityId"
                    type="text"
                    value={app.sso?.entityId}
                    readOnly
                    className="form-input bg-gray-100 text-gray-600 focus:bg-white mt-1 block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="acsUrl"
                    className="block text-sm font-semibold mb-1"
                  >
                    ACS (Assertion Consumer Service) URL
                  </label>

                  <input
                    id="acsUrl"
                    type="text"
                    value={app.sso?.acsUrl}
                    readOnly
                    className="form-input bg-gray-100 text-gray-600 focus:bg-white mt-1 block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="p-6 border rounded-lg shadow-lg mb-6">
                <h2 className="text-sm uppercase tracking-wider font-bold text-gray-400 mb-6">
                  SSO Provider Parameters
                </h2>
                <div className="mb-4">
                  <FormField
                    fieldName="provider"
                    type="select"
                    label="SSO Provider"
                    options={providersOptions}
                    validation={{ required: 'Select a provider' }}
                    register={register}
                    error={errors.provider}
                  />
                </div>
                <div className="mb-4">
                  <FormField
                    fieldName="redirectUrl"
                    type="text"
                    label="Redirect URL"
                    register={register}
                    error={errors.redirectUrl}
                    validation={{ required: 'Required' }}
                  />
                </div>
                <div className="mb-4">
                  <FormField
                    fieldName="certificate"
                    type="textarea"
                    label="X.509 Certificate"
                    register={register}
                    error={errors.certificate}
                    validation={{ required: 'Required' }}
                  />
                </div>
                {/* <Button
                  color="accent"
                  className="flex items-center space-x-2 pl-2 pr-2 pt-1 pb-1 text-sm"
                  disabled={!ssoProviderDataOk}
                  //onClick={onOpenPushModal(environment)}
                >
                  <FiActivity />
                  <span>Test connection</span>
                </Button> */}
              </div>

              <div className="p-6 border rounded-lg shadow-lg mb-6">
                <h2 className="text-sm uppercase tracking-wider font-bold text-gray-400 mb-6">
                  Login configuration
                </h2>

                <div className="mb-8">
                  <FormField
                    fieldName="uniqueAppIdentifier"
                    type="normalized-text"
                    label="Single Sign-On App Identifier"
                    //register={register}
                    control={control}
                    error={errors.uniqueAppIdentifier}
                    validation={{ validate: uniqueValidationDebounced }}
                    normalize={normalizeUniqueAppIdentifier}
                  />
                  {watch('uniqueAppIdentifier')?.length > 1 &&
                    !errors.uniqueAppIdentifier && (
                      <div className="mt-1 text-green-600 text-sm font-bold">
                        👍 This name is valid and unique
                      </div>
                    )}
                  <p className="mt-2 text-sm text-gray-500">
                    This is a unique identifier of your App. Your users will
                    type this to login via SSO. Keep it short and easy to
                    remember, min 6 characters, max 20 (for example "acme_sso").
                  </p>
                </div>

                <div className="mb-4">
                  <FormField
                    fieldName="forceSso"
                    type="checkbox"
                    label={
                      <b className="font-semibold">Force login only via SSO</b>
                    }
                    register={register}
                  />
                  <p className="text-sm text-gray-500">
                    Warning: contractors may not be able to login any more
                  </p>
                </div>
              </div>
            </>
          )}

          <Button
            type="submit"
            color="blue"
            loading={patchApp.isLoading}
            className="flex-1"
            disabled={
              watch('useSso') &&
              (!ssoProviderDataOk || !watch('uniqueAppIdentifier'))
            }
          >
            Save SSO preferences
          </Button>

          {!app.subscription.sso && (
            <div className="relative">
              <img
                src={imgSSO}
                alt="Single Sign-On form"
                className="max-w-md"
              />
              <div className="backdrop-blur-sm bg-white/30 absolute inset-0" />
            </div>
          )}
        </form>
      </Card>
    </div>
  )
}

export default SingleSignOn
