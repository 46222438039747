import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Tooltip } from 'react-tooltip'
import { FiInfo } from 'react-icons/fi'

import useCreateInvitation from '../../hooks/api/mutations/useCreateInvitation'

import FormField from '../FormField'
import Button from '../Button'
import { Role } from '../../hooks/api/queries/useCustomRoles'
import type { App } from '../../hooks/api/queries/useApp'

interface InviteUserProps {
  app: App
  onCloseInviteModal: any
  customRoles: Role[]
}

const initalData = {
  role: 'EDITOR',
  customRoleId: '',
  canDeploy: false,
  canDeployStaging: false,
  canDeployDev: false,
  canCreatePage: false,
  canDeletePage: false,
}

const userRoleOptions = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Editor', value: 'EDITOR' },
]

const InviteUser: React.FC<InviteUserProps> = ({
  app,
  onCloseInviteModal,
  customRoles,
}) => {
  const createInvitation = useCreateInvitation(app.appId, app.appEnv)
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: initalData,
  })

  useEffect(() => {
    reset(initalData)
  }, [reset])

  const watchRole = watch('role')

  const userCustomRoleOptions = [
    {
      value: '',
      label: '-- Select a custom role --',
    },
  ].concat(
    customRoles.map((customRole: Role) => {
      return {
        value: customRole.id,
        label: customRole.name,
      }
    })
  )

  // const calculatePosition = (
  //   position: any,
  //   currentEvent: any,
  //   current: any
  // ) => {
  //   if (current.id === 'prod') {
  //     return { left: 300, top: 190 }
  //   } else if (current.id === 'staging') return { left: 300, top: 230 }
  //   else return { left: 300, top: 270 }
  // }

  return (
    <div className="px-12 py-8 max-w-2xl">
      <h2 className="text-xl font-semibold text-center mb-3">Invite user</h2>

      <div className="mb-5 text-sm text-gray-600">
        Invite a new user to collaborate on your app, choosing its{' '}
        <span className="text-pink-500" data-tooltip-id="role">
          Role
        </span>{' '}
        and Permissions.
      </div>

      <form
        onSubmit={handleSubmit((data) => {
          onCloseInviteModal()
          if (data.role === 'ADMIN') {
            const {
              canDeploy,
              canDeployStaging,
              canDeployDev,
              canCreatePage,
              canDeletePage,
              ...finalData
            } = data
            return createInvitation.mutate(finalData)
          }
          return createInvitation.mutate(data)
        })}
      >
        <div className="mb-4">
          <FormField
            fieldName="email"
            type="email"
            label="Email"
            register={register}
            validation={{
              required: 'Enter an email address',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Invalid email address',
              },
            }}
            error={errors.email}
          />
        </div>
        <div className="mb-4">
          <FormField
            fieldName="role"
            type="select"
            label="Role"
            register={register}
            options={userRoleOptions}
          />
        </div>
        {!!customRoles && customRoles.length > 0 && (
          <div className="mb-6">
            <FormField
              fieldName="customRoleId"
              type="select"
              label="Custom Role"
              register={register}
              options={userCustomRoleOptions}
            />
          </div>
        )}

        {watchRole !== 'ADMIN' && (
          <div className="block sm:flex">
            <div className="md:w-1/2">
              <div className="mb-4">
                <FormField
                  fieldName="canCreatePage"
                  type="checkbox"
                  register={register}
                  label={<span className="ml-2">Can create pages</span>}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="canDeletePage"
                  type="checkbox"
                  register={register}
                  label={<span className="ml-2">Can delete pages</span>}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="canEditSeo"
                  type="checkbox"
                  register={register}
                  label={<span className="ml-2">Can edit SEO</span>}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="canEditPageAttributes"
                  type="checkbox"
                  register={register}
                  label={
                    <span className="ml-2">Can edit pages attributes</span>
                  }
                />
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="mb-4">
                <FormField
                  fieldName="canDeploy"
                  type="checkbox"
                  register={register}
                  label={
                    <div className="flex items-center">
                      <span className="ml-2">Can deploy in Production</span>
                      <span
                        id="prod"
                        className="ml-2 text-pink-500"
                        data-tooltip-content={
                          'Checking this flag you allow the Editor user to trigger the Production build hook through the "Deploy" button in React Bricks interface.'
                        }
                        data-tooltip-id="helpDeployInvite"
                      >
                        <FiInfo />
                      </span>
                    </div>
                  }
                />
              </div>
              <div className="mb-4">
                <div
                  data-tooltip-content={'Not available'}
                  data-tooltip-id={
                    !app.subscription.deployHookStaging
                      ? 'canDeployStaging'
                      : ''
                  }
                >
                  <FormField
                    fieldName="canDeployStaging"
                    type="checkbox"
                    register={register}
                    disabled={!app.subscription.deployHookStaging}
                    checkboxLabelClass={
                      !app.subscription.deployHookStaging
                        ? 'text-slate-400'
                        : ''
                    }
                    label={
                      <div className="flex items-center">
                        <span className="ml-2">Can deploy in Staging</span>
                        <span
                          id="staging"
                          className="ml-2 mr-2 text-pink-500"
                          data-tooltip-content={
                            'Checking this flag you allow the Editor user to trigger the Staging build hook through the "Deploy" button in React Bricks interface.'
                          }
                          data-tooltip-id="helpDeployInvite"
                        >
                          <FiInfo />
                        </span>
                        {/* {!app.subscription.deployHookStaging && (
                        <div className="text-xs font-bold uppercase tracking-wide bg-gray-200 text-gray-500 py-px px-2 rounded-sm">
                          Not available
                        </div>
                      )} */}
                      </div>
                    }
                  />
                  {!app.subscription.deployHookStaging && (
                    <Tooltip
                      id="canDeployStaging"
                      place="bottom"
                    />
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div
                  data-tooltip-content={'Not available'}
                  data-tooltip-id={
                    !app.subscription.deployHookDev ? 'canDeployDev' : ''
                  }
                >
                  <FormField
                    fieldName="canDeployDev"
                    type="checkbox"
                    register={register}
                    disabled={!app.subscription.deployHookDev}
                    checkboxLabelClass={
                      !app.subscription.deployHookDev ? 'text-slate-400' : ''
                    }
                    label={
                      <div className="flex items-center">
                        <span className="ml-2">Can deploy in Devolpment</span>
                        <span
                          id="dev"
                          className="ml-2 mr-2 text-pink-500"
                          data-tooltip-content={
                            'Checking this flag you allow the Editor user to trigger the Development build hook through the "Deploy" button in React Bricks interface.'
                          }
                          data-tooltip-id="helpDeployInvite"
                          //onClick={() => ReactTooltip.show(modalRef.current)}
                        >
                          <FiInfo />
                        </span>
                        {/* {!app.subscription.deployHookDev && (
                        <div className="text-xs font-bold uppercase tracking-wide bg-gray-200 text-gray-500 py-px px-2 rounded-sm">
                          Not available
                        </div>
                      )} */}
                      </div>
                    }
                  />
                  {!app.subscription.deployHookDev && (
                    <Tooltip
                      id="canDeployDev"
                      place="bottom"
                    />
                  )}
                </div>
              </div>

              <div className="mb-4">
                <div data-tooltip-content={'Not available'} data-tooltip-id="canApprovePage">
                  <FormField
                    fieldName="canApprove"
                    type="checkbox"
                    register={register}
                    disabled={
                      !(
                        app.subscription.workingCopy &&
                        app.subscription.approvalWorkflow &&
                        app.useWorkingCopy &&
                        app.useApprovalWorkflow
                      )
                    }
                    checkboxLabelClass={
                      !(
                        app.subscription.workingCopy &&
                        app.subscription.approvalWorkflow &&
                        app.useWorkingCopy &&
                        app.useApprovalWorkflow
                      )
                        ? 'text-slate-400'
                        : ''
                    }
                    label={
                      <div className="flex items-center">
                        <span className="ml-2">Can approve pages</span>
                        {/* <span
                        className="ml-2 mr-2 text-pink-500"
                        data-tooltip-content={
                          'Checking this flag you allow the Editor user to trigger the Development build hook through the "Deploy" button in React Bricks interface.'
                        }
                        data-tooltip-id="helpDeployInvite"
                      >
                        <FiInfo />
                      </span> */}
                        {/* {!app.subscription.deployHookDev && (
                        <div className="text-xs font-bold uppercase tracking-wide bg-gray-200 text-gray-500 py-px px-2 rounded-sm">
                          Not available
                        </div>
                      )} */}
                      </div>
                    }
                  />
                  {!(
                    app.subscription.workingCopy &&
                    app.subscription.approvalWorkflow &&
                    app.useWorkingCopy &&
                    app.useApprovalWorkflow
                  ) && (
                    <Tooltip
                      id="canApprovePage"
                      place="bottom"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center space-x-4 mt-6">
          <Button color="gray" onClick={onCloseInviteModal} className="flex-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            loading={createInvitation.isLoading}
            className="flex-1"
          >
            Send invitation
          </Button>
        </div>

        {createInvitation.isError && (
          <span className="block mt-2 text-red-500 md:inline-block md:mt-0">
            An error occurred while sending the invitation
          </span>
        )}
      </form>

      <Tooltip
        id="role"
        place="bottom"
        className="w-1/2"
      >
        <div className="text-sm leading-tight text-gray-400">
          <p className="mb-2">
            <b className="text-pink-400">Admin users</b> can edit app settings,
            invite other users and edit content.
          </p>
          <p>
            <b className="text-pink-400">Editor users</b> can just edit content
            using React Bricks interface.
          </p>
        </div>
      </Tooltip>
    </div>
  )
}

export default InviteUser
