import { useQuery } from 'react-query'
import axios from 'axios'

const useTokenChangePassword = (token?: string) => {
  return useQuery(
    ['tokenChangePassword', token],
    async () => {
      const { data } = await axios.get<{ message: string }>(
        `/account/change_password/${token}`
      )
      return data
    },
    {
      enabled: !!token,
    }
  )
}

export default useTokenChangePassword
