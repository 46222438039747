import React from 'react'

import usePatchUser from '../../../hooks/api/mutations/usePatchUser'

import Card from '../../../components/Card'
import Form from '../../../components/Form'
import type { User } from '../../../hooks/api/queries/useProfile'

interface userDetailsProps {
  user: User
}

const UserDetails: React.FC<userDetailsProps> = ({ user }) => {
  const mutation = usePatchUser()

  return (
    <Card title="Your data">
      <Form
        formFields={[
          {
            fieldName: 'firstName',
            label: 'First Name',
            validation: { required: 'First name is required' },
          },
          {
            fieldName: 'lastName',
            label: 'Last Name',
            validation: { required: 'Last name is required' },
          },
          {
            fieldName: 'company',
            label: 'Company',
            validation: { required: 'Company is required' },
          },
        ]}
        mutation={mutation}
        data={{
          company: user.company,
          firstName: user.firstName,
          lastName: user.lastName,
        }}
      />
    </Card>
  )
}

export default UserDetails
