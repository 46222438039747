import React from 'react'
import { FiPower, FiCoffee, FiSettings } from 'react-icons/fi'

import { useClickOutsideListenerRef } from '../hooks/useClickOutsideListenerRef'
import { Link } from 'react-router-dom'

interface IHeaderMenuProps {
  onClose: () => void
  onPause: () => void
  onLogout: () => void
}

const HeaderMenu: React.FC<IHeaderMenuProps> = ({
  onClose,
  onPause,
  onLogout,
}) => {
  const ref = useClickOutsideListenerRef(onClose)

  return (
    <div
      className="absolute top-0 right-0 mt-10 rounded-md shadow-xl"
      style={{ width: 150 }}
      ref={ref}
    >
      <div className="py-1 rounded-md bg-white shadow-xs">
        <Link to="/user" className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900 flex items-center user-menu-item">
        <FiSettings className="text-gray-500 mr-2" />
          User settings
        </Link>

        <hr className="my-1" />

        <button
          className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900 flex items-center user-menu-item"
          onClick={onPause}
        >
          <FiCoffee className="text-gray-500 mr-2 pause" />
          Coffee Break
        </button>
        <button
          className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900 flex items-center user-menu-item"
          onClick={onLogout}
        >
          <FiPower className="text-gray-500 mr-2 logout" />
          Log out
        </button>
      </div>
    </div>
  )
}

export default HeaderMenu
