import React, { useState } from 'react'
import Modal from 'react-modal'
import { Tooltip } from 'react-tooltip'
import { FiPlusCircle } from 'react-icons/fi'

import useProfile from '../../../../hooks/api/queries/useProfile'
import type { App, Environment } from '../../../../hooks/api/queries/useApp'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import EnvironmentsRow from '../../../../components/Environments/EnvironmentsRow'
import CreateEnvironment from '../../../../components/Environments/CreateEnvironment'
import SyncEnvironment from '../../../../components/Environments/SyncEnvironment'
import PushEnvironment from '../../../../components/Environments/PushEnvironment'
import RemoveEnvironment from '../../../../components/Environments/RemoveEnvironment'
import { Link, useParams } from 'react-router-dom'

import { modalCustomStyles } from '../../../../styles/modalStyles'
// import EnvironmentCard from './EnvironmentCard'

interface UsersProps {
  app: App
}

const Users: React.FC<UsersProps> = ({ app }) => {
  const [createEnvironment, setCreateEnvironment] = useState<boolean>(false)
  const [syncEnvironment, setSyncEnvironment] = useState<Environment | null>(
    null
  )
  const [pushEnvironment, setPushEnvironment] = useState<Environment | null>(
    null
  )
  const [deleteEnvironment, setDeleteEnvironment] =
    useState<Environment | null>(null)

  const { data: user } = useProfile()

  const { appId } = useParams()

  const openCreateModal = () => {
    setCreateEnvironment(true)
  }
  const closeCreateModal = () => {
    setCreateEnvironment(false)
  }
  const openSyncModal = (environment: Environment) => () => {
    setSyncEnvironment(environment)
  }
  const closeSyncModal = () => {
    setSyncEnvironment(null)
  }
  const openPushModal = (environment: Environment) => () => {
    setPushEnvironment(environment)
  }
  const closePushModal = () => {
    setPushEnvironment(null)
  }
  const openDeleteModal = (environment: Environment) => () => {
    setDeleteEnvironment(environment)
  }
  const closeDeleteModal = () => {
    setDeleteEnvironment(null)
  }

  const addEnvironmentDisabled =
    !user?.isVerified ||
    app.subscription.numEnvironments >= app.subscription.maxEnvironments ||
    app.appEnv !== 'main' ||
    !app.subscription.environments

  let tooltip = ''

  if (addEnvironmentDisabled) {
    if (!user?.isVerified) {
      tooltip =
        'You need to verify your e-mail address in order to add an environment'
    } else if (app.appEnv !== 'main') {
      tooltip = 'You cannot edit your environments from this page.'
    } else if (!app.subscription.environments) {
      tooltip = 'Not available for your plan.'
    } else {
      tooltip = `You reached the max number of Environments for your plan.`
    }
  }

  const orderedEnvironments = [...app.environments].sort((a, b) => {
    if (a.isMain) {
      return -1
    }
    return 1
  })

  return (
    <div>
      <Card
        title={app.appEnv === 'main' ? `Environments` : 'Environments 🔐'}
        actionButton={
          <Button
            color="green"
            className="flex items-center pl-3 mb-4 relative"
            onClick={openCreateModal}
            disabled={addEnvironmentDisabled}
          >
            {addEnvironmentDisabled && (
              <>
                <div
                  className="absolute top-0 bottom-0 right-0 left-0"
                  data-tooltip-id="userCan"
                  data-tooltip-content={tooltip}
                />
                <Tooltip
                  id="userCan"
                  place="top"
                />
              </>
            )}
            <FiPlusCircle
              className={`mr-2 ${
                addEnvironmentDisabled ? '' : 'text-lime-200'
              }`}
            />
            Create Environment
          </Button>
        }
      >
        <>
          {app.appEnv !== 'main' && (
            <p className="mb-6 max-w-lg">
              You cannot edit your environments from this page. Visit the{' '}
              <Link
                to={`/apps/${appId}/environments/main/environments`}
                className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
              >
                main environment
              </Link>{' '}
              page to change it.
            </p>
          )}
          <div className="flex flex-col w-full">
            {orderedEnvironments.map((environment) => {
              return (
                <EnvironmentsRow
                  key={environment.name}
                  environment={environment}
                  app={app}
                  onOpenSyncModal={openSyncModal}
                  onOpenPushModal={openPushModal}
                  onOpenDeleteModal={openDeleteModal}
                />
              )
            })}
          </div>
        </>
      </Card>

      <Modal
        isOpen={!!createEnvironment}
        onRequestClose={closeCreateModal}
        style={modalCustomStyles}
      >
        {createEnvironment && (
          <CreateEnvironment
            appId={app.appId}
            onCloseCreateModal={closeCreateModal}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!syncEnvironment}
        onRequestClose={closeSyncModal}
        style={modalCustomStyles}
      >
        {syncEnvironment && (
          <SyncEnvironment
            appId={app.appId}
            environment={syncEnvironment}
            onCloseSyncModal={closeSyncModal}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!pushEnvironment}
        onRequestClose={closePushModal}
        style={modalCustomStyles}
      >
        {pushEnvironment && (
          <PushEnvironment
            appId={app.appId}
            environment={pushEnvironment}
            environments={app.environments?.filter(
              // All the other environments, where I can push this one
              (environment) => environment.name !== pushEnvironment.name
            )}
            onClosePushModal={closePushModal}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!deleteEnvironment}
        onRequestClose={closeDeleteModal}
        style={modalCustomStyles}
      >
        {deleteEnvironment && (
          <RemoveEnvironment
            appId={app.appId}
            environment={deleteEnvironment}
            onCloseDeleteModal={closeDeleteModal}
          />
        )}
      </Modal>
    </div>
  )
}

export default Users
