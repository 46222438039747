import classNames from 'classnames'
import React, { useState } from 'react'
import { Control, Controller } from 'react-hook-form'

type OptionsProps = {
  label: string
  value: string
}

type FormFieldProps = {
  fieldName: string
  type?:
    | 'text'
    | 'date'
    | 'number'
    | 'email'
    | 'textarea'
    | 'checkbox'
    | 'password'
    | 'select'
    | 'normalized-text'
  label?: string | React.ReactElement
  labelClassName?: string
  register?: any
  control?: any
  normalize?: (value: string) => string
  validation?: any
  error?: any
  defaultValue?: any
  readOnly?: boolean
  disabled?: boolean
  options?: OptionsProps[]
  isAdvanced?: boolean
  checkboxLabelClass?: string
  tabIndex?: number
}

const FormField: React.FC<FormFieldProps> = ({
  fieldName,
  type = 'text',
  label,
  labelClassName,
  register = () => null,
  control,
  normalize,
  validation,
  error,
  defaultValue,
  readOnly,
  disabled,
  options,
  checkboxLabelClass,
  tabIndex,
}) => {
  const [pwdVisible, setPwdVisible] = useState(false)

  const togglePwdVisible = (event: any) => {
    event.preventDefault()
    setPwdVisible(!pwdVisible)
  }

  // CHECKBOX
  if (type === 'checkbox') {
    return (
      <>
        <div className="mb-3">
          <div className="inline-flex items-center">
            <input
              id={fieldName}
              type={type}
              defaultChecked={defaultValue}
              disabled={disabled}
              readOnly={readOnly}
              tabIndex={tabIndex}
              className={classNames(
                'form-checkbox text-blue-500 border rounded',
                {
                  'cursor-not-allowed': disabled,
                },
                'rounded border-gray-300 focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50'
              )}
              {...register(fieldName, validation)}
            />
            <label
              htmlFor={fieldName}
              className={classNames(
                'ml-2 text-sm font-semibold',
                {
                  'cursor-not-allowed': disabled,
                },
                checkboxLabelClass
              )}
            >
              {label}
            </label>
          </div>
          {error && (
            <span className="text-sm text-red-500">{error.message}</span>
          )}
        </div>
      </>
    )
  }

  // PASSWORD
  if (type === 'password') {
    return (
      <>
        {label && (
          <label
            htmlFor={fieldName}
            className={
              labelClassName || 'block text-sm font-semibold mb-1 text-sm'
            }
          >
            {label}
          </label>
        )}

        <div className="relative w-full">
          <div className="absolute inset-y-0 right-0 flex items-center px-2">
            <button
              tabIndex={-1}
              type="button"
              onClick={togglePwdVisible}
              className="bg-gray-200 border border-gray-300 hover:bg-gray-300 rounded px-2 py-1 text-sm text-gray-700 cursor-pointer"
            >
              {pwdVisible ? 'hide' : 'show'}
            </button>
          </div>
          <input
            id={fieldName}
            tabIndex={tabIndex}
            type={pwdVisible ? 'text' : 'password'}
            defaultValue={defaultValue}
            readOnly={readOnly}
            className={classNames(
              'form-input rounded  focus:bg-white w-full',
              { 'bg-gray-50': !readOnly && !error },
              'mt-1 block rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50',
              {
                'border-red-500 bg-pink-100 focus:border-red-500': error,
              },
              { 'bg-gray-100 text-gray-600': readOnly }
            )}
            {...register(fieldName, validation)}
          />
        </div>
        {error && <span className="text-sm text-red-500">{error.message}</span>}
      </>
    )
  }

  // SELECT
  if (type === 'select') {
    return (
      <>
        {label && (
          <label
            htmlFor={fieldName}
            className={labelClassName || 'block text-sm font-semibold mb-1'}
          >
            {label}
          </label>
        )}
        <select
          id={fieldName}
          tabIndex={tabIndex}
          defaultValue={defaultValue}
          className={classNames(
            'form-select bg-gray-50 focus:bg-white w-full',
            {
              'border-red-500 bg-pink-100 focus:border-red-500': error,
            },
            {
              'cursor-not-allowed': disabled,
            },
            'block mt-1 rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
          )}
          disabled={disabled}
          {...register(fieldName, validation)}
        >
          {options?.map((o) => {
            return (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            )
          })}
        </select>
        {error && <span className="text-sm text-red-500">{error.message}</span>}
      </>
    )
  }

  // TEXTAREA
  if (type === 'textarea') {
    return (
      <>
        {label && (
          <label
            htmlFor={fieldName}
            className={labelClassName || 'block text-sm font-semibold mb-1'}
          >
            {label}
          </label>
        )}
        <textarea
          id={fieldName}
          tabIndex={tabIndex}
          rows="4"
          defaultValue={defaultValue}
          readOnly={readOnly}
          disabled={disabled}
          className={classNames(
            'form-input bg-gray-50 focus:bg-white w-full ',
            {
              'border-red-500 bg-pink-100 focus:border-red-500': error,
            },
            {
              'cursor-not-allowed': disabled,
            },
            'mt-1 block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
          )}
          {...register(fieldName, validation)}
        />
        {error && <span className="text-sm text-red-500">{error.message}</span>}
      </>
    )
  }

  // MASKED INPUT
  if (type === 'normalized-text') {
    return (
      <>
        {label && (
          <label
            htmlFor={fieldName}
            className={labelClassName || 'block text-sm font-semibold mb-1'}
          >
            {label}
          </label>
        )}

        <Controller
          defaultValue={defaultValue}
          control={control}
          name={fieldName}
          rules={validation}
          render={({ field }) => (
            <input
              id={fieldName}
              tabIndex={tabIndex}
              type={type}
              defaultValue={defaultValue}
              value={normalize ? normalize(field.value) : field.value}
              readOnly={readOnly}
              disabled={disabled}
              className={classNames(
                'form-input bg-gray-50 focus:bg-white w-full ',
                {
                  'border-red-500 bg-pink-100 focus:border-red-500': error,
                },
                {
                  'cursor-not-allowed': disabled,
                },
                'mt-1 block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
              )}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />

        {error && <span className="text-sm text-red-500">{error.message}</span>}
      </>
    )
  }

  // TEXT & OTHER TYPES
  return (
    <>
      {label && (
        <label
          htmlFor={fieldName}
          className={labelClassName || 'block text-sm font-semibold mb-1'}
        >
          {label}
        </label>
      )}
      <input
        id={fieldName}
        tabIndex={tabIndex}
        type={type}
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={disabled}
        className={classNames(
          'form-input focus:bg-white w-full ',
          { 'bg-gray-50': !readOnly && !error },
          {
            'border-red-500 bg-pink-100 focus:border-red-500': error,
          },
          {
            'cursor-not-allowed': disabled,
          },
          { 'bg-gray-100 text-gray-600': readOnly },
          'mt-1 block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
        )}
        {...register(fieldName, validation)}
      />
      {error && <span className="text-sm text-red-500">{error.message}</span>}
    </>
  )
}

export default FormField
