import { useMutation } from 'react-query'
import axios from 'axios'
import type { User } from '../queries/useProfile'

const useResetPassword = (token: string) => {
  return useMutation(
    async (password: string) => {
      return await axios
        .post<{ authToken: string; user: User }>(`/account/change_password`, {
          token,
          password,
        })
        .then((res) => res.data)
    },
    {
      onSuccess: (data) => {},
      onError: (error: any) => {},
    }
  )
}

export default useResetPassword
