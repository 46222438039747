import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useDeleteAppUser = (appId: string, appEnv: string, userId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async () =>
      await axios
        .delete(`/account/apps/${appId}/environments/${appEnv}/users/${userId}`)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['app', appId, appEnv])
        queryClient.invalidateQueries(['appUsers', appId, appEnv])
        cogoToast.success('User deleted successfully', {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useDeleteAppUser
