import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Role } from '../../hooks/api/queries/useCustomRoles'
import usePatchAppCustomRole from '../../hooks/api/mutations/usePatchAppCustomRole'

import FormField from '../../components/FormField'
import Button from '../../components/Button'

interface EditRoleProps {
  appId: string
  appEnv: string
  role: Role
  onCloseEditModal: any
}

const normalizeCustomRoleId = (value: string) => {
  const normalized = value
    .replace(/(\s|-)/g, '_')
    .normalize('NFKD')
    .replace(/\W/g, '')
    .toLowerCase()

  return normalized
}

const EditRole: React.FC<EditRoleProps> = ({
  appId,
  appEnv,
  role,
  onCloseEditModal,
}) => {
  const patchCustomRole = usePatchAppCustomRole(appId, appEnv, role.id)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm<any>({
    defaultValues: role,
  })

  const onSubmit = (data: any) => {
    patchCustomRole.mutateAsync(data).then(onCloseEditModal)
  }

  useEffect(() => {
    reset(role)
  }, [role, reset])

  return (
    <div className="px-12 py-8 max-w-xl">
      <div className="flex flex-col items-center mb-5">
        <h2 className="text-xl font-semibold">
          <div>{role.name}</div>
        </h2>
      </div>

      <div className="mb-5 text-sm text-gray-600">
        Here you can edit the role's name.
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <FormField
            fieldName="id"
            type="normalized-text"
            control={control}
            normalize={normalizeCustomRoleId}
            label="Id"
            validation={{
              required: 'An identifier is required',
            }}
            error={errors.id}
            disabled
          />
        </div>
        <div className="mb-6">
          <FormField
            fieldName="name"
            type="text"
            label="Name"
            validation={{
              required: 'A display name is required',
            }}
            error={errors.name}
            register={register}
          />
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <Button color="gray" onClick={onCloseEditModal} className="flex-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            loading={patchCustomRole.isLoading}
            className="flex-1"
          >
            Save
          </Button>
        </div>

        {patchCustomRole.isError && (
          <span className="block mt-2 text-red-500 md:inline-block md:mt-0">
            An error occurred while editing this role
          </span>
        )}
      </form>
    </div>
  )
}

export default EditRole
