import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useCreateInvitation = (appId: string, appEnv: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (values: any) => {
      return await axios.post('/account/invitation', { ...values, appId, appEnv }).then((res) => res.data)
    },

    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['appInvitations', appId, appEnv])
        queryClient.invalidateQueries(['app', appId, appEnv])
        cogoToast.success('User invited successfully', { position: 'bottom-right' })
      },
      onError: (error: Error & { status: number }, values) => {
        console.log('ERROR: ', error?.status)
        if (error?.status === 409) {
          cogoToast.warn(`Invitation already sent to ${values.email}`, { position: 'bottom-right' })
        }
      },
    }
  )
}

export default useCreateInvitation
