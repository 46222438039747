import React, { ReactElement, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import useApp from '../../../../hooks/api/queries/useApp'

import PlanPill from '../../../../components/PlanPill'
import AsideMenu from '../../../../components/AsideMenu'

import GettingStarted from './GettingStarted'
import Keys from './Keys'
import AppSettings from './AppSettings'
import Languages from './Languages'
import WebHooks from './WebHooks'
import Users from './Users'
// import Billing from './Billing'
import Usage from './Usage'
import PlanPage from './Plan'
import ChangeHistory from './ChangeHistory'
import BackupRestore from './BackupRestore'
// import Duplicate from "./Duplicate";
import DeleteApp from './DeleteApp'
import CustomRoles from './CustomRoles'
import Environments from './Environments'
import ApprovalWorkflow from './ApprovalWorkflow'
import SingleSignOn from './SingleSignOn'

type RoutePath =
  | 'getting-started'
  | 'app-settings'
  | 'keys'
  | 'plan'
  | 'usage'
  | 'users'
  | 'languages'
  | 'web-hooks'
  | 'sso'
  | 'custom-roles'
  | 'environments'
  | 'approval-workflow'
  | 'history'
  | 'backup-restore'
  | 'delete-app'

interface AppRoute {
  path: RoutePath
  label: string
  element: ReactElement
  separator?: boolean
  disabled?: boolean
}

const App = () => {
  const navigate = useNavigate()
  const { appId, environment, page } = useParams()

  useEffect(() => {
    if (!environment) {
      navigate(`/apps/${appId}/environments/main/${page || ''}`)
    }
  }, [appId, environment, page, navigate])

  const appQuery = useApp(appId, environment)

  if (!appId || !environment || !appQuery.data) {
    return null
  }

  const OWNER_APP_ROUTES: AppRoute[] = [
    {
      path: 'getting-started',
      label: 'Getting started',
      element: <GettingStarted app={appQuery.data} />,
    },
    {
      path: 'app-settings',
      label: 'App Settings',
      element: <AppSettings app={appQuery.data} />,
    },
    {
      path: 'keys',
      label: 'API Keys',
      element: <Keys app={appQuery.data} />,
      separator: true,
    },
    {
      path: 'users',
      label: 'Users',
      element: <Users app={appQuery.data} />,
    },
    {
      path: 'languages',
      label: 'Languages (i18n)',
      element: <Languages app={appQuery.data} />,
    },
    {
      path: 'web-hooks',
      label: 'Web hooks',
      element: <WebHooks app={appQuery.data} />,
      separator: true,
    },
    {
      path: 'plan',
      label: 'Plan and Billing',
      element: <PlanPage app={appQuery.data} />,
    },
    {
      path: 'usage',
      label: 'Usage',
      element: <Usage app={appQuery.data} />,
      separator: true,
    },
    {
      path: 'sso',
      label: 'Single Sign-On',
      element: <SingleSignOn app={appQuery.data} />,
    },
    {
      path: 'custom-roles',
      label: 'Custom roles',
      element: <CustomRoles app={appQuery.data} />,
      //disabled: !appQuery.data?.subscription?.flexibleRoles,
    },
    {
      path: 'environments',
      label: 'Environments',
      element: <Environments app={appQuery.data} />,
      //disabled: !appQuery.data?.subscription?.environments,
    },
    {
      path: 'approval-workflow',
      label: 'Approval workflow',
      element: <ApprovalWorkflow app={appQuery.data} />,
      separator: true,
      //disabled: !appQuery.data?.subscription?.environments,
    },
    {
      path: 'history',
      label: 'Change history',
      element: <ChangeHistory app={appQuery.data} />,
    },
    {
      path: 'backup-restore',
      label: 'Backup & Restore',
      element: <BackupRestore app={appQuery.data} />,
      separator: true,
    },
    // {
    //   path: "duplicate-app",
    //   label: "Duplicate App",
    //   element: <Duplicate appId={appId} />,
    // },
    {
      path: 'delete-app',
      label: 'Delete App',
      element: <DeleteApp appId={appId} appEnv={environment} />,
    },
  ]
  const ADMIN_APP_ROUTES: AppRoute[] = OWNER_APP_ROUTES.filter(
    (route) =>
      route.path !== 'plan' &&
      route.path !== 'environments' &&
      route.path !== 'delete-app'
  )

  const EDITOR_APP_ROUTES: AppRoute[] = [
    {
      path: 'getting-started',
      label: 'Getting started',
      element: <GettingStarted app={appQuery.data} />,
    },
    {
      path: 'keys',
      label: 'Keys',
      element: <Keys app={appQuery.data} />,
    },
  ]

  let APP_ROUTES: AppRoute[] = []
  if (appQuery.data?.isOwner) {
    APP_ROUTES = OWNER_APP_ROUTES
  } else {
    if (appQuery.data?.role === 'ADMIN') {
      APP_ROUTES = ADMIN_APP_ROUTES
    } else if (appQuery.data?.role === 'EDITOR') {
      APP_ROUTES = EDITOR_APP_ROUTES
    }
  }

  return (
    <div className="bg-gray-50">
      <div className="border-b border-gray-200">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-end space-x-3">
            <h1 className="text-4xl font-semibold leading-5">
              {appQuery.data?.appName || ' '}
            </h1>
            <PlanPill
              plan={appQuery.data?.plan}
              isLiveDemo={
                appQuery.data?.liveDemo &&
                appQuery.data?.liveDemo.status === 'ACTIVE'
              }
              isBoosted={
                appQuery.data?.subscription &&
                appQuery.data?.subscription.description
                  ? appQuery.data?.subscription.description ===
                    'Boosted Developer Plan'
                  : false
              }
            />
          </div>
        </div>
      </div>

      {APP_ROUTES.length > 0 && (
        <AsideMenu
          menuTitle={`${appQuery.data?.appName} Settings`}
          routes={APP_ROUTES}
          indexRoute={`/apps/${appId}/environments/${environment}`}
        />
      )}
    </div>
  )
}

export default App
