import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import usePushAppEnvironment from '../../hooks/api/mutations/usePushAppEnvironment'

import Button from '../../components/Button'
import FormField from '../../components/FormField'
import { Environment } from '../../hooks/api/queries/useApp'

interface PushEnvironmentProps {
  appId: string
  environment: Environment
  environments: Environment[]
  onClosePushModal: any
}

interface FormFields {
  destinationEnvironmentName: string
}

const initialData: FormFields = {
  destinationEnvironmentName: 'main',
}

const PushEnvironment: React.FC<PushEnvironmentProps> = ({
  appId,
  environment,
  environments,
  onClosePushModal,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: initialData,
  })

  useEffect(() => {
    reset(initialData)
  }, [reset])

  const pushEnvironment = usePushAppEnvironment(appId)

  return (
    <div className="px-12 py-8 max-w-md">
      <h2 className="text-lg font-semibold text-center mb-5">
        Push "{environment.name}" to another env
      </h2>

      <p className="mb-5 text-sm text-gray-600">
        The content of this environment (assets included) will replace the
        content of the Destination environment. The Destination environment's
        change history will be reset.
      </p>

      <form
        onSubmit={handleSubmit((data) => {
          pushEnvironment.mutate({
            environmentName: environment.name,
            destinationEnvironmentName: data.destinationEnvironmentName,
          })
          onClosePushModal()
        })}
      >
        <div className="mb-6">
          <FormField
            fieldName="destinationEnvironmentName"
            type="select"
            label="Destination"
            error={errors.destinationEnvironmentName}
            register={register}
            options={environments.map((environment) => ({
              value: environment.name,
              label: environment.name,
            }))}
          />
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <Button color="gray" onClick={onClosePushModal} className="flex-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            loading={pushEnvironment.isLoading}
            className="flex-1"
          >
            Push
          </Button>
        </div>

        {pushEnvironment.isError && (
          <span className="block mt-2 text-red-500 md:inline-block md:mt-0">
            An error occurred while pushing this environment
          </span>
        )}
      </form>
    </div>
  )
}

export default PushEnvironment
