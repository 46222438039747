import React from 'react'

/**
 * Props for Select
 * @internal
 */
interface SelectProps {
  id?: string
  value?: any
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  className?: string
  style?: object
  name?: string
  required?: boolean
  placeholder?: string
  disabled?: boolean
}

const Select: React.FC<SelectProps> = React.forwardRef<
  HTMLInputElement,
  SelectProps
>((props: any, ref) => {
  return (
    <select
      ref={ref}
      disabled={props.disabled}
      {...props}
      className="form-select bg-gray-50 focus:bg-white w-full block mt-1 rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
      {...props.style}
    ></select>
  )
})

export default Select
