import { useQuery } from 'react-query'
import axios from 'axios'
import type { App } from './useApp'

const useApps = () => {
  return useQuery(['apps'], async () => {
    const { data: result } = await axios.get<App[]>(`/account/apps`)
    return result
  })
}

export default useApps
